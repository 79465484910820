import React, {useCallback, useEffect, useState} from 'react'
import BaseDialog from "../../../../components/Common/BaseDialog";
import {Box, Checkbox, Grid, makeStyles, Typography} from "@material-ui/core";
import {truncateString} from "../../../../utils";
import moment from "moment";


const useStyles = makeStyles(() => ({
  itemCard: {
    backgroundSize: 'cover',
    backgroundColor: '#ffffff',
    padding: 8,
    width:200,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
    height: 140,
    position: 'relative'
  },
  itemTitle: {
    backgroundColor: 'rgba(255,255,255,0.91)', padding: 5, borderRadius: 5
  }
}))

const GuideItemsDialog = (props) => {
  const classes = useStyles()
  const {open, handleClose, items,handleAddSelectedItems,showItemsOnly=false,currentComponentId,preSelectedItems,preSelectedLists,languages,components,componentType="LIST"} = props
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedLists,setSelectedLists] = useState([])
  const selectedLanguage = languages? languages[0] : ''
  const [activeTab,setActiveTab] = useState(0)

  useEffect(() => {
    if (open) {
      if (preSelectedItems?.length > 0)
        setSelectedItems(preSelectedItems)
      else
        setSelectedItems([])
      if (preSelectedLists?.length>0)
        setSelectedLists(preSelectedLists)
      else
        setSelectedLists([])
    }
  }, [open,preSelectedItems,preSelectedLists])

  const handleSelectItem = useCallback((item)=>{
    const itemExists = selectedItems?.includes(item)
    if (itemExists)
    {
      setSelectedItems(selectedItems?.filter((selectedItem)=>selectedItem !== item))
    }
    else
      setSelectedItems((prevState)=>[...prevState,item])
  },[selectedItems])

  const handleSelectList = useCallback((item)=>{
    const itemExists = selectedLists?.includes(item)
    if (itemExists)
    {
      setSelectedLists(selectedLists?.filter((selectedList)=>selectedList !== item))
    }
    else
      setSelectedLists((prevState)=>[...prevState,item])
  },[selectedLists])


  const renderStepOne = () =>{
   return <Grid container spacing={2}>
     {items?.map((item) => {
       const isChecked = selectedItems?.find((selectedItem)=>selectedItem === item.id) !== undefined
       return <Grid key={item.id} item style={{position:'relative'}}>
         <Grid container alignContent={'flex-start'} alignItems={'center'} className={classes.itemCard}
               style={{backgroundImage: item?.coverImage && `url(${item?.coverImage.thumb})`,outline:isChecked?'#7589da solid 4px':''}}
               onClick={()=>handleSelectItem(item.id)}
         >
           <Grid item xs={12} className={classes.itemTitle}>
             {item?.title ? <span style={{fontWeight: 'bolder'}}>{truncateString(item.title[selectedLanguage], 30)}</span> :
               <span style={{color: '#939393', fontStyle: 'italic'}}>Empty title</span>}
           </Grid>
           <Grid item style={{position: 'absolute', bottom: 4,right:2, width: '95%'}} alignContent={'flex-end'} justifyContent={'flex-end'} xs={12}>
             <Grid container alignItems={'center'}
                   style={{backgroundColor: 'rgba(255,255,255,0.53)', padding: 5, borderRadius: 5}}>
               <Grid item>
                 <Typography variant={'caption'} style={{color: '#000', textShadow: '#fff6f6 0px 1px 1px'}}>Created
                   at {moment(item?.createdAt).format('hh:mm DD/MM/YYYY')}</Typography>
               </Grid>
             </Grid>
             <Box display={'flex'} alignItems={'flex-end'} width={'100%'} justifyContent={'flex-end'}>
               <div className="custom-control custom-switch mb-2 mt-2 z-depth-0">
                 <input
                   type="checkbox"
                   className="custom-control-input"
                   id={`${item.id}-item`}
                   checked={isChecked}
                 />
                 <label
                   className="custom-control-label"
                   htmlFor={`${item.id}-item`}
                   onClick={() => handleSelectItem(item.id)}
                 />
               </div>
             </Box>
           </Grid>
         </Grid>
       </Grid>
     })}
   </Grid>
  }

  const renderStepTwo = () =>{
    return <Grid container spacing={2}>
      {components?.filter((com)=>com.type === componentType && com.id !== currentComponentId)?.map((item) => {
        const isChecked = selectedLists?.find((selectedList)=>selectedList === item.id) !== undefined
        return <Grid key={item.id} item style={{position:'relative'}}>
          <Grid container alignContent={'flex-start'} alignItems={'center'} className={classes.itemCard}
                style={{backgroundImage: item?.coverImage && `url(${item?.coverImage.thumb})`,outline:isChecked?'#7589da solid 4px':''}}
                onClick={()=>handleSelectList(item.id)}
          >
            <Grid item xs={12} className={classes.itemTitle}>
              {item?.title ? <span style={{fontWeight: 'bolder'}}>{truncateString(item.title[selectedLanguage], 30)}</span> :
                <span style={{color: '#939393', fontStyle: 'italic'}}>Empty title</span>}
            </Grid>
            <Grid item style={{position: 'absolute', bottom: 4,right:2, width: '95%'}} alignContent={'flex-end'} justifyContent={'flex-end'} xs={12}>
              <Grid container alignItems={'center'}
                    style={{backgroundColor: 'rgba(255,255,255,0.53)', padding: 5, borderRadius: 5}}>
                <Grid item>
                  <Typography variant={'caption'} style={{color: '#000', textShadow: '#fff6f6 0px 1px 1px'}}>Created
                    at {moment(item?.createdAt).format('hh:mm DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
              <Box display={'flex'} alignItems={'flex-end'} width={'100%'} justifyContent={'flex-end'}>
                <div className="custom-control custom-switch mb-2 mt-2 z-depth-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`${item.id}-item`}
                    checked={isChecked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${item.id}-item`}
                    onClick={() => handleSelectList(item.id)}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      })}
    </Grid>
  }

  const tabs = showItemsOnly? [] : [{title:'Items'},{title:componentType==='ROUTES'?'Routes':'Lists'}]
  const contents = showItemsOnly ? [renderStepOne()]:[renderStepOne(), renderStepTwo()];



  return <BaseDialog
    open={open}
    handleClose={handleClose}
    title={'Repox Guide | List Type'}
    size={'lg'}
    primaryActionTitle={'Add Selected Items'}
    primaryAction={()=> {
      handleAddSelectedItems({listOfItems:selectedItems,listOfLists:selectedLists})
      handleClose()
    }}
    tabs={tabs}
    changeTab={activeTab}
    contents={contents}
    onTabChanged={setActiveTab}
    primaryActionDisabled={selectedItems?.length <=0 && selectedLists?.length <=0}
  />

}

export default GuideItemsDialog