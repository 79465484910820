import React from 'react'
import {Grid} from "@material-ui/core";

const StaticMobileView = ({component,selectedLanguage}) =>{

  return <Grid container direction={'row'}>
      <Grid item xs={12}>
        {component?.title[selectedLanguage]}
      </Grid>
    <Grid item>
      <div dangerouslySetInnerHTML={{__html: JSON.parse(component?.contentHtml[selectedLanguage])}}/>
    </Grid>
  </Grid>
}

export default StaticMobileView