import React, {useCallback, useEffect, useState} from 'react'
import SlideAssetsDialog from "../../../Story/Stories/Slideshow/components/SlideAssetsDialog";
import {assetThumb} from "../../../../utils";
import GuideAssetsDialog from "../GuideAssetsDialog";

const CoverImage = ({coverImage,handleChange}) =>{

  const [openAssetsDialog, setOpenAssetsDialog] = useState(false)

  const [coverImageSet, setCoverImage] = useState(undefined);

  useEffect(()=>{
    if (coverImageSet)
      handleChange('coverImage',coverImageSet)
  },[coverImageSet])

  const handleImageChange = (asset)=>{
    setCoverImage({thumb:asset.thumbnailUrl,uuid:asset.uuid})
  }

  return<> <div style={{backgroundImage: coverImage?.thumb && `url(${coverImage?.thumb})`,display:'flex',alignItems:'center',justifyContent:'center',
    backgroundSize: 'cover', border:`1px ${!coverImage?.thumb?'dashed':'solid'} #ececec`,borderRadius:2,width:100,height:100,cursor:'pointer'}} onClick={()=>setOpenAssetsDialog(true)}>

  </div>

    <GuideAssetsDialog open={openAssetsDialog}
                       multiple={true}
                       handleChange={handleImageChange}
                       handleClose={() => {
                         setOpenAssetsDialog(!openAssetsDialog)
                       }}/>

    </>
}

export default CoverImage