import React from 'react'
import {Box, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import ImagesCarousel from "./ImagesCarousel";
import {ArrowBack} from "@material-ui/icons";
import ListItemMobileView from "./LIstItemMobileView";
import ListItem from "./ListItem";


const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: 460,
    overflowY: 'auto',
  }
}))
const ListOfListMobileView = ({list, handleClose, items, selectedLanguage, setSelectedItem,routeIndicator=false}) => {
  const classes = useStyles()
  const listOfItems = list?.listOfItems


  return <Grid container direction={'row'} className={classes.container} style={{position: 'relative'}}>
    <Grid item xs={12}>
      <Grid container alignItems={'center'} justifyContent={'flex-start'}>
        <Grid item xs={2}>
          <IconButton size={'small'} onClick={handleClose}>
            <ArrowBack/>
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography variant={'body1'} style={{fontWeight: 'bold'}}>{list.title[selectedLanguage]}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {listOfItems?.map((listItem,idx) => {
              const thisComponentItemObj = items.find((item) => item.id === listItem && item.title[selectedLanguage])
              if (thisComponentItemObj)
                return <Grid item key={`item-list-${thisComponentItemObj.id}`} xs={12} onClick={() => setSelectedItem(thisComponentItemObj)}>
                  <ListItem item={thisComponentItemObj} selectedLanguage={selectedLanguage} routeIdx={routeIndicator?idx+1:null}/>
                </Grid>
            }
          )}
        </Grid>
      </Grid>
    </Grid>

  </Grid>
}

export default ListOfListMobileView