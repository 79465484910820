import React, {useCallback, useState} from 'react'

import {Grid} from "@material-ui/core";
import GuideAssetsDialog from "../GuideAssetsDialog";
import Reorder from "react-reorder";

const ImageGallery = ({images, setImages}) => {

  const [openAssetsDialog, setOpenAssetsDialog] = useState(false)

  const handleImageChange = useCallback((asset) => {
    setImages([...images, {thumb: asset.thumbnailUrl, uuid: asset.uuid}])
  }, [images])

  const handleReOrder = useCallback((e, previousIndex, nextIndex) => {
    const imagesTmp = JSON.parse(JSON.stringify(images))
    imagesTmp[previousIndex] = images[nextIndex]
    imagesTmp[nextIndex] = images[previousIndex]
    setImages(imagesTmp)
  },[images,setImages])

  return <>
    <Grid container style={{width:'100%'}}>
       <Grid item xs={10} style={{marginBottom:4}}>
         {images?.length > 0 &&  <Grid container style={{height: 100}} direction={'row'}>
          <Reorder
            reorderId={'slidesList'}
            autoScroll
            draggedClassName="draggedImg"
            onReorder={handleReOrder}
            holdTime={50}
            disableContextMenus={true}
          >
            {images?.map((imageSet, idx) => {
              return <Grid item style={{
                backgroundImage: `url(${imageSet?.thumb})`,
                backgroundSize: 'cover',
                marginRight: '2px',
                border: imageSet?.thumb ? '' : `1px dashed #ececec`,
                borderRadius: 2,
                width: 100,
                height: 100,
                float:'left',
                cursor: 'pointer'
              }} key={imageSet.uuid} className={'draggedImg'}/>
            })}
          </Reorder>
        </Grid>}
        <Grid item
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                border: `1px dashed #ececec`, borderRadius: 2, width: 100, height: 100, cursor: 'pointer'
              }} onClick={() => setOpenAssetsDialog(true)}>
          Add image
        </Grid>
      </Grid>
    </Grid>
    <GuideAssetsDialog open={openAssetsDialog}
                       multiple={true}
                       handleChange={handleImageChange}
                       handleClose={() => {
                         setOpenAssetsDialog(!openAssetsDialog)
                       }}/>

  </>
}

export default ImageGallery