import React, {useCallback, useState} from 'react'
import {Box, Button, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import {motion} from "framer-motion";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import ReactCountryFlag from "react-country-flag";
import GuideAssetsDialog from "./components/GuideAssetsDialog";
import axios from "axios";
import {API_URL} from "../../config";
import {getAxiosDefaultConfig} from "../../utils";

const languages = [{countryCode: 'GR'}, {countryCode: 'US'}, {countryCode: 'IT'}, {countryCode: 'FR'}]

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '150px',
    width: '800px',
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: '10%',
    border: '1px solid #ececec',
    boxShadow: '1px 1px 1px 0px #f2efef',
    minHeight: 0,
    opacity: 0,
    height: 'auto',
    padding: '50px',
  },
  title: {
    fontSize: 30,
    color: '#6457d7',
    fontWeight: 'bolder'
  },
  title2: {
    fontSize: 24,
    color: '#6457d7',
    fontWeight: 'bolder'
  },
  uploadLogoBtn: {
    width: 80,
    height: 80,
    borderRadius: 40,
    border: '1px solid #ececec',
    cursor: 'pointer',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  submitButton: {
    backgroundColor: '#6457d7',
    color: 'white',
    padding: 10,
    transition: 'width 1s ease-in'
  }
}))
const GuideCreationWizard = (props) => {

  const classes = useStyles()

  const [appName, setAppName] = useState('')
  const [appDescription, setAppDescription] = useState('')

  const [language, setLanguage] = useState(() => []);
  const [logo, setLogo] = useState(null)
  const [openUploadLogo, setOpenUploadLogo] = useState(false)

  const handleLanguage = (event, newLanguages) => {
    setLanguage(newLanguages);
  };

  const handleImageChange = (asset) => {
    setLogo({thumb: asset.thumbnailUrl, uuid: asset.uuid})
  }

  const createIsDisabled = appName === '' || appDescription === '' || language?.length === 0

  const createGuide = useCallback(async()=>{
    const payload = {
      title: '', description: '', coverImage: '',
      guideDetails: {
        application: {title: '', description: '', version: 1, createdAt: ''},
        settings: {allowItemMediaFullscreen: true, noImageFilepath: 'assets/no-image-grey.png'},
        languages: [],
        splashScreen: {
          logo: '',
          filepath: '',
          welcomeText: '',
          durationInSecs: 5
        },
        onboarding: [],
        bottomMenu: [],
        components: [],
        items: [],
      }
    }
    payload.title = appName
    payload.guideDetails.title = appName
    payload.description = appDescription
    payload.coverImage = logo?.thumb
    payload.guideDetails.description = appDescription
    payload.guideDetails.languages = language.map((l)=>l.toLowerCase())
    payload.guideDetails.splashScreen.logo = logo?.thumb
    payload.guideDetails.splashScreen.filepath = logo?.thumb

    const res = await axios.post(`${API_URL}/guides`, payload, getAxiosDefaultConfig())
      try{
        const uuid = res.data.uuid
        props.history.push(`/guides/edit/${uuid}`)
      }
      catch (e) {
        console.log('error',e)
      }
  },[appDescription, appName, language, logo])

  return <motion.div
    initial={{height: 0}}
    animate={{minHeight: 600, height: 'auto', opacity: 1}}
    exit={{scale: 0.8, opacity: 0}}
    transition={{type: "spring", duration: 1.5, delay: 1}}
    className={classes.container}>
    <Grid container alignItems={'center'} spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>Welcome to Repox Guide Creation</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="App Title"
          id="margin-none"
          defaultValue=""
          required={true}
          value={appName}
          onChange={(event) => setAppName(event.target.value)}
          helperText="Type the name of your guide as it will be displayed on Mobile Stores"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Description"
          fullWidth={true}
          id="margin-none"
          defaultValue=""
          required={true}
          value={appDescription}
          onChange={(event) => setAppDescription(event.target.value)}
          helperText="Type a short description of your app"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title2}>Select Language</Typography>
        <ToggleButtonGroup
          value={language}
          onChange={handleLanguage}
        >
          {languages.map((country) =>
            <ToggleButton key={country.countryCode} value={country.countryCode} aria-label={country.countryCode}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <ReactCountryFlag
                  countryCode={country.countryCode}/>
                <Typography variant={'body1'}>{country.countryCode}</Typography>
              </Box>
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title2}>App Logo</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'caption'}>Your logo must be 512x512</Typography>
      </Grid>
      <Grid item xs={12}>
        <motion.div
          className={classes.uploadLogoBtn}
          style={{backgroundImage: logo ? `url(${logo.thumb})` : ''}}
          whileHover={{scale: 1.1}}
          whileTap={{scale: 0.9}}
          onClick={() => setOpenUploadLogo(true)}
        >
        </motion.div>
      </Grid>
      <Grid item xs={12}>
        <Box alignItems={"center"} justifyContent={'center'} display={'flex'}>
          <Button onClick={createGuide} className={classes.submitButton} fullWidth={true} disabled={createIsDisabled}>Create</Button>
        </Box>
      </Grid>
    </Grid>
    <GuideAssetsDialog
      open={openUploadLogo}
      handleClose={() => setOpenUploadLogo(false)}
      handleChange={handleImageChange}
    />
  </motion.div>
}

export default GuideCreationWizard