import React, {useRef, useState} from 'react'
import Row from 'reactstrap/lib/Row'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input} from 'reactstrap'
import Col from 'reactstrap/lib/Col'


const GuidesFilter = (props) => {
  const {getAllStories, sortBy, setSortBy, setSortFilter} = props
  const [text, setText] = useState()
  const [openDropDown,setOpenDropDown] = useState(false)
  const [optionSelected,setOptionSelected] = useState('Name')
  const searchTimeout = useRef()

  const handleChange = (val) => {
    const value = val.target.value

    setText(value)
    clearTimeout(searchTimeout.current)
    if (value.length > 3) {
      searchTimeout.current = setTimeout(() => {
        getAllStories(0, text)
      }, 800)
    } else if (value === '') {
      getAllStories(0, '')
    }
  }

  const handleChangeSort = () =>{
    if (sortBy === 'DESC')
      setSortBy('ASC')
    else
      setSortBy('DESC')
  }


  return <>
    <Row>
      <Col xs={4}>
        <div className='text-muted font-size-12 font-weight-bold mb-2'>Search</div>
      </Col>
      <Col xs={8}>
        <div className='text-muted font-size-12 font-weight-bold mb-2'>Sort by</div>
      </Col>
    </Row>
    <Row className='align-content-end align-items-end'>
      <Col xs={4}>
        <Input name={'search'} id={'search'} type={'text'} value={text} onInput={handleChange}/>
      </Col>
      <Col xs={'auto'}>
        <Dropdown
          isOpen={openDropDown}
          toggle={() => setOpenDropDown(!openDropDown)}
        >
          <DropdownToggle className={'btn-stories-filter'}>
            {optionSelected} <i className="bx bx-chevron-down" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={()=> {
              setSortFilter('title')
              setOptionSelected('Name')
            }
            }>
              Name
            </DropdownItem>
            <DropdownItem onClick={()=>{
              setSortFilter('updatedAt')
              setOptionSelected('Date')
            }}>
              Date
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Col>
      <Col xs={'auto'}>
        <div className='d-inline-block ml-2' style={{width:'30px',height:'30px',textAlign:'center',cursor:'pointer'}} onClick={handleChangeSort}>
          {sortBy === 'ASC' &&
            <i className='bx bx-sort-up' color='primary'/>
          }
          {sortBy === 'DESC' &&
            <i className='bx bx-sort-down' color='primary'/>
          }
        </div>
      </Col>
    </Row>
  </>
}

export default GuidesFilter