import React, {useState, useEffect} from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  Tooltip,
} from 'reactstrap';
import {Grid} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {ApiEndpoint} from '../../store/core/endpoint';
import {bytesToSize, downloadFile, downloadThumb, getIconForDatastream} from '../../utils';
import {useToggle} from '../../helpers/hooks';
import {EntityType} from '../../store/core/entityType';

const DataStreamList = ({files, selectedDatastream, handleFileSelect, handleFileClick, handleFileEdit, handleDeleteFile, handleMoveFile,handleFileRename, handleLinkFile, showList ,recent}) => {
  
  const [downloadLoading, setDownloadLoading] = useState(false);
  // const [thumbnail, setThumbnail] = useState([])
  const [pointerPosition, setPointerPosition] = useState({x: 0, y: 0});

  const handleDownloadFile = (file) => (e) => {
    e.stopPropagation();
    setDownloadLoading({
      ...downloadLoading,
      [file.uuid]: true,
    });
    const downloadUrl =
      ApiEndpoint[EntityType.DataStream] + `/${file.uuid}/download`;
    downloadFile(downloadUrl, file, downloadLoading, setDownloadLoading);
  };

  // useEffect(() => {
  //   if (files && files.length > 0) {
  //     files.forEach((file) => {
  //       const downloadUrl = ApiEndpoint[EntityType.DataStream] + `/${file.uuid}/download`;
  //       downloadThumb(downloadUrl,file,thumbnail,setThumbnail)
  //     })
  //   }
  //   // eslint-disable-next-line
  // }, [files])

  if (files.length === 0) return null;

  if (showList)
    return (
      <Col lg="12" className='p-0'>
        <Card onContextMenu={(e) => e.preventDefault()}>
          <CardBody>
            <CardTitle className="mb-4">Files</CardTitle>
            <div className="table-responsive">
              <Table className="table table-nowrap table-centered table-hover mb-0">
                <tbody>
                  {files.map((file, index) => (
                    <ListItem
                      key={index}
                      file={file}
                      handleFileClick={handleFileClick}
                      handleDownloadFile={handleDownloadFile}
                      handleFileRename={handleFileRename}
                      downloadLoading={downloadLoading}
                      // thumbnail={thumbnail[file.uuid]}
                      handleMoveFile={handleMoveFile}
                      handleLinkFile={handleLinkFile}
                      handleFileEdit={handleFileEdit}
                      handleDeleteFile={handleDeleteFile}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  else if (showList === false) {
    return files.map((file, index) => {
      return (
        <GridItem
          key={index}
          file={file}
          handleFileClick={handleFileClick}
          handleDownloadFile={handleDownloadFile}
          handleFileRename={handleFileRename}
          downloadLoading={downloadLoading}
          pointerPosition={pointerPosition}
          setPointerPosition={setPointerPosition}
          // thumbnail={thumbnail[file.uuid]}
          handleMoveFile={handleMoveFile}
          handleLinkFile={handleLinkFile}
          handleFileEdit={handleFileEdit}
          handleDeleteFile={handleDeleteFile}
          recent={recent}
          handleFileSelect={handleFileSelect}
          selectedDatastream={selectedDatastream}
        />
      );
    });
  } else {
    return null;
  }
};

export default DataStreamList;

function ListItem({file, handleFileClick, handleDownloadFile,handleFileRename, handleFileEdit, handleDeleteFile,handleMoveFile,handleLinkFile, downloadLoading}) {
  const [open, toggle] = useToggle(false);
  return (
    <tr key={"_file_" + file.uuid}>
      <td style={{width: "45px"}}>
        {!file.img ? (
          <>
            <div className="avatar-sm mx-auto mb-2">
              <span style={{cursor: "pointer"}} className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24" onClick={() => handleFileClick(file)}>
                <DataStreamThumb key={file.uuid} file={file} thumbnail={file.thumbnail} />
              </span>
            </div>
          </>
        ) : (
          <div className="mb-2">
            <img onClick={() => handleFileClick(file)} className="rounded-circle avatar-sm" src={file.img} alt="" />
          </div>
        )}
      </td>
      <td>
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {file.label} <i className={`ml-2 bx ${getIconForDatastream(file.mimeType)}`} />
          </Link>
        </h5>
        <small>
          Type :{" "}
          <Badge
            color={"primary"}
          >
            {file.type.name}
          </Badge>
        </small>
        <small className={"ml-2"}>Size: {bytesToSize(file.filesize)}</small>
        <small className={"ml-2"}>Creator : {file.createdBy.lastname}</small>
      </td>
      <td>
        <div className={"d-flex"} style={{position: "absolute", marginTop: "-20px"}}>
          <Dropdown isOpen={open} toggle={toggle} direction="left" className="btn-group">
            <DropdownToggle className="btn waves-light waves-effect dropdown-toggle" tag="div">
              <i className="fa fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleFileClick(file)}>
                <i className={"bx bx-show"} /> View file
              </DropdownItem>
              <DropdownItem onClick={() => handleFileEdit(file)}>
                <i className={"bx bx-edit"} /> Edit
              </DropdownItem>
              <DropdownItem onClick={() => handleFileRename(file)}>
                <i className="bx bx-edit-alt" /> Rename
              </DropdownItem>
              <DropdownItem onClick={handleDownloadFile(file)}>
                <i className={"bx bx-download"} /> Download
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => handleMoveFile(file)}>
                <i className={"bx bx-transfer"} /> Move
              </DropdownItem>
              <DropdownItem onClick={() => handleLinkFile(file)}>
                <i className={"bx bx-link"} /> Link
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem className={"text-danger"} onClick={() => handleDeleteFile(file)}>
                <i className={"bx bx-trash"} /> Remove
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
}

function GridItem({key, selectedDatastream, file, handleFileSelect, handleFileClick, handleDownloadFile, handleFileEdit,handleFileRename, handleDeleteFile, handleMoveFile, handleLinkFile,recent}) {
  const [open, toggle] = useToggle(false);
  const [menuIndex, setMenuIndex] = useState(null);


  const borderColor = selectedDatastream?.uuid === file.uuid ? 'primary' : 'light';
  return (
    <React.Fragment>
      <Col xl="4" sm="12">
        {/* <Card className="text-center">
          <CardBody style={{paddingBottom: "0.5rem"}}>
            {!file.img ? (
              <>
                <div className="avatar-sm mx-auto mb-2">
                  <span style={{cursor: "pointer"}} className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24" onClick={() => handleFileClick(file)}>
                    <DataStreamThumb key={file.uuid} file={file} thumbnail={file.thumbnail} />
                  </span>
                </div>
                <i className={`bx ${getIconForDatastream(file.mimeType)}`} />
              </>
            ) : (
              <div className="mb-2">
                <img onClick={() => handleFileClick(file)} className="rounded-circle avatar-sm" src={file.img} alt="" />
              </div>
            )}
            <div className={"d-flex"} style={{position: "absolute", right: 0, top: 0}}>
              <Dropdown isOpen={open} toggle={toggle} direction="left" className="btn-group">
                <DropdownToggle className="btn waves-light waves-effect dropdown-toggle" tag="div">
                  <i className="fa fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleFileClick(file)}>
                    <i className={"bx bx-show"} /> View file
                  </DropdownItem>
                  <DropdownItem onClick={() => handleFileEdit(file)}>
                    <i className={"bx bx-edit"} /> Edit
                  </DropdownItem>
                  <DropdownItem onClick={() => handleFileRename(file)}>
                    <i className="bx bx-edit-alt" /> Rename
                  </DropdownItem>
                  <DropdownItem onClick={handleDownloadFile(file)}>
                    <i className={"bx bx-download"} /> Download
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleMoveFile(file)}>
                    <i className={"bx bx-transfer"} /> Move
                  </DropdownItem>
                  <DropdownItem onClick={() => handleLinkFile(file)}>
                    <i className={"bx bx-link"} /> Link
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className={"text-danger"} onClick={() => handleDeleteFile(file)}>
                    <i className={"bx bx-trash"} /> Remove
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <h5 className="font-size-15 mb-0 text-dark" onClick={() => handleFileClick(file)}>
              {file.label}
            </h5>
            <p className="text-muted mb-0">{bytesToSize(file.filesize)}</p>
          </CardBody>
              <div className={"d-flex"} style={{width: "100%", left: "12px", top: "12px"}}>
              <Badge color={"primary"} style={{
                overflowWrap: "break-word",
                width: "100%",
                whiteSpace: "inherit",
                wordBreak: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                lineHeight: "16px",
                webkitLineClamp: "1",
                webkitBoxOrient: "vertical"
              }}>
                {file.type.code}</Badge>
            </div>
        </Card> */}
        <Card
          className={`text-left cursor-pointer border border-${borderColor}`}         
          onClick={(e) =>
          {
            e.stopPropagation();
           if(recent){ 
            if (menuIndex !== key) {
              handleFileSelect(e, file);
            }        }       
          }}          
        >
          <CardBody>
            <Grid container>
              <Grid item lg={4} md={4} sm={5} xs={6}>
                {!file.img ? (
                  <>
                    <div className="avatar-md mx-auto mb-1">
                      <span style={{cursor: "pointer"}} className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24" onClick={() => handleFileClick(file)}>
                        <DataStreamThumb key={file.uuid} file={file} thumbnail={file.thumbnail} />
                      </span>
                    </div>
                    <i style={{position: "absolute", fontSize: "58px", opacity: "0.08", bottom: "0px", right: "0px", zIndex: "0", transform: "rotate(0deg)"}} className={`bx ${getIconForDatastream(file.mimeType)}`} />
                  </>
                ) : (
                  <div className="mb-2">
                    <img onClick={() => handleFileClick(file)} className="rounded-circle avatar-sm" src={file.img} alt="" />
                  </div>
                )}
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={0}></Grid>
              <Grid item lg={7} md={7} sm={6} xs={6}>
               
                  <div className={"d-flex"} style={{ position: "absolute", right: 0, top: 0 }}>
                  <Dropdown isOpen={open} toggle={toggle} direction="left" className="btn-group">
                    <DropdownToggle className="btn waves-light waves-effect dropdown-toggle" tag="div">
                      <i className="fa fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleFileClick(file)}>
                        <i className={"bx bx-show"} /> View file
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFileEdit(file)}>
                        <i className={"bx bx-edit"} /> Edit
                      </DropdownItem>
                      <DropdownItem onClick={() => handleFileRename(file)}>
                        <i className="bx bx-edit-alt" /> Rename
                      </DropdownItem>
                      <DropdownItem onClick={handleDownloadFile(file)}>
                        <i className={"bx bx-download"} /> Download
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => handleMoveFile(file)}>
                        <i className={"bx bx-transfer"} /> Move
                      </DropdownItem>
                      <DropdownItem onClick={() => handleLinkFile(file)}>
                        <i className={"bx bx-link"} /> Link
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem className={"text-danger"} onClick={() => handleDeleteFile(file)}>
                        <i className={"bx bx-trash"} /> Remove
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <h5 className="font-size-15 mb-0 text-dark" onClick={() => handleFileClick(file)}>
                  {file.label}
                </h5>
                <p className="text-muted mb-0">{bytesToSize(file.filesize)}</p>
                <div className={"d-flex"}>
                  <Badge
                    color={"primary"}
                    style={{
                      overflowWrap: "break-word",
                      whiteSpace: "inherit",
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineHeight: "16px",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {file.type?.name}
                  </Badge>
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}


const DataStreamThumb = ({file, thumbnail}) => {

  const [imageThumb, setImageThumb] = useState('')
  const [openTooltip, handleToggle] = useToggle();
  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    if (file.thumbnail?.length > 0)
      downloadThumb(file, thumbnail, setImageThumb,isLoading,setIsLoading)
    // eslint-disable-next-line
  }, [file.uuid])

  if (isLoading) return <><i className={`bx ${getIconForDatastream(file.type?.name)}`}/></>

  return <>
    {imageThumb == null ? <i className={`bx ${getIconForDatastream(file.type?.name)}`}/> :
      <>
        <Tooltip
          placement="right"
          target={`fileId-${file.uuid}`}
          toggle={handleToggle}
          isOpen={openTooltip}
          style={{maxWidth:'400px'}}
        >
          <img src={imageThumb} alt={`${thumbnail} thumb`} style={{maxWidth:'300px'}}/>
        </Tooltip>
        <img id={`fileId-${file.uuid}`} src={imageThumb} alt={`${thumbnail} thumb`} className="avatar-title"/>
      </>
    }
  </>
}
