import axios from "axios";
import {handleError} from "./store/core/api";
import toastr from "toastr";
import {ApiEndpoint} from './store/core/endpoint'
import {EntityType} from './store/core/entityType'

const jwtDecode = require("jwt-decode");

export const getAxiosDefaultConfig = (blob, cancelToken) => {
  const token = getToken()

  const authorizationHeaders = {
    "X-TenantID": getTenantId()
  }

  if (token) {
    authorizationHeaders['Authorization'] = `Bearer ${token}`
  }

  if (blob)
    return {
      headers: {
        ...authorizationHeaders,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      cancelToken: cancelToken,
      responseType: "blob",
    };
  return {
    headers: {
      ...authorizationHeaders
    },
  };
};

export function getTenantId() {
  try {
    const path = window.location.pathname;
    const parts = path.split("/");
    return parts[1];
  } catch (e) {
    return null;
  }
}

export function getToken() {
  return getAuthInfo("jwtToken");
}

export function getUsername() {
  const token = getToken();
  const decoded = jwtDecode(token);
  return decoded.sub;
}

export function getEmail() {
  const token = getToken();
  const decoded = jwtDecode(token);
  return decoded.email;
}

export function getAuthInfo(prop) {
  const credentials = localStorage.getItem("authUser");
  if (!credentials) return null;
  return prop ? JSON.parse(credentials)[prop] : JSON.parse(credentials);
}

export const createMap = (array, prop = "id") =>
  new Map(array.map((item) => [item[prop], item]));

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function makeid(length = 10) {
  let result = '';
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const downloadFile = (
  downloadUrl,
  file,
  downloadLoading,
  setDownloadLoading
) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };
  axios
    .get(downloadUrl, {headers, responseType: "blob"})
    .then((response) => {
      const {data} = response;
      let fileName = file.filename;
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", decodeURIComponent(fileName)); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();

      setDownloadLoading &&
      setDownloadLoading({...downloadLoading, [file.uuid]: false});
    })
    .catch((e) => {
      toastr.error(
        `Something went wrong. Error code: ${e.request.status}.`,
        "Error"
      );
      setDownloadLoading &&
      setDownloadLoading({...downloadLoading, [file.uuid]: false});
    });
};

export const downloadFileGeneral = (
  url,
  loading,
  setLoading,
  body = null
) => {
  setLoading &&
  setLoading(true);
  axios
    .post(url, {...body}, getAxiosDefaultConfig(true))
    .then((response) => {
      const {data, headers} = response;
      let fileName = headers['content-disposition'].split('"')[1]
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([data], {type: headers['content-type']}));
      link.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(link);
      link.click();
      link.remove();

      setLoading &&
      setLoading(false);
    })
    .catch((e) => {
      console.log(e)
      toastr.error(
        `Something went wrong. Error code: ${e.request.status}.`,
        "Error"
      );
      setLoading &&
      setLoading(false);
    });
};

export const downloadThumb = (
  file,
  downloadUrl,
  setThumbnail,
  isLoading,
  setIsLoading
) => {
  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };
  let base64Data = null;
  axios
    .get(downloadUrl, {headers, responseType: "blob"})
    .then((response) => {
      const {data} = response;
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = function () {
        base64Data = reader.result;
        setThumbnail(base64Data);
        setIsLoading(false);
      };
    })
    .catch((e) => {
      setThumbnail(null);
    });
};

export const fetchData = (url) =>
  axios
    .get(url, getAxiosDefaultConfig())
    .then((res) => res.data)
    .catch(handleError);

export const postData = (url, data) =>
  axios
    .post(url, data, getAxiosDefaultConfig())
    .then((res) => res.data)
    .catch(handleError);

export const deleteData = (url, data) => {
  const config = {
    ...getAxiosDefaultConfig(),
    data,
  };
  return axios.delete(url, config).catch(handleError);
};

export const getIconForDatastream = (name) => {
  const n = name ? name.toLowerCase() : "";
  if (n.includes("image") || n.includes("img")) {
    return "bx-image";
  } else if (n.includes("audio") || n.includes("mp") || n.includes("wav")) {
    return "bx-play";
  } else if (n.includes("video")) {
    return "bx-video";
  } else if (n.includes("pdf")) {
    return "bxs-file-pdf";
  } else if (
    n.includes("excel") ||
    n.includes("xls") ||
    n.includes("spread") ||
    n.includes("csv")
  ) {
    return "bx-spreadsheet";
  } else {
    return "bx-file";
  }
};

export function getTextColor(backgroundHexColor) {
  let hexcolor = backgroundHexColor.replace("#", "");

  if (!/^(?:[0-9a-f]{3}){1,2}$/i.test(hexcolor)) {
    throw new Error("Invalid hex color value: " + backgroundHexColor);
  }

  if (hexcolor.length === 3) {
    hexcolor = [...hexcolor].reduce((acc, char) => acc + char + char, "");
  }

  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 125 ? "#1F2438" : "white";
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export const getFullName = (object) =>
  object && `${object.firstname} ${object.lastname}`;

export const wrapAcceptanceContainers = (containers) => {
  if (!containers) return "";
  return containers
    .filter((x) => x && x.value)
    .reduce((acc, cv) => {
      return acc + cv.value + ";";
    }, "");
};

export const unwrapAcceptanceTypes = (containerString, containerOptions) => {
  return containerString
    ? containerString
      .split(";")
      .map((code) => containerOptions.find((x) => x.value === code))
    : [];
};

export const buildPropertiesPayload = (state) => {
  const properties = [];
  state.forEach((group) => {
    group.properties.forEach((property, index) => {
      properties.push({
        ...property.content,
        position: index,
        groupId: group.groupId,
      });
    });
  }, []);
  return properties;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const extractUrlData = (url, data) => {
  const {__custom_url__} = data;
  if (__custom_url__) {
    return {
      url: __custom_url__,
      data: data.payload,
    };
  } else {
    return {url, data};
  }
};

export function compareProp(a, b, prop = "position") {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
}

export function exportFile(url, data, label) {
  const config = {
    ...getAxiosDefaultConfig(),
    responseType: "arraybuffer",
  };
  return axios
    .post(url, data, config)
    .then((response) => {
      const {data} = response;
      // let fileName = `${label}.xls`;
      let fileName = response.headers["content-disposition"].match(/\"(.*?)\"/);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", decodeURIComponent(fileName[1])); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(handleError);
}

// reorder util
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const getThumb = (uuid,from,setBase64Data) =>{
  if (uuid) {
    let thumbUrl = ''
    if (from === 'stories')
      thumbUrl = `${ApiEndpoint[EntityType.Assets]}/${uuid}/thumb/preview`
    else if (from === 'store')
      thumbUrl = `${ApiEndpoint[EntityType.DataStream]}/${uuid}/thumb/preview`
    else if (from === 'guides')
      thumbUrl = uuid
    else
      thumbUrl = `${ApiEndpoint[EntityType.Assets]}/${uuid}/thumb/preview`
    let headers = {
      Authorization: `Bearer ${getToken()}`,
      "X-TenantID": getTenantId(),
    };


    const p1 = new Promise((resolve, reject) => {
      axios.get(thumbUrl, {headers, responseType: "blob"})
        .then((response) => {
          const {data} = response;
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = async function () {
            const readResult = await reader.result;
            resolve(readResult)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    })
    Promise.all([p1]).then((values)=>{
      setBase64Data(values[0])
    })


  }
}

export const assetThumb = (
  uuid,
  setThumb,
  from,
  thumb,
  append = false,
  getOrigin = false,
  makeId = false,
) => {
  if (uuid) {
    let thumbUrl = ''
    if (from === 'stories')
      thumbUrl = `${ApiEndpoint[EntityType.Assets]}/${uuid}/thumb/preview`
    else if (from === 'store')
      thumbUrl = `${ApiEndpoint[EntityType.DataStream]}/${uuid}/thumb/preview`
    else if (from === 'guides')
      thumbUrl = uuid
    else
      thumbUrl = `${ApiEndpoint[EntityType.Assets]}/${uuid}/thumb/preview`
    let headers = {
      Authorization: `Bearer ${getToken()}`,
      "X-TenantID": getTenantId(),
    };

    axios.get(thumbUrl, {headers, responseType: "blob"})
      .then((response) => {
        const {data} = response;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64Data = reader.result;
          if (append && getOrigin) {
            const thumbArray = makeId?  {origin:from,uuid:uuid,id:makeid()} : {origin:from,uuid:uuid}
            setThumb([...thumb,{...thumbArray}])
          } else {
            if (append)
              setThumb([...thumb, base64Data])
            else {
              if (getOrigin)
                setThumb({image: base64Data, origin: thumbUrl})
              else
                setThumb(base64Data);
            }
          }
        };
      })
      .catch((e) => {
        console.log(e)
        setThumb(null)
      })
  } else
    setThumb(null)
}

export const assetFull = (
  uuid,
  setThumb,
  assetFrom
) => {

  let headers = {
    Authorization: `Bearer ${getToken()}`,
    "X-TenantID": getTenantId(),
  };
  if (uuid) {
    let thumbUrl
    if (assetFrom === 'stories')
      thumbUrl = `${ApiEndpoint[EntityType.Assets]}/${uuid}/thumb/preview`
    else if (assetFrom === 'store')
      thumbUrl = `${ApiEndpoint[EntityType.DataStream]}/${uuid}/thumb/preview`
    else
      thumbUrl = `${ApiEndpoint[EntityType.Assets]}/${uuid}/thumb/preview`

    axios.get(thumbUrl, {headers, responseType: "blob"})
      .then((response) => {
        const {data} = response;
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = function () {
          const base64Data = reader.result;
          setThumb(base64Data);
        };
      })
      .catch((e) => {
        setThumb(null)
      })
  } else
    setThumb(null)
}

export const truncateString = (str, num) => {
  if (str) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  } else
    return ''
}
