import {Box, Grid, IconButton, } from "@material-ui/core";
import {Button} from "reactstrap";
import React, {useCallback, useState} from "react";
import Reorder from "react-reorder";
import {AvailableGuideComponents} from "../../../../constants/enums";
import GuideComponentsBottomMenuDialog from "./GuidComponentsBottomMenuDialog";

const GuideBottomMenu = (props) => {

  const {setGuide, bottomMenu, components,languages} = props
  const [openComponentsDialog, setOpenComponentsDialog] = useState(false)

  const initialLanguage = languages? languages[0] : ''
  const [selectedLanguage,setSelectedLanguage] = useState(initialLanguage)


  const handleAddComponent = (updatedComponents) => {
    setGuide((prevState) => ({
      ...prevState,
      guideDetails: {
        ...prevState.guideDetails,
        bottomMenu: [...updatedComponents]
      }
    }))
    setOpenComponentsDialog(false)
  }

  const handleDeleteComponent = useCallback((e,bottomMenuComponent) => {
    e.stopPropagation()
    e.preventDefault()
    setGuide((prevState) => ({
      ...prevState,
      guideDetails: {
        ...prevState.guideDetails,
        bottomMenu: prevState.guideDetails.bottomMenu.filter((menu)=>menu !==bottomMenuComponent),
      }
    }))
  }, [setGuide])

  const handleReOrder = useCallback((e, previousIndex, nextIndex) => {
    const bottomMenuTmp = JSON.parse(JSON.stringify(bottomMenu))
    bottomMenuTmp[previousIndex] = bottomMenu[nextIndex]
    bottomMenuTmp[nextIndex] = bottomMenu[previousIndex]
    setGuide((prevState) => ({
      ...prevState,
      guideDetails: {
        ...prevState.guideDetails,
        bottomMenu: [...bottomMenuTmp]
      }
    }))
  },[bottomMenu,setGuide])


  return <>
    <Grid container
          alignItems={'center'}
          alignContent={'center'}>
      <Grid item xs={12}>
        <Grid container style={{border: '1px dashed #ececec', height: 220, width: '100%', marginTop: 20, padding: 5}}
              alignItems={bottomMenu?.length > 0 ? 'center' : 'center'}
              alignContent={bottomMenu?.length > 0 ? 'flex-start' : 'center'}
              justifyContent={bottomMenu?.length > 0 ? 'center' : 'center'}
              spacing={1}>
          {bottomMenu?.length > 0 ?
              <Reorder
                reorderId={'bottomMenuComponent'}
                autoScroll
                draggedClassName="draggedComponent"
                onReorder={handleReOrder}
                placeholderClassName={'MuiGrid-item'}
                // holdTime={50}
                disableContextMenus
              >
              {bottomMenu?.map((menuItem) => {
                const menuItemObj = components.find((component)=>component.id === menuItem)
                const componentIcon = menuItemObj ? AvailableGuideComponents.find((availableGuideComponent)=>availableGuideComponent?.type === menuItemObj.type)?.icon : ''
                return <Grid key={`component-menu-${menuItem}`} item style={{border: '1px solid #ececec', height: 40,width:'270px',padding:'8px 8px 8px 12px'}} xs={12} className={'draggedComponent'}>
                  <Grid container style={{height: 40}} spacing={1} alignContent={'center'} alignItems={'center'} justifyContent={'flex-start'}>
                    <Grid item>
                      <i className={componentIcon}></i>
                    </Grid>
                    <Grid item style={{userSelect:'none'}}>
                      {menuItemObj?.title[selectedLanguage]}
                    </Grid>
                    <Grid item style={{marginLeft: 'auto'}}>
                      <IconButton size={'small'} onClick={(e) => handleDeleteComponent(e,menuItemObj?.id)}>
                        <i className="bx bx-trash"/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              })}
              </Reorder>
            :
            <>
              <Grid item>
                You have no menu components
              </Grid>
            </>
          }
        </Grid>
      </Grid>
    </Grid>
    <Box mt={4} width={'100%'} display={'flex'} justifyContent={'center'}>
      <Button color='primary' disabled={bottomMenu?.length === 5} onClick={() => setOpenComponentsDialog(true)}>Add a Component</Button>
    </Box>
    <GuideComponentsBottomMenuDialog languages={languages} open={openComponentsDialog} components={components} bottomMenu={bottomMenu} handleAddComponent={handleAddComponent}
                           handleClose={() => setOpenComponentsDialog(false)}/>
  </>
}

export default GuideBottomMenu