import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from "reactstrap";
import BaseContent from '../../../components/Common/BaseContent'
import axios from 'axios';
import {API_URL} from "../../../config";
import {getAxiosDefaultConfig} from "../../../utils";
import {Box, Divider, Grid} from "@material-ui/core";
import MobileMockup from "../components/MobileMockup";
import GuideBottomMenu from "../components/Sidebar/GuideBottomMenu";
import MobileViewBottomMenu from "../components/MobileView/MobileViewBottomMenu";
import GuideComponents from "../components/Sidebar/GuideComponents";
import ItemsList from "../components/ItemsList";
import GuideItems from "../components/Sidebar/GuideItems";
import moment from "moment";
import ComponentsList from "../components/ComponentsList";
import GuideContext from "./GuideSidebarContext";
import SweetAlert from "react-bootstrap-sweetalert";
import {Prompt} from "react-router";

const OptionBox = (props) => {
  const {title, icon, selected} = props

  return <Grid container alignContent={'center'} direction={'column'} alignItems={'center'}
               justifyContent={'center'} {...props} style={{marginBottom: 15}}>
    <Grid item className='font-size-24' xs={12}>
      <i className={`${icon}`} style={{color: selected ? '#556ee6' : ''}}/>
    </Grid>
    <Grid item xs={12}>
      <div className='font-size-10'>{title}</div>
    </Grid>
  </Grid>
}

const GuideEditPage = (props) => {
  const [guide, setGuide] = useState()
  const [isDirty,setIsDirty] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [editItem, setEditItem] = useState({hideSidebar:false,data:{}})
  const [hideSidebar,setHideSidebar] = useState(false)
  const [selectedOption, setSelectedOption] = useState('items')
  const [openDeleteDialog,setOpenDeleteDialog] = useState(null)
  const [itemExistsDialog,setItemExistsDialog] = useState(null)
  const uuid = props.match.params.uuid

  const bottomMenu = useMemo(() => {
    return guide?.guideDetails?.bottomMenu
  }, [guide?.guideDetails?.bottomMenu])

  const components = useMemo(() => {
    return guide?.guideDetails?.components
  }, [guide?.guideDetails?.components])


  useEffect(() => {
    axios.get(`${API_URL}/guides/${uuid}`, getAxiosDefaultConfig()).then((response) => {
      setIsLoading(false)
      setGuide(response.data)
    }).catch((e) => {
      setIsLoading(false)
      console.log(e)
    })

  }, [uuid])


  const handleSetItems = useCallback((newItem) => {
    setIsDirty(true)
    newItem.updatedAt = moment()
    const guideTemp = JSON.parse(JSON.stringify(guide))
    const previousItems = guideTemp.guideDetails.items.filter((item) => item.id !== newItem.id)
    let updatedItem = guideTemp.guideDetails.items.filter((item) => item.id === newItem.id)
    updatedItem[0] = {...newItem}
    guideTemp.guideDetails.items = [...previousItems, ...updatedItem]
    setGuide(guideTemp)
    setEditItem({hideSidebar:false,data:{}})
  }, [guide])


  const handleUpdateGuide = useCallback(() => {
    setIsLoading(true)
    const payload = {
      title: guide.title,
      description: guide.description,
      coverImage: guide.coverImage,
      guideDetails: guide.guideDetails
    }
    axios.put(`${API_URL}/guides/${uuid}`, payload, getAxiosDefaultConfig()).then((response) => {
      setIsLoading(false)
      setGuide(response.data)
      setIsDirty(false)
    }).catch((e) => {
      setIsLoading(false)
      console.log(e)
      setIsDirty(false)
    })
  }, [guide, uuid])



  const handleDeleteItemAndRemoveFromComponent = useCallback(()=>{
    setIsDirty(true)
      const {item,component} = itemExistsDialog
    const filteredItems = guide.guideDetails.items?.filter((i) => i.id !== item)
    const componentWithoutThisItem = component.listOfItems.filter((i)=>i.id !== item)
    const componentsTmp = JSON.parse(JSON.stringify(guide.guideDetails.components))
    const componentIndex = guide.guideDetails.components.findIndex((c)=>c.id === component.id)
    componentsTmp[componentIndex] = {...component,listOfItems:[...componentWithoutThisItem]}

    setGuide({...guide,
      guideDetails:{
      ...guide.guideDetails,
        items:filteredItems,
        components:componentsTmp
      }})

    setItemExistsDialog(null)
  },[itemExistsDialog,guide,setGuide])

  const handleDeleteItem = useCallback((itemId) => {
    const itemExistsInAList = guide.guideDetails.components.find((i)=>i.listOfItems.includes(itemId))

    // check if item exists in a component and prevent from deleting
    if (itemExistsInAList?.id) {
      setItemExistsDialog({item:itemId,component:itemExistsInAList})
    }
    else {
      const filteredItems = guide.guideDetails.items?.filter((item) => item.id !== itemId)
      setGuide({...guide, guideDetails: {...guide.guideDetails, items: filteredItems}})
    }
  }, [guide])

  const handleComponentsUpdate = useCallback((updatedComponents) => {
    setIsDirty(true)
    setGuide({
      ...guide,
      guideDetails: {
        ...guide.guideDetails,
        components: updatedComponents
      }
    })
  }, [guide])

  const languages = guide?.guideDetails?.languages


  const renderMobileView = useCallback((selectedOption) => {
    if (selectedOption === 'bottomMenu')
      return <MobileViewBottomMenu bottomMenu={bottomMenu} languages={languages} components={components} items={guide?.guideDetails?.items}/>
  }, [guide, bottomMenu, components])

  const renderSidebarView = useCallback(() => {
    if (selectedOption === 'bottomMenu')
      return <GuideBottomMenu guide={guide} setGuide={setGuide} bottomMenu={bottomMenu} components={components} languages={languages}/>
    else if (selectedOption === 'components')
      return <GuideComponents guide={guide} setGuide={setGuide} bottomMenu={bottomMenu} components={components}/>
    else if (selectedOption === 'items')
      return <GuideItems guide={guide} setGuide={setGuide} components={components} isLoading={isLoading}/>
  }, [guide, selectedOption, setGuide, bottomMenu, components, isLoading])


  // Center container rendering different components based on selectedOption
  const ContainerBasedOnSelection = () => {
    if (selectedOption === 'items')
      return <ItemsList items={guide?.guideDetails?.items} languages={guide?.guideDetails?.languages} setItems={handleSetItems}
                        handleDeleteItem={setOpenDeleteDialog}/>
    else if (selectedOption === 'components')
      return <ComponentsList guide={guide}
                             setHideSidebar={setHideSidebar}
                             setGuide={setGuide} items={guide?.guideDetails?.items} hideSidebar={hideSidebar} languages={languages} components={guide?.guideDetails?.components}
                             setComponents={handleComponentsUpdate}/>
    else
      return <MobileMockup>
        {renderMobileView(selectedOption)}
      </MobileMockup>
  }

  return <GuideContext.Provider value={{editItem, setEditItem,hideSidebar,setHideSidebar}}>
    <BaseContent
      title={'New Guide'}
      isRoot={true}
      breadcrumbs={[
        {title: 'Guides', link: '/guides'},
        {title: `${guide?.title || ''}`, link: '/guides'},
      ]}
    >

      <ContainerBasedOnSelection/>
        <Box style={{
          backgroundColor: 'white',
          top: 70,
          right: 0,
          width: 300,
          display:editItem.hideSidebar || hideSidebar?'none':'',
          height: 'calc(100vh - 5.5em)',
          padding: 10,
          position: 'absolute'
        }}>
          <div className='font-size-15 font-weight-bold' style={{marginTop: 10}}>Settings</div>
          <Grid container style={{marginTop: 40}}>
            <Grid item xs={4}>
              <OptionBox title='Items' icon='bx bx-book-content' selected={selectedOption === 'items'}
                         onClick={() => setSelectedOption('items')} {...props}/>
            </Grid>
            <Grid item xs={4}>
              <OptionBox title='Components' icon='bx bx-collection' selected={selectedOption === 'components'}
                         onClick={() => setSelectedOption('components')} {...props}/>
            </Grid>
            <Grid item xs={4}>
              <OptionBox title='Menu' icon='bx bx-mobile' selected={selectedOption === 'bottomMenu'}
                         onClick={() => setSelectedOption('bottomMenu')} {...props}/>
            </Grid>
            <Grid item xs={4}>
              <OptionBox title='Settings' icon='bx bx-wrench' selected={selectedOption === 'settings'}
                         onClick={() => setSelectedOption('settings')} {...props}/>
            </Grid>
            <Grid item xs={4}>
              <OptionBox title='Publish' icon='bx bx-rocket' selected={selectedOption === 'publish'}
                         onClick={() => setSelectedOption('publish')} {...props}/>
            </Grid>
          </Grid>
          <Box>
            <Divider/>
            {renderSidebarView()}
            <Button color='secondary' style={{position: 'absolute', bottom: 40, right: 50, width: 200}}
                    disabled={isLoading}
                    onClick={handleUpdateGuide}>
          <span>{isLoading &&
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Save Changes</span>
            </Button>
          </Box>
        </Box>
      {openDeleteDialog && <SweetAlert
        title={"Remove confirmation"}
        warning
        onConfirm={()=> {
          handleDeleteItem(openDeleteDialog)
          setOpenDeleteDialog(null)
        }}
        onCancel={() => {setOpenDeleteDialog(null)}}>
        Are you sure you wish to delete this item?
      </SweetAlert>}
      {itemExistsDialog && <SweetAlert
        title={"Warning"}
        warning
        onConfirm={()=> {
          handleDeleteItemAndRemoveFromComponent()
        }}
        onCancel={() => {setItemExistsDialog(null)}}>
        This Item is inside a <strong>{itemExistsDialog.component.type}</strong> component <b>{itemExistsDialog.component.title[languages[0]]}</b>. Do you wish to proceed on deleting?
      </SweetAlert>}
    </BaseContent>
    <Prompt
    when={isDirty}
    message="You have unsaved changes. Do you still want to leave?"
  />
  </GuideContext.Provider>
}


export default GuideEditPage