import React, {useEffect, useState} from 'react'
import BaseDialog from "../../../../components/Common/BaseDialog";
import {GoogleMap, Marker, withGoogleMap} from "react-google-maps";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";
import {Box} from "@material-ui/core";


const MapWithAMarker = withGoogleMap((props) => {
  const {latState, lngState} = props;
  return (
    <>
      <GoogleMap defaultZoom={8} center={{lat: latState, lng: lngState}}>
        <Marker position={{lat: latState, lng: lngState}} draggable onDragEnd={props.onMarkerDragEnd} />
      </GoogleMap>
    </>
  );
});

const GetSpatialInfoMap = ({open,handleClose,handleChange,lat,long}) =>{
  const [mapLat,setMapLat] = useState(37.9908997)
  const [mapLong,setMapLong] = useState(23.7033199)

  useEffect(()=>{
    if (open) {
      setMapLat(parseFloat(lat) || 37.9908997)
      setMapLong(parseFloat(long) || 23.7033199)
    }
  },[open,lat,long])

  const onMarkerDragEnd = (event) => {
    setMapLat(event.latLng.lat())
    setMapLong(event.latLng.lng())
  };

  const handleSelectMap = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({lat, lng}) => {
        setMapLat(lat);
        setMapLong(lng);

      })
      .catch((error) => console.error(error));
  };

  return <BaseDialog
    scrollable={true}
    extraClassName={"modal-full-height"}
    open={open}
    title={'Spatial Info'}
    handleClose={handleClose}
    size={'lg'}
    primaryAction={() => {
      handleChange({lat:mapLat,long:mapLong})
      handleClose()
    }}
    primaryActionTitle={'Save'}
    secondaryAction={handleClose}
    secondaryActionTitle={'Close'}
  >
    <GooglePlacesAutocomplete
      initialValue={''}
      onSelect={({description}) => {
        handleSelectMap(description);
      }}
      inputClassName="form-control"
    />
    <Box mt={10}/>
    <MapWithAMarker
      defaultCenter={{lat: mapLat, lng: mapLong}}
      containerElement={<div style={{height: `400px`}} />}
      mapElement={<div style={{height: `100%`}} />}
      loadingElement={<div style={{height: `100%`}} />}
      latState={mapLat}
      lngState={mapLong}
      draggable={true}
      onMarkerDragEnd={onMarkerDragEnd}
    />
  </BaseDialog>
}


export default GetSpatialInfoMap