import React, {useCallback, useContext, useState} from 'react'
import {makeid} from "../../../../utils";
import {Button} from "reactstrap";
import moment from "moment";
import GuideComponentsDialog from "../ComponentsList/GuideComponentsDialog";
import {Box} from "@material-ui/core";
import GuideContext from "../../GuideEditPage/GuideSidebarContext";

const dummyItem = {
  id: "",
  code: "",
  slug: "",
  title: "",
  createdAt: '',
  updatedAt: '',
  description: "",
  layout: "STANDARD",
  coverImage: "",
  spatialInfo: {
    placeName: "",
    latitude: "",
    longitude: "",
    staticImage: ""
  },
  contactInfo: {
    telephone: "",
    mobile: "",
    email: "",
    siteUrl: ""
  },
  socialLinks: {
    facebookUrl: "",
    instagramUrl: "",
    tripAdvisorUrl: "",
    youtubeUrl: "",
    twitterUrl: ""
  },
  beforeAfterWidget: {
    visible: false,
    before: {
      uuid: "",
      thumb: ""
    },
    after: {
      uuid: "",
      thumb: ""
    }
  },
  images: [],
  video: [],
  audio: [],
  files: []
}

const GuideItems = ({ guide, setGuide,isLoading}) =>{
  const {setEditItem} = useContext(GuideContext)
  const createNewItem = useCallback(()=>{
      const newItem = {...dummyItem}
      newItem.id = makeid()
      newItem.createdAt = moment()
      newItem.updatedAt = moment()
      const prevState = {...guide}
      prevState.guideDetails.items = [...prevState.guideDetails.items,newItem]
      setGuide(prevState)
      setEditItem({hideSidebar:true, data:newItem})
    },[guide, setEditItem, setGuide])

  return <Box width={'100%'} display={'flex'} mt={4} alignItems={'center'} justifyContent={'center'}>
            <Button color='primary' disabled={isLoading} onClick={createNewItem}>New Item</Button>
        </Box>
}

export default GuideItems