import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import React,{useCallback, useState} from "react";

const GuideHtmlEditor = ({selectedLanguage,handleChangeMultilingual,value,objectKey='description'}) =>{
  const initHtml = value ? {contentHtml: JSON.parse(value)} : {contentHtml: '<p> </p>'}
  const initContentBlock = htmlToDraft(initHtml.contentHtml)
  const initContentState = ContentState.createFromBlockArray(initContentBlock.contentBlocks)
  const initEditorState = EditorState.createWithContent(initContentState)
  const [editorState, setEditorState] = useState(initEditorState);
  const editorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleChangeEditor = useCallback((e) => {
    handleChangeMultilingual(objectKey, JSON.stringify(draftToHtml(e)),selectedLanguage)
  }, [handleChangeMultilingual, selectedLanguage])

  return <Editor
    editorState={editorState}
    wrapperClassName="demo-wrapper"
    value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
    editorClassName="demo-editor"
    onEditorStateChange={(editorState) => editorChange(editorState)}
    onChange={handleChangeEditor}
  />
}

export default GuideHtmlEditor