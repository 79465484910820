import React, {useCallback, useState} from 'react'
import {Box, Grid, IconButton, makeStyles, TextField, Typography} from "@material-ui/core";
import {AvailableGuideComponents, GuideComponents} from "../../../../constants/enums";
import {Button, Label} from "reactstrap";
import {truncateString} from "../../../../utils";
import {DeleteOutlined, EditOutlined} from "@material-ui/icons";
import GuideItemsDialog from "./GuideItemsDialog";
import GuideLanguageSelection from "../GuideLanguageSelection";
import CoverImage from "../ItemsList/CoverImage";
import GuideHtmlEditor from "../GuideHtmlEditor";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 'calc(100vw - 600px)', width: "auto"
  },
  editItemContainer: {
    backgroundColor: '#ffffff',
    padding: 14,
    width: 'calc(100vw - 620px)',
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
    minHeight: 480,
    height: 'auto',
    position: 'relative'
  },
  addedListItem: {
    padding: 4,
    boxShadow: '1px 1px 4px 0px #00000036',
    backgroundColor: '#d8ddf9',
    color: '#556ee6',
    fontWeight: 500,
    border: '1px solid #ced6ff',
    borderRadius: 3,
    position:'relative'
  },
  addItemButton: {
    width: 40,
    height: 40,
    padding: 4,
    cursor: 'pointer',
    borderRadius: 20,
    backgroundColor: '#556ee6',
    color: 'white',
    border: '1px solid #ececec',
    position:'absolute',
    bottom:2,
    right:5,
  },
  description: {
    color: 'rgb(147, 147, 147)',
    fontStyle: 'italic',
    fontSize: 12
  },
  componentCard: {
    backgroundColor: '#ffffff',
    padding: 8,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
    height: 140,
    position: 'relative'
  }
}))

const EditComponentContainer = (props) => {
  const classes = useStyles()
  const {handleClose, handleSave,languages} = props
  return <div className={classes.editItemContainer}>
    {props.children}
    <Box style={{position: "absolute", bottom: 10, right: 10}}>
      <Grid container spacing={2}>
        <Grid item>
          <Button onClick={handleClose}>Close</Button>
        </Grid>
        <Grid item>
          <Button onClick={handleSave} color={'primary'}>Save</Button>
        </Grid>
      </Grid>
    </Box>
  </div>
}
const RoutesEditComponent = ({currentComponent, setCurrentComponent, handleClose, handleSave, items,components,languages}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const initialLanguage = languages? languages[0] : ''
  const [selectedLanguage,setSelectedLanguage] = useState(initialLanguage)

  const handleChange = useCallback((key, value, objectKey) => {
    if (objectKey) {
      setCurrentComponent((prevState) => ({
        ...prevState,
        [objectKey]: {
          ...prevState[objectKey],
          [key]: value
        }
      }))
    } else
      setCurrentComponent((prevState) => ({...prevState, [key]: value}))
  }, [setCurrentComponent])

  const handleChangeMultilingual = useCallback((key, value,selectedLanguage) => {
    setCurrentComponent((prevState) => ({...prevState, [key]: {
        ...prevState[key],
        [selectedLanguage]: value}
    }))
  }, [setCurrentComponent])

  const handleAddSelectedItems = useCallback((selectedItems) => {
    setCurrentComponent({...currentComponent, listOfItems: [...selectedItems.listOfItems],listOfLists: [...selectedItems.listOfLists]})
  }, [currentComponent, setCurrentComponent])


  const handleDeleteItem = useCallback((selectedItem) => {
    const selectedItems = currentComponent?.listOfItems
    const selectedLists = currentComponent?.listOfLists
    setCurrentComponent({...currentComponent, listOfItems: selectedItems?.filter((item)=>item !== selectedItem),listOfLists:selectedLists.filter((item)=>item !== selectedItem)})
  }, [currentComponent, setCurrentComponent])

  return <EditComponentContainer handleClose={handleClose} handleSave={handleSave} languages={languages}>
    <Grid container direction={'row'} spacing={1}>
      <Grid item xs={6}>
        <Grid container direction={"column"} spacing={1}>
          <Grid item xs={8}>
            <Typography variant={'h6'}> Basic Information</Typography>
          </Grid>
          <Grid item xs={4}>
            <GuideLanguageSelection languages={languages} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}/>
          </Grid>
          <Grid item xs={12}>
            <Label>Title</Label>
            <TextField key={`title-${selectedLanguage}`}
                       variant={'outlined'} size={'small'} value={currentComponent?.title[selectedLanguage]}
                       onChange={(e) => handleChangeMultilingual('title', e.target.value,selectedLanguage)} placeholder={'Please type a title'}
                       fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <Label>Description</Label>
            <TextField key={`description-${selectedLanguage}`}
                       variant={'outlined'} size={'small'} value={currentComponent?.description[selectedLanguage]}
                       onChange={(e) => handleChangeMultilingual('description', e.target.value,selectedLanguage)}
                       placeholder={'Please type a description'} fullWidth/>
          </Grid>
          <Grid item xs={4}>
            <Label>Duration</Label>
            <TextField key={`duration`}
                       variant={'outlined'} size={'small'} value={currentComponent?.duration}
                       onChange={(e) => handleChange('duration', e.target.value)}
                       placeholder={'Total Minutes'} fullWidth/>
          </Grid>
          <Grid item xs={2}>
            <Label>Cover Image</Label>
            <CoverImage coverImage={currentComponent?.coverImage} handleChange={handleChange}/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} style={{border: '1px dashed #ececec',maxHeight:400,height:"auto",overflow:'auto',overflowY:'auto',overflowX:'hidden', padding: 2,position:'relative'}}>
        <Grid container direction={'row'} spacing={2}>
          {currentComponent?.listOfItems?.map((listItem) => {
            const listItemObj = items.find((item) => item?.id === listItem && item.title[selectedLanguage])
            if (listItemObj)
              return <Grid item key={`item-${selectedLanguage}-${listItemObj?.id}`} xs={4}>
                <Grid container className={classes.addedListItem}>
                  <Grid item xs={4} alignContent={'center'} justifyContent={'flex-start'} wrap={'nowrap'}>
                    {listItemObj?.title[selectedLanguage]}
                  </Grid>
                  <Grid item style={{position: 'absolute',bottom:2,right:2}}>
                    <IconButton size={'small'} onClick={()=>handleDeleteItem(listItem)}>
                      <DeleteOutlined fontSize={'small'}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
          })}
          {currentComponent?.listOfLists?.map((listItem) => {
            const listItemObj = components.find((item) => item?.id === listItem && item.title[selectedLanguage])
            if(listItemObj)
              return <Grid item key={`list-${selectedLanguage}-${listItemObj?.id}`} xs={4}>
                <Grid container className={classes.addedListItem}>
                  <Grid item xs={4} alignContent={'center'} justifyContent={'flex-start'} wrap={'nowrap'}>
                    {listItemObj?.title[selectedLanguage]}
                  </Grid>
                  <Grid item style={{position: 'absolute',bottom:2,right:2}}>
                    <IconButton size={'small'} onClick={()=>handleDeleteItem(listItem)}>
                      <DeleteOutlined fontSize={'small'}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
          })}
        </Grid>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={4}>
            <Box alignItems={'center'} onClick={() => setOpen(true)} justifyContent={'center'} display={'flex'}
                 className={classes.addItemButton}>+</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <GuideItemsDialog open={open} handleClose={() => setOpen(!open)}
                      items={items}
                      languages={languages}
                      componentType={'ROUTES'}
                      currentComponentId={currentComponent?.id}
                      components={components}
                      handleAddSelectedItems={handleAddSelectedItems}
                      preSelectedItems={currentComponent?.listOfItems}
                      preSelectedLists={currentComponent?.listOfLists}/>
  </EditComponentContainer>
}
const ListEditComponent = ({currentComponent, setCurrentComponent, handleClose, handleSave, items,components,languages}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const initialLanguage = languages? languages[0] : ''
  const [selectedLanguage,setSelectedLanguage] = useState(initialLanguage)

  const handleChange = useCallback((key, value, objectKey) => {
    if (objectKey) {
      setCurrentComponent((prevState) => ({
        ...prevState,
        [objectKey]: {
          ...prevState[objectKey],
          [key]: value
        }
      }))
    } else
      setCurrentComponent((prevState) => ({...prevState, [key]: value}))
  }, [setCurrentComponent])

  const handleChangeMultilingual = useCallback((key, value,selectedLanguage) => {
    setCurrentComponent((prevState) => ({...prevState, [key]: {
        ...prevState[key],
        [selectedLanguage]: value}
    }))
  }, [setCurrentComponent])

  const handleAddSelectedItems = useCallback((selectedItems) => {
    setCurrentComponent({...currentComponent, listOfItems: [...selectedItems.listOfItems],listOfLists: [...selectedItems.listOfLists]})
  }, [currentComponent, setCurrentComponent])


  const handleDeleteItem = useCallback((selectedItem) => {
    const selectedItems = currentComponent?.listOfItems
    const selectedLists = currentComponent?.listOfLists
    setCurrentComponent({...currentComponent, listOfItems: selectedItems.filter((item)=>item !== selectedItem),listOfLists:selectedLists.filter((item)=>item !== selectedItem)})
  }, [currentComponent, setCurrentComponent])

  return <EditComponentContainer handleClose={handleClose} handleSave={handleSave} languages={languages}>
    <Grid container direction={'row'} spacing={1}>
      <Grid item xs={6}>
        <Grid container direction={"column"} spacing={1}>
          <Grid item xs={8}>
            <Typography variant={'h6'}> Basic Information</Typography>
          </Grid>
          <Grid item xs={4}>
            <GuideLanguageSelection languages={languages} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}/>
          </Grid>
          <Grid item xs={12}>
            <Label>Title</Label>
            <TextField key={`title-${selectedLanguage}`}
                       variant={'outlined'} size={'small'} value={currentComponent?.title[selectedLanguage]}
                       onChange={(e) => handleChangeMultilingual('title', e.target.value,selectedLanguage)} placeholder={'Please type a title'}
                       fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <Label>Description</Label>
            <TextField key={`description-${selectedLanguage}`}
                       variant={'outlined'} size={'small'} value={currentComponent?.description[selectedLanguage]}
                       onChange={(e) => handleChangeMultilingual('description', e.target.value,selectedLanguage)}
                       placeholder={'Please type a description'} fullWidth/>
          </Grid>
          <Grid item xs={2}>
            <Label>Cover Image</Label>
            <CoverImage coverImage={currentComponent?.coverImage} handleChange={handleChange}/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} style={{border: '1px dashed #ececec',maxHeight:400,height:"auto",overflowY:'auto',overflowX:'hidden', padding: 2,position:'relative'}}>
        {currentComponent?.listOfLists?.length === 0 && currentComponent?.listOfItems?.length === 0 && (
          <Box width={'100%'} height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} style={{cursor:'pointer'}} onClick={() => setOpen(true)}>Your content is empty</Box>
        )}
        <Grid container direction={'row'} spacing={2}>
          {currentComponent?.listOfItems?.map((listItem) => {
            const listItemObj = items.find((item) => item?.id === listItem && item.title[selectedLanguage])
            if (listItemObj)
            return <Grid item key={`item-${selectedLanguage}-${listItemObj?.id}`} xs={4}>
              <Grid container className={classes.addedListItem}>
                <Grid item xs={4} alignContent={'center'} justifyContent={'flex-start'} wrap={'nowrap'}>
                  {listItemObj?.title[selectedLanguage]}
                </Grid>
                <Grid item style={{position: 'absolute',bottom:2,right:2}}>
                  <IconButton size={'small'} onClick={()=>handleDeleteItem(listItem)}>
                  <DeleteOutlined fontSize={'small'}/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          })}
          {currentComponent?.listOfLists?.map((listItem) => {
            const listItemObj = components.find((item) => item?.id === listItem && item.title[selectedLanguage])
            if(listItemObj)
            return <Grid item key={`list-${selectedLanguage}-${listItemObj?.id}`} xs={4}>
              <Grid container className={classes.addedListItem}>
                <Grid item xs={4} alignContent={'center'} justifyContent={'flex-start'} wrap={'nowrap'}>
                  {listItemObj?.title[selectedLanguage]}
                </Grid>
                <Grid item style={{position: 'absolute',bottom:2,right:2}}>
                  <IconButton size={'small'} onClick={()=>handleDeleteItem(listItem)}>
                    <DeleteOutlined fontSize={'small'}/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          })}
        </Grid>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={4}>
            <Box alignItems={'center'} onClick={() => setOpen(true)} justifyContent={'center'} display={'flex'}
                 className={classes.addItemButton}>+</Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <GuideItemsDialog open={open} handleClose={() => setOpen(!open)}
                      items={items}
                      languages={languages}
                      currentComponentId={currentComponent?.id}
                      components={components}
                      handleAddSelectedItems={handleAddSelectedItems}
                      preSelectedItems={currentComponent?.listOfItems}
                      preSelectedLists={currentComponent?.listOfLists}/>
  </EditComponentContainer>
}

const StaticEditComponent = ({currentComponent, setCurrentComponent, handleClose, handleSave,languages}) => {
  const initialLanguage = languages? languages[0] : ''
  const [selectedLanguage,setSelectedLanguage] = useState(initialLanguage)

  console.log(currentComponent)

  const handleChangeMultilingual = useCallback((key, value,selectedLanguage) => {
    setCurrentComponent((prevState) => ({...prevState, [key]: {
        ...prevState[key],
        [selectedLanguage]: value}
    }))
  }, [setCurrentComponent])



  return <EditComponentContainer handleClose={handleClose} handleSave={handleSave}>
    <Grid container direction={'column'} spacing={2}>
      <Grid item xs={12}>
        <Grid container direction={"column"}>
          <Grid item xs={12}>
            <Typography variant={'h6'}> Basic Information</Typography>
          </Grid>
          <Grid item xs={5}>
            <GuideLanguageSelection languages={languages} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}/>
          </Grid>
          <Grid item xs={6}>
            <Label>Title</Label>
            <TextField key={`title-${selectedLanguage}`}
                       variant={'outlined'} size={'small'} value={currentComponent?.title[selectedLanguage]}
                       onChange={(e) => handleChangeMultilingual('title', e.target.value,selectedLanguage)} placeholder={'Please type a title'}
                       fullWidth/>
          </Grid>
          <Grid item xs={6}>
            <Label>Description</Label>
            <TextField key={`description-${selectedLanguage}`}
                       variant={'outlined'} size={'small'} value={currentComponent?.description[selectedLanguage]}
                       onChange={(e) => handleChangeMultilingual('description', e.target.value,selectedLanguage)}
                       placeholder={'Please type a description'} fullWidth/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <GuideHtmlEditor key={`html-${selectedLanguage}`} value={currentComponent?.contentHtml[selectedLanguage]} selectedLanguage={selectedLanguage} handleChangeMultilingual={handleChangeMultilingual} objectKey={'contentHtml'}/>
      </Grid>
    </Grid>
  </EditComponentContainer>
}

const ComponentsList = ({components, setComponents, items,languages,guide,setGuide,setHideSidebar}) => {
  const classes = useStyles()
  const [edit, setEdit] = useState({})
  const [openDeleteDialog,setOpenDeleteDialog] = useState(null)
  const [deleteFromMenu,setDeleteFromMenu] = useState(null)
  const getComponentIcon = (componentType) => {
    const foundComponent = AvailableGuideComponents.find((component) => component.type === componentType)
    return foundComponent.icon
  }

  const handleEditComponent = (component) => {
    setEdit(component)
  }

  const handleDelete = useCallback((component) => {
    const bottomMenu = guide.guideDetails.bottomMenu
    const existsInBottomMenu = bottomMenu.find((b)=>b === component.id)
    if (existsInBottomMenu)
      setDeleteFromMenu(component)
     else
      setComponents(components?.filter((com) => com.id !== component.id))
  },[guide])

  const handleDeleteComponentAndRemoveFromBottomMenu = useCallback(()=>{
    const guideTmp = JSON.parse(JSON.stringify(guide))
    const listsThatContainThisComponent = guideTmp.guideDetails.components?.map((o)=>{return {...o,listOfLists:[...o.listOfLists?.filter((l)=>l !== deleteFromMenu.id)]}})?.filter((c)=>c.id !== deleteFromMenu.id)

    setGuide({
      ...guide,
      guideDetails:{
        ...guide.guideDetails,
        components:[...listsThatContainThisComponent],
        bottomMenu:[...guide.guideDetails.bottomMenu?.filter((b)=>b!== deleteFromMenu.id)]
      }
    })

    setDeleteFromMenu(null)
  },[deleteFromMenu,guide,setGuide])

  const initialLanguage = languages? languages[0] : ''

  const handleSave = useCallback(() => {
    const componentsTmp = JSON.parse(JSON.stringify(components))
    const foundIndex = componentsTmp.findIndex((com) => (com.id === edit.id))
    componentsTmp[foundIndex] = {...edit}
    setComponents(componentsTmp)
    setEdit({})
  }, [components, edit, setComponents])

  const renderEditComponent = useCallback(() => {
    switch (edit.type) {
      case GuideComponents.list : {
        return <ListEditComponent currentComponent={edit}
                                  items={items}
                                  languages={languages}
                                  components={components}
                                  setCurrentComponent={setEdit} handleClose={() => setEdit({})}
                                  handleSave={handleSave}/>
      }
      case GuideComponents.static : {
        return <StaticEditComponent currentComponent={edit}
                                    languages={languages}
                                    setCurrentComponent={setEdit}
                                    handleClose={() => setEdit({})} handleSave={handleSave}/>
      }
      case GuideComponents.routes : {
        return <RoutesEditComponent currentComponent={edit}
                                    languages={languages}
                                    items={items}
                                    components={components}
                                    setCurrentComponent={setEdit}
                                    handleClose={() => setEdit({})} handleSave={handleSave}/>
      }
      default:
        return <div>Type not found</div>
    }
  }, [edit, items, languages, components, handleSave])

  if (edit?.id) {
    return <>{renderEditComponent()}</>
  }

  return <>
    <Grid container className={classes.container} spacing={2}>
    {components?.map((component) => {
      return <Grid item xs={2} className={classes.componentCard} key={component.id}>
        <Grid container direction={'column'} spacing={1}>
          {component.coverImage?.thumb && <img src={component.coverImage?.thumb} style={{  width: "100%",
            position: "absolute",
            height: "100%",
            margin: "-3px",
            borderRadius: "6px",
            opacity: 0.1,
            userSelect: "none"}}/>}
          <Grid item>
            <Typography
              variant={'h6'}>{component.title ? truncateString(component.title[initialLanguage], 30) : 'Title is missing'}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={'caption'}
              className={classes.description}>{component.description ? truncateString(component.description[initialLanguage], 50) : 'Description is missing...'}</Typography>
          </Grid>
        </Grid>
        <Box style={{position: 'absolute', bottom: 2, right: 15, zIndex: 2}} width={'100%'}>
          <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <IconButton onClick={() => setOpenDeleteDialog(component)} size={'small'}>
                <DeleteOutlined/>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleEditComponent(component)} size={'small'}>
                <EditOutlined/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box style={{position: 'absolute', bottom: 5, right: 5, zIndex: 1}}>
          <i className={getComponentIcon(component.type)}
             style={{fontSize: 50, textAlign: 'center', color: 'rgba(196,196,196,0.55)'}}></i>
        </Box>
      </Grid>
    })}
  </Grid>
  {openDeleteDialog && <SweetAlert
    title={"Remove confirmation"}
    warning
    onConfirm={()=> {
      handleDelete(openDeleteDialog)
      setOpenDeleteDialog(null)
    }}
    onCancel={() => {setOpenDeleteDialog(null)}}>
    Are you sure you wish to delete this component?
  </SweetAlert>}
    {deleteFromMenu && <SweetAlert
      title={"Warning"}
      warning
      onConfirm={()=> {
        handleDeleteComponentAndRemoveFromBottomMenu()
      }}
      onCancel={() => {setDeleteFromMenu(null)}}>
      This component is linked on Bottom Menu and it will be deleted
    </SweetAlert>}
  </>
}

export default ComponentsList