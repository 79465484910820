import React, {useEffect, useState} from 'react'
import BaseDialog from "../../../../components/Common/BaseDialog";
import {Box, Grid, Typography} from "@material-ui/core";
import {AvailableGuideComponents, GuideComponents} from "../../../../constants/enums";
import {truncateString} from "../../../../utils";
import moment from "moment";

const ComponentCard = (props) => {
  const {guideComponent, setSelectedComponent, selected} = props
  const {title, icon, description} = guideComponent

  return <Grid item xs={3} style={{
    borderRadius: 4,
    marginBottom: 10,
    border: `1px solid ${selected ? '#3774c4' : '#ececec'}`,
    height: 200,
    cursor: 'pointer',
    userSelect: 'none'
  }} onClick={() => setSelectedComponent(guideComponent)}>
    <Grid container direction={'column'} alignItems={'center'} alignContent={'center'} justify={'center'} spacing={2}>
      <Grid item>
        <i className={icon} style={{fontSize: 50, textAlign: 'center'}}></i>
      </Grid>
      <Grid item>
        <Typography variant={'body1'} align={'center'}>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant={'caption'} align={'center'} style={{color: '#a8a8a8'}}>{description}</Typography>
      </Grid>
    </Grid>
  </Grid>
}

const GuideComponentsDialog = (props) => {
  const {open, handleClose, components, handleAddComponent, bottomMenu=true} = props
  const [selectedComponent, setSelectedComponent] = useState({})

  const renderStepOne = () => {
    return <Grid container spacing={2} style={{padding: 20}}>
      {AvailableGuideComponents.filter((component) => {
        return component.type !== GuideComponents.keypad && component.type !== GuideComponents.settings
      }).map((component) => {
        return <ComponentCard guideComponent={component} selected={selectedComponent?.type === component.type}
                              setSelectedComponent={setSelectedComponent}/>
      })
      }
    </Grid>
  }

  const contents =  [renderStepOne()]

  useEffect(() => {
    setSelectedComponent({})
  }, [open])

  return <BaseDialog
    open={open}
    handleClose={handleClose}
    title={'Repox Guide Components'}
    size={'lg'}
    primaryActionTitle={'Add Component'}
    primaryAction={() => {
      handleAddComponent(selectedComponent)
    }}
    primaryActionDisabled={!selectedComponent?.title}
  >
    {contents}
  </BaseDialog>

}

export default GuideComponentsDialog