import React from 'react'
import {Table} from 'reactstrap'
import {truncateString} from '../../../utils'
import moment from 'moment'

const GuidesTableList = (props) => {

  const {result, columns} = props

  return <div className={'table-responsive'}>
    <Table className="project-list-table table-nowrap table-centered table-borderless">
      <thead>
      <tr>
        {columns.map((col) => (
          <th scope="col">{col}</th>
        ))}
      </tr>
      </thead>
      <tbody>
      {result.map((story) => (
        <tr key={story.id} onClick={()=>props.history.push(`/stories/edit/${story.uuid}`)} style={{cursor:'pointer'}}>
          <td style={{width: '100px'}}>
            <h5 className="text-truncate font-size-14">
              {story.title}
            </h5>
          </td>
          <td style={{width: '100px'}}>
            <h5 className="text-truncate font-size-14">
              {truncateString(story.description, 10)}
            </h5>
          </td>
          <td style={{width: '100px'}}>
            {story?.tags?.length > 0 &&
              story.tags.map((tag) => {
                return <h5 key={tag.id} className='d-inline-block'><span
                  className="badge badge-soft-secondary mr-1">{truncateString(tag.name, 10)}</span></h5>
              })
            }
          </td>
          <td style={{width: '100px'}}>
            <h5 className='mr-1 d-inline-block'><span className="badge badge-success">{story?.aspectRatio}</span></h5>
          </td>
          <td style={{width: '100px'}}>
            <h5 className='d-inline-block'><span className="badge badge-info">{story?.orientation}</span></h5>
          </td>
          <td style={{width: '100px'}}>
            {moment(story?.updatedAt).format('DD/MM/YYYY HH:mm')}
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  </div>


}

export default GuidesTableList