import React, {createRef, Fragment, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useAcceptedTypes, useForm} from "../../helpers/hooks";
import axios from "axios";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import {handleError} from "../../store/core/api";
import {getAxiosDefaultConfig, makeid} from "../../utils";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditDialog from "../../components/Common/EditDialog";
import {Schema} from "../../store/core/schema";
import {ViewLayout} from "../../constants/enums";
import {useContainerDialogLayout} from "./hooks";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import toastr from "toastr";
import {MandatoryContext} from "./MandatoryContext";

const EditTitle = (props) => {
  const {label, handleShortUpdate} = props;
  const inputRef = createRef();
  const [edit, setEdit] = useState(false);
  const [labelValue, setLabelValue] = useState(label);

  const handleEdit = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    if (edit) inputRef.current.focus();
  }, [edit, inputRef]);
  return (
    <Row className={"mb-1"}>
      <Col lg={"auto"}>
        {!edit && <div>{`Edit ${label}`}</div>}
        {edit && <Input ref={inputRef} type={"text"} value={labelValue} onChange={(e) => setLabelValue(e.target.value)} />}
      </Col>
      {!edit && (
        <Col lg={2}>
          <i onClick={handleEdit} className={"bx bx-edit"} />
        </Col>
      )}
      {edit && (
        <>
          <Col lg={1}>
            <i
              onClick={() => {
                setEdit(!edit);
                handleShortUpdate(labelValue,props.id);
              }}
              className="bx bx-save"
            />
          </Col>
          <Col lg={1}>
            <i onClick={() => setEdit(!edit)} className="bx bx-window-close" />
          </Col>
        </>
      )}
    </Row>
  );
};

export const EntityDialog = (props) => {
  const {entity, parentUuid, showDeleteModal, setShowDeleteModal, rootContext, propertyGroups, open, isRoot, thisContainer, setEntity, toolbarAction, handlePageClick} = props;

  const [properties, setProperties] = useState([]);
  const qualifiers = rootContext.Qualifier.getAll.result;
  const [openShortForm, setOpenShortForm] = useState(false);
  const [form, handleChange, updateInitial, handleChangeArray] = useForm(Schema[EntityType.Container]);
  const parentContainer = rootContext.Container.get.result;
  const acceptedTypes = useAcceptedTypes(parentContainer, rootContext, props.open, isRoot);
  //eslint-disable-next-line
  const { mandatoryNotice, setMandatoryNotice } = useContext(MandatoryContext)

  const [filteredProperties, setFilteredProperties] = useState({
    mandatory: [],
    optional: [],
  });
  const [joinProps, setJoinProps] = useState({property: [], id: null});
  const [repeatable, createRepeatable] = useState("");
  const [repeatableDeleted, deleteRepeatable] = useState("");

  const {steps, contents, basicLayout} = useContainerDialogLayout({
    form,
    handleChange,
    handleChangeArray,
    createRepeatable,
    deleteRepeatable,
    filteredProperties,
    joinProps,
    qualifiers,
    open,
    acceptedTypes,
    propertyGroups,
    isRoot,
  });

  useEffect(() => {
    if (form.id === undefined && !toolbarAction && thisContainer) {
      setEntity(thisContainer);
    } else if (form.id !== undefined && toolbarAction) {
      setEntity({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.open) {
      handleChange("label")("");
    }

    return () => {
      handleChange("type")("");
    };
    //eslint-disable-next-line
  }, [props.open]);

  useEffect(() => {
    const formProperties = [];
    if (entity.properties && entity.properties.length > 0) {
      properties.forEach((property) => {
        const indexes = entity.properties.reduce((r, n, i) => {
          n.key === property.propertyKey && r.push(i);
          return r;
        }, []);
        indexes.forEach((index) => {
          const propertiesEntity = entity.properties[index];
          const id = propertiesEntity.id ? propertiesEntity.id : makeid();
          formProperties.push({...property, id});
        });
      });

      let newAddedFields = [];
      properties.forEach((property) => {
        if (formProperties.findIndex((propertyItem) => propertyItem.propertyKey === property.propertyKey) === -1)
          // found
          newAddedFields.push(property);
      });
      newAddedFields.forEach((newField) => {
        const id = makeid();
        formProperties.push({...newField, id});
      });
    } else {
      properties.forEach((property) => {
        const match = entity.properties && entity.properties.find((x) => x.key === property.propertyKey);
        const id = match ? match.id : makeid();
        formProperties.push({...property, id});
      });
    }
    setJoinProps(formProperties);
  }, [entity.properties, properties]);

  const shouldSplit = useMemo(() => {
    const containerType = props.containerTypes.find((x) => x.id === form.type);
    return containerType && containerType.formType === ViewLayout.record;
  }, [form.type, props.containerTypes]);

  useEffect(() => {
    if (joinProps.length > 0) {
      setMandatoryNotice(false);
      const mandatory = shouldSplit ? joinProps.filter((x) => x.mandatory) : joinProps;

      const optional = shouldSplit ? joinProps.filter((x) => !x.mandatory) : [];
      setFilteredProperties({mandatory, optional});
    } else if (joinProps.length === 0) {
      //in case a container has no properties
      setFilteredProperties({mandatory: [], optional: []});
    }
    //eslint-disable-next-line
  }, [shouldSplit, joinProps, updateInitial]);

  useEffect(() => {
    const state = {
      id: entity.id || entity.uuid,
      label: entity.label,
      type: entity.type && entity.type.code,
    };
    entity.properties &&
      entity.properties.forEach((prop) => {
        state[prop.id] = prop.value;
      });
    updateInitial(state);
  }, [entity, updateInitial]);

  const fetchProperties = useCallback(async (containerType) => {
    const url = ApiEndpoint[EntityType.ContainerType] + `/${containerType}`;
    try {
      const containerType = await axios
        .get(url, getAxiosDefaultConfig())
        .then((res) => res.data)
        .catch(handleError);
      setProperties(containerType.properties);  
    } catch (e) {
      setProperties([]);
    }
  }, []);

  useEffect(() => {
    if (form.id && form.type && props.open) {
      fetchProperties(form.type.code || form.type);
    } else {
      //setProperties([]);
    }
  }, [fetchProperties, form.id, form.type, props.open]);

  const getQueryStringParams = () => {
    if (!parentUuid) return "";
    return `?to=${parentUuid}`;
  };

  function* afterSave(result) {
    yield updateInitial({...result, id: result.uuid});
  }

  const handleCreate = () => {
    const payload = {
      __callback__: afterSave,
      queryStringParams: getQueryStringParams(),
      label: form.label,
      type: form.type,
      properties: joinProps.map((prop) => {
        return {
          key: prop.propertyKey,
          value: form[prop.id] || "",
        };
      }),
    };
    props.createEntity(payload);
  };

  const handleUpdate = () => {
    let mandatoryFields = [];
    joinProps.forEach((prop) =>
    {
      if (prop.mandatory) {
        const formPropValue = form[prop.id] ? JSON.parse(form[prop.id]).values : [];
        if (formPropValue?.length === 0) mandatoryFields.push(formPropValue);
      }
    })
    if (mandatoryFields.length === 0) {
      setMandatoryNotice(false);
      const payload = {
        id: form.id,
        label: form.label,
        type: form.type,
        properties: joinProps.map((prop) => {
          return {
            key: prop.propertyKey,
            value: form[prop.id] || "",
            id: typeof prop.id === "string" ? null : prop.id,
          };
        }),
      };
      props.updateEntity(payload);

      setTimeout(() => {
        handlePageClick()
      }, 500);
    } else {
      setMandatoryNotice(true);
      toastr.error("Please fill all the required fields");
    }
  };

  const handleShortUpdate = (label,id) => {
    const payload = {
      ...entity,
      label: label,
      id: id
    };
    props.updateEntity(payload);
  };

  const isValid = form.label && form.type;

  useEffect(() => {
    const foundKey = properties.filter((x) => x.propertyKey === repeatable.key);
    if (repeatable.id && shouldSplit) {
      if (repeatable.mandatory) {
        const payloadRow = foundKey[0];
        const mandatoryStates = [...filteredProperties.mandatory];
        let filteredRow = foundKey[0];
        mandatoryStates.push({...filteredRow, id: repeatable.id});
        // mandatoryStates.sort((a, b) => a.position - b.position)
        mandatoryStates.sort(function (a, b) {
          const aGroup = a.groupId;
          const bGroup = b.groupId;
          const aPosition = a.position;
          const bPosition = b.position;

          if (aGroup === bGroup) {
            return aPosition < bPosition ? -1 : aPosition > bPosition ? 1 : 0;
          } else {
            return aGroup < bGroup ? -1 : 1;
          }
        });
        const joinPropsState = joinProps;
        joinPropsState.push({...payloadRow, id: repeatable.id});
        setJoinProps(joinPropsState);
        setFilteredProperties({
          mandatory: mandatoryStates,
          optional: [...filteredProperties.optional],
        });
      } else if (!repeatable.mandatory) {
        const payloadRow = foundKey[0];
        const filteredRow = foundKey[0];
        const optionalStates = [...filteredProperties.optional];
        optionalStates.push({...filteredRow, id: repeatable.id});
        // optionalStates.sort((a, b) => a.position - b.position)
        optionalStates.sort(function (a, b) {
          const aGroup = a.groupId;
          const bGroup = b.groupId;
          const aPosition = a.position;
          const bPosition = b.position;

          if (aGroup === bGroup) {
            return aPosition < bPosition ? -1 : aPosition > bPosition ? 1 : 0;
          } else {
            return aGroup < bGroup ? -1 : 1;
          }
        });
        const joinPropsState = joinProps;
        joinPropsState.push({...payloadRow, id: repeatable.id});
        setJoinProps(joinPropsState);
        setFilteredProperties({
          mandatory: [...filteredProperties.mandatory],
          optional: optionalStates,
        });
      }
    } else if (repeatable.id && !shouldSplit) {
      const payloadRow = foundKey[0];
      const mandatoryStates = [...filteredProperties.mandatory];
      let filteredRow = foundKey[0];
      mandatoryStates.push({...filteredRow, id: repeatable.id});
      mandatoryStates.sort(function (a, b) {
        const aGroup = a.groupId;
        const bGroup = b.groupId;
        const aPosition = a.position;
        const bPosition = b.position;

        if (aGroup === bGroup) {
          return aPosition < bPosition ? -1 : aPosition > bPosition ? 1 : 0;
        } else {
          return aGroup < bGroup ? -1 : 1;
        }
      });
      // mandatoryStates.sort((a, b) => a.position - b.position)
      const joinPropsState = joinProps;
      joinPropsState.push({...payloadRow, id: repeatable.id});
      setJoinProps(joinPropsState);
      setFilteredProperties({
        mandatory: mandatoryStates,
        optional: [...filteredProperties.optional],
      });
    }

    // eslint-disable-next-line
  }, [repeatable.id]);

  useEffect(() => {
    if (repeatableDeleted.id && shouldSplit) {
      if (repeatableDeleted.mandatory) {
        const mandatoryStates = [...filteredProperties.mandatory];
        const newMandatoryState = mandatoryStates.filter((prop) => prop.id !== repeatableDeleted.id);
        setFilteredProperties({
          mandatory: newMandatoryState,
          optional: [...filteredProperties.optional],
        });
        const joinPropsStates = [...joinProps];
        const newJoinPropsStates = joinPropsStates.filter((prop) => prop.id !== repeatableDeleted.id);
        setJoinProps(newJoinPropsStates);
      } else if (!repeatableDeleted.mandatory) {
        const optionalStates = [...filteredProperties.optional];
        const newOptionalState = optionalStates.filter((prop) => prop.id !== repeatableDeleted.id);
        setFilteredProperties({
          mandatory: [...filteredProperties.mandatory],
          optional: newOptionalState,
        });
        const joinPropsStates = [...joinProps];
        const newJoinPropsStates = joinPropsStates.filter((prop) => prop.id !== repeatableDeleted.id);
        setJoinProps(newJoinPropsStates);
      }
    } else if (repeatableDeleted.id && !shouldSplit) {
      const mandatoryStates = [...filteredProperties.mandatory];
      const newMandatoryState = mandatoryStates.filter((prop) => prop.id !== repeatableDeleted.id);
      setFilteredProperties({
        mandatory: newMandatoryState,
        optional: [...filteredProperties.optional],
      });

      const joinPropsStates = [...joinProps];
      const newJoinPropsStates = joinPropsStates.filter((prop) => prop.id !== repeatableDeleted.id);

      setJoinProps(newJoinPropsStates);
    }
    // eslint-disable-next-line
  }, [repeatableDeleted.id]);

  const subtitle = form.id && (form.type.name || form.type);

  return (
    <Fragment>
      <EditDialog
        {...props}
        // scrollable={true}
        subtitle={subtitle}
        titleOverride={form.id ? <EditTitle handleShortUpdate={handleShortUpdate} label={form.label} id={form.id}/> : "Create container"}
        handleEditTitle={() => setOpenShortForm(true)}
        primaryActionTitle={form.id ? "Save" : "Create"}
        createEntity={handleCreate}
        updateEntity={handleUpdate}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        form={form}
        size={"lg"}
        steps={steps}
        contents={contents}
        extraClassName={"modal-full-height"}
        keepOpen={!form.id || openShortForm}
        isValid={isValid}
      >
        {basicLayout}
      </EditDialog>
      <EditDialog {...props} form={form} open={openShortForm} handleClose={() => setOpenShortForm(false)} updateEntity={handleShortUpdate} deleteEntity={null} size={"sm"} isValid={!!form.label}>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>Label</Label>
              <Input type="text" className="form-control" placeholder="Enter label" value={form.label} onChange={handleChange("label")} />
            </FormGroup>
          </Col>
        </Row>
      </EditDialog>
    </Fragment>
  );
};
