import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {useToggle} from "../../helpers/hooks";
import {bytesToSize, downloadThumb, getFullName, getIconForDatastream} from '../../utils';
import {Col, Tooltip} from "reactstrap";
import moment from 'moment'
const DataStreamThumb = ({file, thumbnail}) => {

  const [imageThumb, setImageThumb] = useState('')
  const [openTooltip, handleToggle] = useToggle();
  const [isLoading,setIsLoading] = useState(true)

  useEffect(() => {
    if (file.thumbnail.length > 0)
      downloadThumb(file, thumbnail, setImageThumb,isLoading,setIsLoading)
    // eslint-disable-next-line
  }, [file.uuid])

  if (isLoading) return <Col xs={2} className="p-1 mt-1">
    <i className={`bx ${getIconForDatastream(file.type.name)} avatar-title`} style={{width: '45px', height: '45px', borderRadius: '10px',backgroundColor:'#bec0d0',color:'#606371'}}/>
  </Col>

  return <>
    {imageThumb == null ? <Col xs={2} className="p-1 mt-3">
        <i className={`bx ${getIconForDatastream(file.type.name)} avatar-title`}/>
      </Col> :
      <Col xs={2} className="p-1 mt-1">
        <Tooltip
          placement="right"
          target={`fileId-${file.uuid}`}
          toggle={handleToggle}
          isOpen={openTooltip}
          style={{maxWidth:'400px'}}
        >
          <img src={imageThumb} alt={`${thumbnail} thumb`} style={{maxWidth:'300px'}}/>
        </Tooltip>        
        <div style={{width: '40px', height: '40px', borderRadius: '20px'}}>
          <img id={`fileId-${file.uuid}`} src={imageThumb} alt={`${thumbnail} thumb`} className="avatar-title"
               style={{width: '45px', height: '45px', borderRadius: '10px'}}/>
        </div>
      </Col>
    }
  </>
}
const StorageListRow = ({ file, index }) => {
  return (
    <tr>
      <td>
        <Link
          to={`/containers/${file.membership[0]?.uuid}/?datastream=${file.uuid}`}
        >
        <DataStreamThumb key={file.uuid} file={file} thumbnail={file.thumbnail}/>
        </Link>
      </td>
      <td>
        <h5 className="text-truncate font-size-14">
          <Link
            to={`/containers/${file.membership[0]?.uuid}/?datastream=${file.uuid}`}
            className={`text-dark ${!file.filename && "font-italic"}`}
          >
            {file.filename || "No name"}
          </Link>
        </h5>
        <p className="text-muted mb-0">{file.type.name}</p>
      </td>
      <td>
        <span className="badge badge-soft-primary font-size-12">
          {bytesToSize(file.filesize)}
        </span>
      </td>
      <td>{moment(file.createdAt).format('Do MMMM YYYY')}</td>
      <td>{getFullName(file.createdBy)}</td>
    </tr>
  );
};

export default StorageListRow;
