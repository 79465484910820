import {Box, Grid, Tab, Tabs} from "@material-ui/core";
import EditItemComponent from "./EditItemComponent";
import ContactInformation from "./ContactInformation";
import SocialMediaInformation from "./SocialMediaInformation";
import {Button, Label} from "reactstrap";
import React, {useState} from "react";
import {TabPanel} from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import CoverImage from "./CoverImage";
import ImageGallery from "./ImageGallery";
import BeforeAfterImage from "./BeforeAfterImage";
import GuideLanguageSelection from "../GuideLanguageSelection";
import MobileMockup from "../MobileMockup";
import ListItemMobileView from "../MobileView/LIstItemMobileView";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ItemEditPage = ({
                        classes,
                        itemValue,
                        languages,
                        handleChange,
                        handleChangeMultilingual,
                        handleClose,
                        handleSave
                      }) => {
  const [value, setValue] = useState(0);
  const initialLanguage = languages ? languages[0] : ''
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage)

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };
  return <div className={classes.editItemContainer}>
    <Paper square elevation={0}>
      <Grid container spacing={2}>
        <Grid item>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeValue}
        aria-label="disabled tabs example"
      >
        <Tab label="Basic Information" style={{textTransform:'initial'}}/>
        <Tab label="Images" style={{textTransform:'initial'}}/>
        <Tab label="Contact Information" style={{textTransform:'initial'}}/>
        <Tab label="Files & Videos" style={{textTransform:'initial'}}/>
      </Tabs>
        </Grid>
        <Grid item>
      <GuideLanguageSelection languages={languages} selectedLanguage={selectedLanguage}
                              setSelectedLanguage={setSelectedLanguage}/>
        </Grid>
      </Grid>
    </Paper>

    <Grid container>
      {value === 0 && <Grid item xs={8}>
        <EditItemComponent itemValue={itemValue} languages={languages} selectedLanguage={selectedLanguage} handleChange={handleChange}
                           handleChangeMultilingual={handleChangeMultilingual}/>
      </Grid>}
      {value === 1 && <Grid item xs={8} style={{marginTop:15}}>
        <Grid container>
          <Grid item xs={2}>
            <Label>Cover Image</Label>
            <CoverImage coverImage={itemValue?.coverImage} handleChange={handleChange}/>
          </Grid>
          <Grid item xs={6}>
            <Label>Image Gallery</Label>
            <ImageGallery images={itemValue?.images} setImages={(imagesArray) => handleChange('images', imagesArray)}/>
          </Grid>
          <Grid item xs={4}>
            <Label>Before After</Label>
            <BeforeAfterImage before={itemValue?.beforeAfterWidget?.before} after={itemValue?.beforeAfterWidget?.after}
                              setImages={(changes) => handleChange('beforeAfterWidget', changes)}/>
          </Grid>
        </Grid>
      </Grid>
      }
      {value === 2 && (
        <Grid item xs={8}>
          <Grid container>
          <Grid item xs={8}>
            <ContactInformation itemValue={itemValue} handleChange={handleChange}/>
          </Grid>
          <Grid item xs={8}>
            <SocialMediaInformation itemValue={itemValue} handleChange={handleChange}/>
          </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item style={{marginBottom:35}}>
        <MobileMockup>
          <div style={{padding: 10, height: 520}}>
            <div style={{width:'260px',height:460,overflow:'auto'}}>
              <ListItemMobileView item={itemValue} selectedLanguage={selectedLanguage}/>
            </div>
          </div>
        </MobileMockup>
      </Grid>
    </Grid>

    <Box style={{position: "absolute", bottom: 10, right: 10}}>
      <Grid container spacing={2}>
        <Grid item>
          <Button onClick={handleClose}>Close</Button>
        </Grid>
        <Grid item>
          <Button disabled={itemValue.code === ""} onClick={handleSave} color={'primary'}>Save</Button>
        </Grid>
      </Grid>
    </Box>
  </div>
}

export default ItemEditPage