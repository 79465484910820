import {Divider, Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import {Email, LocationOnOutlined, Phone, PhoneAndroid, Public} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {Label} from "reactstrap";
import GetSpatialInfoMap from "./GetSpatialInfoMap";

const ContactInformation = ({itemValue, handleChange}) => {

  const [openMap,setOpenMap] = useState(false)

  const handleChangeSpatialInfo = (spatialInfo) =>{
    handleChange('longitude', spatialInfo.long, 'spatialInfo')
    handleChange('latitude', spatialInfo.lat, 'spatialInfo')
    handleChange('staticImage',`https://maps.googleapis.com/maps/api/staticmap?center=${spatialInfo.lat},${spatialInfo.long}&zoom=20&size=800x400&key=AIzaSyDcahpw6UyfMizUlzx7lgv8oEB--FQ38IY`,'spatialInfo')
  }

  const lat =itemValue?.spatialInfo?.latitude
  const long = itemValue?.spatialInfo?.longitude

  useEffect(()=>{
    if (lat?.length >0 && long?.length >0)
      handleChange('staticImage',`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${long}&zoom=20&size=800x400&key=AIzaSyDcahpw6UyfMizUlzx7lgv8oEB--FQ38IY`,'spatialInfo')
  },[handleChange, lat, long])

  return <Grid item style={{marginTop: 15, marginBottom: 5}}>
    <Grid container direction={'column'}>
      <Typography variant={'h6'}>Spatial Info</Typography>
      <Grid item>
        <Grid container spacing={2} style={{marginTop: 10}} alignItems={'flex-end'}>
          <Grid item>
            <Label>Latitude</Label>
            <TextField variant={'outlined'}
                       fullWidth
                       size={'small'}
                       placeholder={'Latitude'}
                       value={itemValue?.spatialInfo?.latitude}
                       onChange={(e) => {
                         handleChange('latitude', e.target.value, 'spatialInfo')
                       }}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <LocationOnOutlined/>
                           </InputAdornment>
                         ),
                       }}
            />
          </Grid>
          <Grid item>
            <Label>Longitude</Label>
            <TextField variant={'outlined'}
                       fullWidth
                       size={'small'}
                       placeholder={'Longitude'}
                       value={itemValue?.spatialInfo?.longitude}
                       onChange={(e) => {
                         handleChange('longitude', e.target.value, 'spatialInfo')
                       }}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <LocationOnOutlined/>
                           </InputAdornment>
                         ),
                       }}
            />
          </Grid>
          <Grid item>
            <Typography variant={'caption'} color={'primary'} style={{userSelect:'none',fontWeight:'bold',cursor:'pointer'}} onClick={()=>setOpenMap(true)}>Find spatial info</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{marginTop:40}}>
        <Divider/>
      </Grid>
      <Grid item>
        <Typography variant={'h6'}>Contact Information</Typography>
        <Grid container spacing={2} style={{marginTop: 10}}>
          <Grid item xs={6}>
            <Label>Telephone</Label>
            <TextField variant={'outlined'}
                       size={'small'}
                       fullWidth
                       value={itemValue?.contactInfo?.telephone}
                       onChange={(e) => {
                         handleChange('telephone', e.target.value, 'contactInfo')
                       }}
                       placeholder={'Phone number'}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <Phone/>
                           </InputAdornment>
                         ),
                       }}
            />
          </Grid>
          <Grid item xs={6}>
            <Label>Mobile</Label>
            <TextField variant={'outlined'}
                       fullWidth
                       size={'small'}
                       placeholder={'Mobile phone number'}
                       value={itemValue?.contactInfo?.mobile}
                       onChange={(e) => {
                         handleChange('mobile', e.target.value, 'contactInfo')
                       }}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <PhoneAndroid/>
                           </InputAdornment>
                         ),
                       }}
            />
          </Grid>
          <Grid item xs={6}>
            <Label>Email</Label>
            <TextField variant={'outlined'}
                       fullWidth
                       size={'small'}
                       placeholder={'Type an email example@example.com'}
                       value={itemValue?.contactInfo?.email}
                       onChange={(e) => {
                         handleChange('email', e.target.value, 'contactInfo')
                       }}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <Email/>
                           </InputAdornment>
                         ),
                       }}
            />
          </Grid>
          <Grid item xs={6}>
            <Label>Website</Label>
            <TextField variant={'outlined'}
                       fullWidth
                       size={'small'}
                       placeholder={'Type a URL ex. www.item.com'}
                       value={itemValue?.contactInfo?.siteUrl}
                       onChange={(e) => {
                         handleChange('siteUrl', e.target.value, 'contactInfo')
                       }}
                       InputProps={{
                         startAdornment: (
                           <InputAdornment position="start">
                             <Public/>
                           </InputAdornment>
                         ),
                       }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <GetSpatialInfoMap open={openMap} lat={itemValue?.spatialInfo?.latitude} long={itemValue?.spatialInfo?.longitude} handleClose={()=>setOpenMap(false)} handleChange={handleChangeSpatialInfo}/>
  </Grid>
}

export default ContactInformation