import React, {Component, useEffect} from 'react';
import {Col, Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
//i18n
import {withNamespaces} from 'react-i18next';
// users
import {getUsername} from "../../../utils";
import BaseDialog from "../../Common/BaseDialog";
import {connect} from 'react-redux'
import {useForm} from '../../../helpers/hooks'
import {Schema} from '../../../store/core/schema'
import {updateMyProfile,getMyProfile} from '../../../store/myProfile/actions'

const MyProfile = (props)=>{
    const {context,handleClose,open,updateMyProfile,refresh} = props
    const profile = context.MyProfile.getAll.result
    const profileUpdated = context.MyProfile.update
    const [form, handleChange, updateInitial] = useForm(Schema['MyProfile']);

    useEffect(()=>{
        if (open)
            updateInitial(profile)
        //eslint-disable-next-line
    },[open])

    useEffect(()=>{
        if (profileUpdated.successResult && open)
        {
            refresh()
            handleClose()
        }
        //eslint-disable-next-line
    },[profileUpdated.successResult])

    const handleUpdate = () =>{
        updateMyProfile(form)
    }

    return <BaseDialog
        open={open}
        handleClose={handleClose}
        title={'My Profile'}
        primaryAction={handleUpdate}
        primaryActionTitle={'Save'}
    >
        <Row>
            <Col xs={12}>
                <FormGroup>
                    <Label>Firstname</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Firstname"
                      value={form.firstname}
                      onChange={handleChange("firstname")}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Label>Lastname</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Lastname"
                      value={form.lastname}
                      onChange={handleChange("lastname")}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Label>Username</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={form.username}
                      disabled
                      style={{
                          backgroundColor: '#f1f1f1',
                          color: '#b3b3b3',
                          cursor: 'not-allowed',
                      }}
                    />
                </FormGroup>
            </Col>
            <Col xs={12}>
                <FormGroup>
                    <Label>e-mail</Label>
                    <Input
                      type="text"
                      className="form-control"
                      placeholder="E-mail"
                      value={form.email}
                      disabled
                      style={{
                          backgroundColor: '#f1f1f1',
                          color: '#b3b3b3',
                          cursor: 'not-allowed',
                      }}
                    />
                </FormGroup>
            </Col>
        </Row>
    </BaseDialog>
}
class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            username : getUsername(),
            userModal:false
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    openUserModal(){
        this.setState(prevState => ({
            userModal: !prevState.userModal,
        }));
    }


    render() {

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        {/*<img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />*/}
                        <div className="avatar-xs">
                            <span
                                className={
                                    "avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
                                }
                            >
                                {getUsername().charAt(0).toUpperCase()}
                            </span>
                        </div>
                        {/*<span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.username}</span>*/}
                        {/*<i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>*/}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <div className="dropdown-item nonDraggableElement mb-1 " style={{cursor:'pointer'}} onClick={()=> {
                            this.openUserModal()
                            this.toggle()
                        }}>
                            <i className="bx bx-user-circle font-size-18 align-top mr-1"></i>{this.props.t('My account')}
                        </div>
                        <a href="https://downloads.repox.io/repox-public/docs/RepoxStore-v1-UserManual.pdf" className="dropdown-item mb-1 " rel="noopener noreferrer" target="_blank">
                            <i className='bx bx-info-circle  font-size-18 align-top mr-1'></i> 
                            <span>{this.props.t('Documentation')}</span>
                        </a>     
                        <div className="dropdown-divider"></div>
                        <Link to="/logout" className="dropdown-item mb-1">
                            <i className="bx bx-power-off font-size-18 align-top mr-1 text-danger"></i>
                            <span>{this.props.t('Logout')}</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>
                <MyProfile open={this.state.userModal} handleClose={()=>this.openUserModal()} context={this.props.context} updateMyProfile={this.props.updateMyProfile} refresh={this.props.getMyProfile}/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        context: state,
    };
};

export default connect(mapStateToProps, {updateMyProfile,getMyProfile})(withNamespaces()(ProfileMenu));



