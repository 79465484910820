import React, {useCallback, useMemo, useState} from "react";
import BaseContent from "../../../../components/Common/BaseContent";
import {connect} from "react-redux";
import {Schema} from "../../../../store/core/schema";
import {EntityType} from "../../../../store/core/entityType";
import EntityDialog from "./EntityDialog";
import PropertyGroupDialog from "../../ContentModel/PropertyGroups/EntityDialog";
import {
  createContainerType,
  deleteContainerType,
  getContainerType,
  updateContainerType,
} from "../../../../store/containerType/actions";
import CardSettings from "../../../../components/Common/card-settings";
import {createProperty, deleteProperty, updateProperty,} from "../../../../store/property/actions";
import {createPropertyGroup, deletePropertyGroup, updatePropertyGroup} from "../../../../store/propertyGroup/actions";
import {useListToggle} from "../../../../helpers/hooks";
import ListSettings from "../../../../components/Common/ListSettings";
import ListToggle from "../../../../components/Common/ListToggle";

const entityType = EntityType.ContainerType;

const breadcrumbs = [
  { title: "Settings", link: "/settings" },
  { title: "Content Model", link: "/settings/content-model" },
  { title: "Container Types", link: "#" },
];

const ContainerTypes = (props) => {
  const {
    context,
    updateContainerType,
    createContainerType,
    deleteContainerType,
    getContainerType,
    createPropertyGroup,
    updatePropertyGroup,
    deletePropertyGroup
  } = props;
  const { result, isLoading, error } = context.ContainerType.getAll;
  const dataStreamTypes = context.DataStreamType.getAll.result;
  const currentContainerType = context.ContainerType.get.result;
  const propertyGroups = context.PropertyGroup.getAll.result;
  const properties = context.Property.getAll.result;
  const [openModal, setOpenModal] = useState(false);
  const [openPropertyGroupModal, setOpenPropertyGroupModal] = useState(false);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [groupEntity, setGroupEntity] = useState(Schema[EntityType.PropertyGroup])
  const [isList, toggleList] = useListToggle();

  const handleEntityClick = useCallback(
    (entity) => {
      getContainerType(entity.code);
      setOpenModal(true);
    },
    [getContainerType]
  );

  React.useEffect(() => {
    setEntity(currentContainerType);
  }, [currentContainerType]);



  const handleCreate = () => {
    setEntity(Schema[entityType]);
    setOpenModal(true);
  };

  const handleCreatePropertyGroup = () => {
    setGroupEntity(Schema[EntityType.PropertyGroup])
    setOpenPropertyGroupModal(true);
  };

  const handleEditPropertyGroup = (group) => {
    setGroupEntity(group)
    setOpenPropertyGroupModal(true);
  };

  const handleClose = () =>{
    setOpenModal((prevState) => !prevState);
    setEntity([])
  }

  const cards = useMemo(() => {
    return result.map((containerType) => ({
      label: containerType.name,
      icon: "bx-folder",
      code: containerType.code,
      containerAcceptance: containerType.containerAcceptance,
      datastreamAcceptance: containerType.datastreamAcceptance,
      allowedAtRoot: containerType.allowedAtRoot,
      handleClick: () => handleEntityClick(containerType),
    }));
  }, [handleEntityClick, result]);

  const list = useMemo(() => {
    return result.map((nameSpace) => ({
      label: nameSpace.name,
      code: nameSpace.code,
      containerAcceptance: nameSpace.containerAcceptance,
      // containerAcceptance: nameSpace.name,
      datastreamAcceptance: nameSpace.datastreamAcceptance,
      allowedAtRoot: nameSpace.allowedAtRoot,
      handleClick: () => handleEntityClick(nameSpace),
    }));
  }, [handleEntityClick, result]);

  const listColumns = [
    {name: "Name", prop: "label"},
    {name: "Code", prop: "code"},
    {name: "Accepted Containers", prop: "containerAcceptance"},
    {name: "Accepted Datastreams", prop: "datastreamAcceptance"},
    {name: "Allow creation at root", prop: "allowedAtRoot"},
  ];
  return (
    <>
      <BaseContent
        title={"Container Types"}
        error={error}
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        handleCreate={handleCreate}
        renderActions={() => <ListToggle isList={isList} toggle={toggleList} />}
      >
        {!isList && <CardSettings settings={cards}/>}
        {isList && <ListSettings contents={list} columns={listColumns}/>}
        <EntityDialog
          entity={entity}
          open={openModal}
          entityType={entityType}
          handleClose={handleClose}
          properties={properties}
          createEntity={createContainerType}
          updateEntity={updateContainerType}
          deleteEntity={deleteContainerType}
          context={context.ContainerType}
          dataStreamTypes={dataStreamTypes}
          propertyGroups={propertyGroups}
          handleEditPropertyGroup={handleEditPropertyGroup}
          handleCreatePropertyGroup={handleCreatePropertyGroup}
        />
        <PropertyGroupDialog
            entity={groupEntity}
            entityType={EntityType.PropertyGroup}
            open={openPropertyGroupModal}
            handleClose={() => setOpenPropertyGroupModal(false)}
            createEntity={createPropertyGroup}
            updateEntity={updatePropertyGroup}
            deleteEntity={deletePropertyGroup}
            context={context.PropertyGroup}
        />
      </BaseContent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  updateContainerType,
  createContainerType,
  deleteContainerType,
  getContainerType,
  createProperty,
  updateProperty,
  deleteProperty,
  createPropertyGroup,
  updatePropertyGroup,
  deletePropertyGroup
})(ContainerTypes);
