import React, {useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { starContainer } from '../../store/container/actions';
import { Badge, Card, CardBody, Col, Media, Row} from "reactstrap";
import OverflowTip from "../../components/Common/OverflowTip";
import ActionsContextMenu from '../../pages/Containers/ActionsContextMenu';
import axios from 'axios'
import { API_URL } from '../../config'
import {getAxiosDefaultConfig } from '../../utils';

function RecentCard(props) {
  const { isDataStream } = props;

  const [menuIndex, setMenuIndex] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const getContainer = async (id) => {
    await axios
      .get(`${API_URL}/containers/${id}`, getAxiosDefaultConfig())
      .then((res) => {
        props.setSelectedContainer(res.data);
        props.setEntity(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const handleContextMenu = (e, container, key,id) => {
    e.preventDefault();
    // const cardPosition = document.getElementById(id).getBoundingClientRect().bottom
    getContainer(container.id)
    const windowHeight = window.innerHeight - e.clientY
    if ((windowHeight) < 271)
      setPointerPosition({x: e.clientX, y: e.clientY,overflow:true});
    else
      setPointerPosition({x: e.clientX, y: e.clientY});
    props.handleContextClick(e, container);
    setMenuIndex(key);
  };  
  const selectedContainer = {
    ...menuIndex !== null && props.settings[menuIndex],
    properties: props.settings[menuIndex]?.properties
    //properties: props.currentContainer.properties
  }

  return (
    <Row>
      {props?.settings?.map((setting, key) =>
      {
        const bgColor = setting.color !== null && setting.color !== undefined && setting.color !== "#fff" ? `${ setting.color }8C` : "#fff";
        const selected = props.isSelected(setting.uuid)
        const borderColor = selected ? 'primary' : 'light';
        return (
          <Col xl="4" sm="6" key={"_project_" + key} id={"_project_" + key}>
            {/* <Link to={setting.route? setting.route : "#"}> */}
            <Card
                className={`cursor-pointer border border-${borderColor}`}
                style={{ position: "relative", backgroundColor: bgColor }}
                onContextMenu={(e) => {
                  handleContextMenu(e, setting, key, "_project_" + key);
                }}    
                onClick={(e) => {
                  e.stopPropagation();
                  if (menuIndex !== key) {
                    props.handleContainerSelect(e, setting);
                  }               
                }}                
              onDoubleClick={() => props.handleContainerClick(setting)}
              
              >
                <CardBody>
                  <Media className={"align-items-center"}>
                    {setting.starred && (
                      <div className="position-absolute" style={{right: 0, bottom: 0, margin: 8}}>
                        <i className={`font-weight-bold bx bxs-star font-size-20`} style={{color: "#ffca00"}} />
                      </div>
                    )}
                    <div className="avatar-sm mr-4">
                      {!isDataStream ? (
                        <span className="avatar-title rounded-circle bg-light text-primary font-size-24">
                          <i className={`bx ${setting.icon}`} />
                        </span>
                      ) : (
                        setting.thumbnail
                      )}
                    </div>
                    <Media className="overflow-hidden" body>
                      <h5 className="text-truncate font-size-15 text-dark d-flex align-items-center" id={"label" + key}>
                        <OverflowTip>{setting.label}</OverflowTip>
                        {setting.published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                      </h5>
                      {setting.info && (
                        <Badge color={"primary"} style={{backgroundColor: setting.color}}>
                          {setting.info}11
                        </Badge>
                      )}
                      {setting?.type && (
                        <Badge color={"primary"} style={{fontSize: 12, fontWeight: 400}}>
                          {setting.type}
                        </Badge>
                      )}
                    </Media>
                    {props.hasNext && <i className="font-weight-bold bx bx-chevron-right text-primary font-size-18" />}
                  </Media>
                </CardBody>
                {menuIndex === key && <ActionsContextMenu container={props.selectedContainer} setShowDeleteModal={props.setShowDeleteModal} setIsShown={setMenuIndex} position={pointerPosition} {...props} />}                
              </Card>
            {/* </Link> */}
          </Col>
        );
      })}
    </Row>
  );
}

export default RecentCard;
