import React, {useContext, useMemo} from 'react'
import {Grid, IconButton, Typography} from "@material-ui/core";
import {Delete, DeleteOutlined, Edit, EditOutlined} from "@material-ui/icons";
import moment from "moment";
import {assetThumb, truncateString} from "../../../../utils";
import GuideContext from "../../GuideEditPage/GuideSidebarContext";


const ItemCard = ({data, setItemEdit,languages, handleDeleteItem}) => {
  const {setEditItem} = useContext(GuideContext)
  return <>
    <Grid container alignContent={'flex-start'} alignItems={'center'} style={{
      backgroundImage: data?.coverImage?.thumb && `url(${data?.coverImage?.thumb})`,
      backgroundPosition:'center center',
      backgroundSize: 'cover',
      backgroundColor: '#ffffff',
      padding: 8,
      marginBottom: 4,
      width:300,
      marginRight: 4,
      borderRadius: 5,
      boxShadow: '1px 1px 1px 0px #f2efef',
      height: 140,
      position: 'relative'
    }}>
      <Grid item xs={12} style={{backgroundColor: 'rgba(255,255,255,0.91)', padding: 5, borderRadius: 5}}>
        {data?.title[languages[0]] ? <span style={{fontWeight: 'bolder'}}>{truncateString(data?.title[languages[0]], 30)}</span> :
          <span style={{color: '#939393', fontStyle: 'italic'}}>Empty title</span>}
      </Grid>
      <Grid item style={{position: 'absolute', bottom: 4, width: '95%'}}>
        <Grid container alignItems={'center'}
              style={{backgroundColor: 'rgba(255,255,255,0.53)', padding: 5, borderRadius: 5}}>
          <Grid item>
            <Typography variant={'caption'} style={{color: '#000', textShadow: '#fff6f6 0px 1px 1px'}}>Created
              at {moment(data.createdAt).format('hh:mm DD/MM/YYYY')}</Typography>
          </Grid>
          <Grid item style={{marginLeft: 'auto'}}>
            <Grid container>
              <Grid item>
                <IconButton size={'small'} onClick={() => handleDeleteItem(data.id)}>
                  <DeleteOutlined/>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton size={'small'} onClick={() => {
                  setEditItem({hideSidebar:true, data})
                }}>
                  <EditOutlined/>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
}

export default ItemCard