import React, { useState, useEffect } from "react";
import BaseContent from "../../components/Common/BaseContent";
import { Table } from "reactstrap";
import { usePaging } from "../../helpers/hooks";
import { API_URL } from "../../config";
import MoreButton from "../../components/Common/MoreButton";
import StorageListRow from "./storage-list-row";
import ArrowUpIcon from "@icons/material/ArrowUpIcon";
import ArrowDownIcon from "@icons/material/ArrowDownIcon";

const breadcrumbs = [{ title: "Storage", link: "#" }];

function StorageFiles() {
  const url = API_URL + "/storage/details";
  const [page, setPage] = useState(0);
  const [isLoading, data, content] = usePaging(url, {
    page,
    size: 20,
    identifier: "uuid",
  });

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("filename");

  function descendingComparator(a, b, orderBy) {    
    if (typeof b[orderBy] === "string")
    {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase())
      {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase())
      {
        return 1;
      }
    } else 
    {
      if (b[orderBy] < a[orderBy])
      {
        return -1;
      }
      if (b[orderBy] > a[orderBy])
      {
        return 1;
      }      
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleTableHeadSort = (value) => {
    const isAsc = orderBy === value && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(value);
  };
  
  return (
    <BaseContent isLoading={isLoading && content.length === 0} breadcrumbs={breadcrumbs}>
      <div className="table-responsive">
        <Table className="project-list-table table-nowrap table-centered table-borderless">
          <thead>
            <tr>
              <th scope="col"/>
              <th scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("filename");
                  }}              
              >Name{" "}
                  {orderBy === "filename" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
              </th>
              <th scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("filesize");
                  }}              
              >Size{" "}
                  {orderBy === "filesize" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
              </th>
              <th scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("createdAt");
                  }}              
              >Created at{" "}
                  {orderBy === "createdAt" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
              </th>
              <th scope="col"
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    handleTableHeadSort("createdBy");
                  }}              
              >Creator{" "}
                  {orderBy === "createdBy" ? (
                    order === "asc" ? (
                      <ArrowUpIcon style={{width: "15px", color: "#777"}} />
                    ) : (
                      <ArrowDownIcon style={{width: "15px", color: "#777"}} />
                    )
                  ) : (
                    <ArrowUpIcon style={{width: "15px", color: "#aaa"}} />
                  )}
              </th>
            </tr>
          </thead>
          <tbody>
            {stableSort(content, getComparator(order, orderBy)).map((file, key) => (
              <StorageListRow key={key} index={key} file={file} />
            ))}
          </tbody>
        </Table>
      </div>
      <MoreButton
        isLoading={isLoading}
        handleMore={() => setPage(page + 1)}
        hasMore={!data.last}
      />
    </BaseContent>
  );
}

export default StorageFiles;
