import React, {useCallback, useState} from 'react'

import {Grid} from "@material-ui/core";
import GuideAssetsDialog from "../GuideAssetsDialog";
import Reorder from "react-reorder";

const BeforeAfterImage = ({before, after, setImages}) => {

  const [openAssetsDialog, setOpenAssetsDialog] = useState({open: false, image: 0}) //image 0 => before , image 1 => after

  const handleImageChange = useCallback((asset) => {
    if (openAssetsDialog?.image === 0)
      setImages({before:{thumb:asset.thumbnailUrl,uuid:asset.uuid},after})
    else if (openAssetsDialog?.image === 1)
      setImages({before,after:{thumb:asset.thumbnailUrl,uuid:asset.uuid}})
  }, [after, before, openAssetsDialog.image, setImages])

  return <>
    <Grid container style={{width: '100%'}}>
      <Grid item xs={10}>
        <Grid container style={{height: 100, width: 'max-content'}} direction={'row'}>
          {before?.uuid ?
            <Grid item style={{
              backgroundImage: `url(${before?.thumb})`,
              backgroundSize: 'cover',
              marginRight: '2px',
              border: before?.thumb ? '' : `1px dashed #ececec`,
              borderRadius: 2,
              width: 100,
              height: 100,
              float: 'left',
              cursor: 'pointer'
            }} onClick={() => setOpenAssetsDialog({open: true, image: 0})}/>
            :
            <Grid item
                  style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    border: `1px dashed #ececec`, borderRadius: 2, width: 100, height: 100, cursor: 'pointer'
                  }} onClick={() => setOpenAssetsDialog({open: true, image: 0})}>
              Add image
            </Grid>
          }
          {after?.uuid ?
            <Grid item style={{
              backgroundImage: `url(${after?.thumb})`,
              backgroundSize: 'cover',
              marginRight: '2px',
              border: after?.thumb ? '' : `1px dashed #ececec`,
              borderRadius: 2,
              width: 100,
              height: 100,
              float: 'left',
              cursor: 'pointer'
            }} onClick={() => setOpenAssetsDialog({open: true, image: 1})}/>
            :
            <Grid item
                  style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                    border: `1px dashed #ececec`, borderRadius: 2, width: 100, height: 100, cursor: 'pointer'
                  }} onClick={() => setOpenAssetsDialog({open: true, image: 1})}>
              Add image
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>

      <GuideAssetsDialog open={openAssetsDialog.open}
                         multiple={true}
                         handleChange={handleImageChange}
                         handleClose={() => {
                           setOpenAssetsDialog((prevState) => ({image: prevState.image, open: false}))
                         }}/>

    </>
    }

    export default BeforeAfterImage