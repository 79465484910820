import {IconButton} from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import React from "react";

const GuideLanguageSelection = ({languages,selectedLanguage, setSelectedLanguage}) =>{
  return languages?.map((code)=>{
    return <IconButton key={`selected-lang-${code}`} onClick={()=>setSelectedLanguage(code)} style={{backgroundColor:selectedLanguage === code ? '#cee5f6':''}}>
      <ReactCountryFlag
        countryCode={code}/>
    </IconButton>
    })
}

export default GuideLanguageSelection