import React, {useEffect, useState, useMemo} from "react";
import ReactDOM from "react-dom";
import toastr from "toastr";
import moment from "moment";
import axios from "axios";
import {API_URL} from "../../config";
import {getAxiosDefaultConfig, downloadThumb} from "../../utils";
import {useDataStreams} from "../../helpers/hooks";
import {bytesToSize} from "../../utils";
import properties from "../Settings/ContentModel/Properties/properties";

const DataStreamThumb = ({file, thumbnail}) => {
  const [imageThumb, setImageThumb] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (file && file.thumbnail.length > 0) downloadThumb(file, thumbnail, setImageThumb, isLoading, setIsLoading);
    // eslint-disable-next-line
  }, [file.uuid]);

  if (isLoading) return <></>;

  return (
    <>
      {imageThumb == null ? (
        ""
      ) : (
        <>
          <img style={{width: "250px", minHeight: "100px", height: "auto"}} width="180" height="180" src={imageThumb} alt={`${thumbnail} thumb`} />
          <div>
            <img style={{display: "none", width: "250px", minHeight: "100px", height: "auto"}} width="180" height="180" src={imageThumb} alt={`${thumbnail} thumb`} />
          </div>
        </>
      )}
    </>
  );
};

const PrintPreview = React.forwardRef((props, ref) => {
  const [container, setParentContainer] = useState([]);

  const getContainer = async (props) => {
    await axios
      .get(`${API_URL}/containers/${props.entity?.uuid}`, getAxiosDefaultConfig())
      .then((res) => {
        setParentContainer(res.data);
      })
      .catch((e) => {
        toastr.error(e);
      });
  };

  useEffect(() => {
    if (props.entity) getContainer(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entity]);

  const getPageMargins = () => {
    return `@media only screen {body{font-size:10px;}h1{font-size:16px;margin-top:20px;}h2{font-size:14px;margin-top:20px;}br{display:none}img{width:100px !important;min-height: auto !important;}td{padding: 1px 2px;}}table{table-layout: fixed !important,} td {word-break: break-word;}@page { margin: 50px 25px !important; } body{font-family: Arial;background: #fff;}`;
    };
    

    const propertyValue = (type) => {
      const propertyItem = props.properties.find((item) => item.propertyKey === type.key);
      console.log(propertyItem.propertyType);
      switch (propertyItem.propertyType) {
        case "boolean":
          return type.value ? "true" : "false";
        case "date":
          const newDate = type.value !== "" ? JSON.parse(type.value) : type.value;
          return newDate.values;
        case "enum":
          const newEnum = type.value !== "" ? JSON.parse(type.value) : type.value;
          const enums = newEnum.values ? newEnum.values : ""
          return `${enums}`;
        case "ip-address-v4":
          return `${type.value}`;
        case "json":
          return "";
        case "multi-select":
          const newMultiSelect = type.value ? JSON.parse(type.value) : null;
          const selections = newMultiSelect? newMultiSelect.values.map((item) => item + ", ") : "";
          return `${selections}`;
        case "number":
          return `${type.value}`;
        case "reference":
          const newReference = type.value ? JSON.parse(type.value) : "";
          return `${newReference}`;
        case "rich-text":
          const newRichText = JSON.parse(type.value);
          return `${newRichText.values}`;
        case "spatial-map":
          return `${type.value}`;
        case "text":
          const newText = type.value !== "" ? JSON.parse(type.value) : type.value;
          const newValues = newText.values? newText.values : newText
          return `${newValues}`;
        case "text-area":
          const newTextArea = JSON.parse(type.value);
          return `${newTextArea.values}`;
        case "time":
          const newTime = JSON.parse(type.value);
          return `${newTime.values}`;
        case "url":
          const newUrl = JSON.parse(type.value);
          return `${newUrl.values}`;
        case "group":
          const newGroup = JSON.parse(type.value);
          return `${newGroup.values}`;
        case "vocabulary-flat":
          return `${type.value}`;
        default:
          const defaultValues = type.value ? type.value : ""
          return `${defaultValues}`;
      }
    };    


    

  return (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <span style={{width: "100%", display: "inline-block", overflow: "hidden"}}>
        <div className="table-responsive">
          <br />
          <br />
          <h1>{container.label}</h1>
          <br />
          {props.printSelections[0] && (
            <>
              {" "}
              <h2>Basic Info</h2>
              <table border="1" cellPadding="5" cellSpacing="1" style={{width: "100%"}}>
                <tbody>
                  <tr>
                    <td>
                      <strong>Uuid</strong>
                    </td>
                    <td>{container.uuid}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Label</strong>
                    </td>
                    <td>{container.label}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Type</strong>
                    </td>
                    <td>{container.type?.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Created At</strong>
                    </td>
                    <td>{container.createdAt}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>CreatedBy</strong>
                    </td>
                    <td>{`Name: ${container.createdBy?.firstname} ${container.createdBy?.lastname}, Email: ${container.createdBy?.email}`}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>No of containers</strong>
                    </td>
                    <td>{container?.children?.length}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>No of datastreams</strong>
                    </td>
                    <td>{container?.datastreams?.length}</td>
                  </tr>
                </tbody>
              </table>
              <br />
            </>
          )}
          {props.printSelections[1] && (
            <>
              {container?.properties?.length > 0 && (
                <>
                  <h2>Metadata</h2>
                  <table border="1" cellPadding="5" cellSpacing="1" style={{width: "100%"}}>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Property Label</strong>
                        </td>
                        <td>
                          <strong>Property Value</strong>
                        </td>
                      </tr>
                      {container?.properties?.map((prop, index) => {
                        // if (prop?.value) {
                        //     console.log(typeof prop?.value);
                        //     //newValue = JSON.parse(newValue);
                        //   if (typeof prop?.value === "string") {
                        //     value = prop?.value;
                        //   } else if (typeof prop?.value === "object") {
                        //     // if (newValue?.values?.length > 0) {
                        //     //   value = newValue?.values?.map((item) => (item.label ? item.label + " " : ""));
                        //     // } else if (newValue?.values?.length === 0) {
                        //     //   value = JSON.stringify(newValue?.values[0]);
                        //     // } else if (newValue?.values !== null && newValue?.values !== undefined) {
                        //     //   let objEntries = Object.entries(newValue?.values);
                        //     //   value = objEntries[0][1];
                        //     // } else {
                        //     //   value = "";
                        //     //   }
                        //     value = JSON.stringify(prop?.value);
                        //   } else if (typeof prop?.value === "boolean") {
                        //     value = prop?.value ? "true" : "false";
                        //   }
                        // }

                        return (
                          <tr key={index}>
                            <td>{prop.label}</td>
                            <td>{propertyValue(prop)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <br />
                  <br /> <br />
                </>
              )}
            </>
          )}
          {props.printSelections[2] && (
            <>
              {container?.children?.length > 0 && (
                <>
                  <h2>Containers ({container?.children?.length})</h2>
                  <table border="1" cellPadding="5" cellSpacing="1" style={{width: "100%"}}>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Label</strong>
                        </td>
                        <td>
                          <strong>Type</strong>
                        </td>
                        <td>
                          <strong>Created At</strong>
                        </td>
                      </tr>
                      {container?.children?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.label}</td>
                            <td>{item.type.name}</td>
                            <td>{moment(item.createdAt).format("Do MMMM YYYY hh:mm")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <br />
                  <br /> <br />
                </>
              )}
            </>
          )}

          {props.printSelections[3] && (
            <>
              {container?.datastreams?.length > 0 && (
                <>
                  <h2>Datastreams ({container?.datastreams?.length})</h2>
                  <table border="1" cellPadding="5" cellSpacing="1" style={{width: "100%"}}>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Filename</strong>
                        </td>
                        <td>
                          <strong>Filetype</strong>
                        </td>
                        <td>
                          <strong>File size</strong>
                        </td>
                        <td>
                          <strong>Created At</strong>
                        </td>
                        <td>
                          <strong>Preview</strong>
                        </td>
                      </tr>
                      {container?.datastreams?.map((prop, index) => {
                        return (
                          <tr key={index}>
                            <td>{prop.filename}</td>
                            <td>{prop.mimeType.replace("vnd.openxmlformats-officedocument.wordprocessingml.", "")}</td>
                            <td>{bytesToSize(prop.filesize)}</td>
                            <td>{moment(prop.createdAt).format("Do MMMM YYYY hh:mm")}</td>
                            <td>{prop.mimeType.includes("image") && <DataStreamThumb key={prop.uuid} file={prop} thumbnail={prop.thumbnail} />}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <br />
                  <br />
                </>
              )}
            </>
          )}

          {props.notes !== "" && (
            <div>
              <br></br> <h2>Notes</h2>
              <p>{props.notes}</p>
            </div>
          )}
        </div>
      </span>
    </div>
  );
});

export default PrintPreview;
