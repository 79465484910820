import React, {useMemo} from 'react'
import {Box, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import {ArrowBack, Facebook, Twitter, Instagram, YouTube, Email, Phone, PhoneAndroid, Public} from "@material-ui/icons";
import {ReactComponent as TripadvisorIcon} from '../../../../assets/svg/tripadvisor.svg'
import ImagesCarousel from "./ImagesCarousel";
import Chip from "@material-ui/core/Chip";
import {GuideItemTitles} from "../../../../constants/enums";
import {truncateString} from "../../../../utils";

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxHeight: 460,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: 2
  }
}))

const SocialLinks = ({socialLinks}) => {

  const Icon = ({icon, value}) => {
    switch (true) {
      case icon === 'facebookUrl' && value?.length > 0 :
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`https://fb.com/${value}`}
                  target={'_blank'}><Facebook/></a>
      case icon === 'twitterUrl' && value?.length > 0:
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`https://twitter.com/${value}`}
                  target={'_blank'}><Twitter/></a>
      case icon === 'instagramUrl' && value?.length > 0:
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`https://instagram.com/${value}`}
                  target={'_blank'}><Instagram/></a>
      case icon === 'youtubeUrl' && value?.length > 0:
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`https://youtube.com/${value}`}
                  target={'_blank'}><YouTube/></a>
      case icon === 'tripAdvisorUrl' && value?.length > 0:
        return <a style={{textDecoration: 'none', padding: 0, margin: 0}} href={`https://tripadvisor.com/${value}`}
                  target={'_blank'}><TripadvisorIcon width={30} height={30}/></a>
      default:
        return null
    }
  }

  return <Grid container alignItems={'center'} justifyContent={'center'}>
    {[...Array(6)].map((val, idx) => {
      return <Grid item key={`social-${idx}`} style={{marginLeft: 5}}>
        <Icon icon={Object.keys(socialLinks)[idx]} value={Object.values(socialLinks)[idx]}/>
      </Grid>
    })}

  </Grid>
}

const ContactInfo = ({contactInfo}) => {

  const Icon = ({icon, value}) => {
    switch (true) {
      case icon === 'email' && value?.length > 0 :
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`mailto:${value}`}
                  target={'_blank'}><Email/></a>
      case icon === 'mobile' && value?.length > 0:
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`tel:${value}`}
                  target={'_blank'}><PhoneAndroid/></a>
      case icon === 'siteUrl' && value?.length > 0:
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`https://${value}`}
                  target={'_blank'}><Public/></a>
      case icon === 'telephone' && value?.length > 0:
        return <a style={{textDecoration: 'none',color:'#000', padding: 0, margin: 0}} href={`tel:${value}`}
                  target={'_blank'}><Phone/></a>
      default:
        return null
    }
  }

  return <Grid container alignItems={'center'} justifyContent={'center'}>
    {[...Array(4)].map((val, idx) => {
      return <Grid item key={`contact-${idx}`} style={{marginLeft: 5}}>
        <Icon icon={Object.keys(contactInfo)[idx]} value={Object.values(contactInfo)[idx]}/>
      </Grid>
    })}

  </Grid>
}

const ListItemMobileView = ({item, handleClose, selectedLanguage}) => {
  const classes = useStyles()

  const socialLinks = useMemo(() => {
    if (item?.socialLinks.facebookUrl === "" && item?.socialLinks.twitterUrl === "" && item?.socialLinks.instagramUrl === "" && item?.socialLinks.youtubeUrl === "" && item?.socialLinks.tripAdvisorUrl === "")
      return false
    return true
  }, [item?.socialLinks])

  const contactInfo = useMemo(() => {
    if (item?.contactInfo.email === "" && item?.contactInfo.mobile === "" && item?.contactInfo.siteUrl === "" && item?.contactInfo.telephone === "")
      return false
    return true
  }, [item?.contactInfo])

  return <Grid container direction={'row'} className={classes.container} style={{position: 'relative'}}>
    <Grid item xs={12}>
      <Grid container alignItems={'center'}>
        <Grid item xs={2}>
      <IconButton size={'small'} onClick={handleClose}>
        <ArrowBack/>
      </IconButton>
        </Grid>
        <Grid item>
          <Typography variant={'caption'} style={{fontSize:14, fontWeight:'bold'}}>{truncateString(item?.title[selectedLanguage],20)}</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <ImagesCarousel images={item?.images}/>
    </Grid>
    {item?.code !== "" &&
      (
        <Grid item>
          <Chip
            size="small"
            style={{color: "#eae8e8", backgroundColor: "#ad8ec0", borderRadius: 4}}
            label={item?.code}
          />
        </Grid>
      )}

    {item?.description[selectedLanguage] && <>
      <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
        <Typography variant={'h5'}
                    style={{fontWeight: 'bolder'}}>{GuideItemTitles[selectedLanguage]?.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <div dangerouslySetInnerHTML={{__html: JSON.parse(item?.description[selectedLanguage])}}></div>
      </Grid>
    </>}

    {item?.spatialInfo?.staticImage !== "" && <>
      <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
        <Typography variant={'h5'}
                    style={{fontWeight: 'bolder'}}>{GuideItemTitles[selectedLanguage]?.spatial}</Typography>
      </Grid>
      <Grid item xs={12}>
        <img src={item?.spatialInfo?.staticImage} height={160}/>
      </Grid>
    </>
    }
    {socialLinks && <>
      <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
        <Typography variant={'h5'}
                    style={{fontWeight: 'bolder'}}>{GuideItemTitles[selectedLanguage]?.social}</Typography>
      </Grid>
      <Grid item xs={12} style={{marginBottom: 15, marginTop: 15}}>
        <SocialLinks socialLinks={item?.socialLinks}/>
      </Grid>
    </>
    }
    {contactInfo && <>
      <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
        <Typography variant={'h5'}
                    style={{fontWeight: 'bolder'}}>{GuideItemTitles[selectedLanguage]?.contact}</Typography>
      </Grid>
      <Grid item xs={12} style={{marginBottom: 15, marginTop: 15}}>
        <ContactInfo contactInfo={item?.contactInfo}/>
      </Grid>
    </>}
  </Grid>
}

export default ListItemMobileView