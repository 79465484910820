import React, {useEffect, useState} from 'react'
import BaseDialog from '../../../../../components/Common/BaseDialog'
import {FormGroup} from '@material-ui/core'
import {Input, Label} from 'reactstrap'

const EditVocabularyModal = (props) =>{
  const {vocabulary,open,handleClose,handleUpdate,handleDelete} = props
  const [values,setValues] = useState({name:'',description:'',vocUri:''})

  useEffect(()=>{
    if(open) {
      setValues(vocabulary)
    }
    // eslint-disable-next-line
  },[open])

  return <BaseDialog
    title={'Edit Vocabulary'}
    primaryAction={()=>{
      handleUpdate(values)
      handleClose()
    }}
    primaryActionTitle={'Save'}
    open={open}
    deleteAction={()=> {
      handleDelete()
      handleClose()
    }}
    handleClose={handleClose}
    scrollable={true}
    size='md'

    // contents={contents}
  >
    <FormGroup>
      <Label>Name</Label>
      <Input type={'text'}  value={values.name} onChange={(e)=>{setValues({...values,name: e.target.value})}}/>
    </FormGroup>
    <br/>
    <FormGroup>
      <Label>Description</Label>
      <Input type={'text'} value={values.description} onChange={(e)=>{setValues({...values,description: e.target.value})}}/>
    </FormGroup>
    <br/>
    <FormGroup>
      <Label>Uri</Label>
      <Input type={'text'} value={values.vocUri} onChange={(e)=>{setValues({...values,vocUri: e.target.value})}}/>
    </FormGroup>
  </BaseDialog>
}

export default EditVocabularyModal