import React from 'react'
import {Col} from 'reactstrap'
import Row from 'reactstrap/lib/Row'

const GuideAssetThumb = (props) =>{
  const {asset,selected,fileName,setSelected,from,setAssetFrom} = props

  const handleImageThumbClick = () =>{
    setSelected(asset)
    setAssetFrom(from)
  }

  return <Col lg={'auto'} style={{border:`${selected.uuid!==asset.uuid?'1px solid #d2d2d2':'1px solid rgb(85, 110, 230)'}`,boxShadow:selected.uuid !==asset.uuid?'#d2d2d2 0px 1px 3px 0px':'rgb(85 110 230) 0px 1px 3px 0px',borderRadius:'10px',width:'160px',height:'160px',margin:'5px',cursor:'pointer',textAlign:'center'}} onClick={handleImageThumbClick}>
    {asset.thumbnailUrl?.length >0 ?
      <Row>
        <Col lg={12}>
          <img src={asset.thumbnailUrl} width={'130px'} height={'130px'} className='mt-2' alt={`thumb for ${asset.uuid}`}/>
        </Col>
        <Col lg={12}>
          <div className='text-muted font-size10 text-truncate'>
            {fileName ? fileName : 'Untitled'}
          </div>
        </Col>
      </Row>
      :
      <Row>
        <div className='align-items-center align-content-center justify-content-center' style={{width:'100%',height:'100%'}}>
          <i className='bx bxs-image' style={{fontSize:'30px'}}/>
        </div>
      </Row>
    }
  </Col>
}

export default GuideAssetThumb