import {Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import {Email, Facebook, Instagram, Phone, PhoneAndroid, Public, Twitter} from "@material-ui/icons";
import React from "react";
import {Label} from "reactstrap";
import {ReactComponent as TripadvisorIcon} from '../../../../assets/svg/tripadvisor.svg'
const SocialMediaInformation = ({itemValue,handleChange}) =>{

  return  <Grid item style={{marginTop:15,marginBottom:5}}>
    <Typography variant={'h6'}> Social Media</Typography>
    <Grid container spacing={2} style={{marginTop:10}}>
      <Grid item xs={6}>
        <Label>Facebook</Label>
        <TextField variant={'outlined'}
                   size={'small'}
                   fullWidth
                   value={itemValue?.socialLinks?.facebookUrl}
                   onChange={(e)=>{
                     handleChange('facebookUrl',e.target.value,'socialLinks')
                   }}
                   placeholder={'Facebook'}
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                         <Facebook/>
                       </InputAdornment>
                     ),
                   }}
        />
      </Grid>
      <Grid item xs={6}>
        <Label>Instagram</Label>
        <TextField variant={'outlined'}
                   fullWidth
                   size={'small'}
                   value={itemValue?.socialLinks?.instagramUrl}
                   onChange={(e)=>{
                     handleChange('instagramUrl',e.target.value,'socialLinks')
                   }}
                   placeholder={'Instagram'}
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                         <Instagram/>
                       </InputAdornment>
                     ),
                   }}
        />
      </Grid>
      <Grid item xs={6}>
        <Label>Twitter</Label>
        <TextField variant={'outlined'}
                   fullWidth
                   size={'small'}
                   value={itemValue?.socialLinks?.twitterUrl}
                   onChange={(e)=>{
                     handleChange('twitterUrl',e.target.value,'socialLinks')
                   }}
                   placeholder={'Twitter'}
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                         <Twitter/>
                       </InputAdornment>
                     ),
                   }}
        />
      </Grid>
      <Grid item xs={6}>
        <Label>Tripadvisor</Label>
        <TextField variant={'outlined'}
                   fullWidth
                   size={'small'}
                   value={itemValue?.socialLinks?.tripAdvisorUrl}
                   onChange={(e)=>{
                     handleChange('tripAdvisorUrl',e.target.value,'socialLinks')
                   }}
                   placeholder={'Tripadvisor'}
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                        <TripadvisorIcon width={30} height={30}/>
                       </InputAdornment>
                     ),
                   }}
        />
      </Grid>
    </Grid>
  </Grid>
}

export default SocialMediaInformation