import {Grid} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {AvailableGuideComponents, GuideComponents} from "../../../../constants/enums";
import ListMobileView from "./ListMobileView";
import ListItemMobileView from "./LIstItemMobileView";
import StaticMobileView from "./StaticMobileView";
import RoutesMobileView from "./RoutesMobileView";

const MobileViewBottomMenu = (props) => {
  const {bottomMenu,components,items,languages} = props
  const [selectedComponent,setSelectedComponent] = useState(null)

  const initialLanguage = languages? languages[0] : ''
  const [selectedLanguage,setSelectedLanguage] = useState(initialLanguage)

  useEffect(()=>{
    bottomMenu?.length>0 && setSelectedComponent(bottomMenu[0])
  },[bottomMenu])

  const renderComponent = useCallback((selectedComponent)=>{
    if (!selectedComponent)
      return <></>
    const foundComponent = components.find((component)=>component?.id === selectedComponent)

    if (foundComponent?.type === GuideComponents.list)
        return <ListMobileView items={items} components={components} selectedLanguage={selectedLanguage} component={foundComponent}/>
    else if (foundComponent?.type === GuideComponents.static)
      return <StaticMobileView component={foundComponent} selectedLanguage={selectedLanguage}/>
    else if (foundComponent?.type === GuideComponents.routes)
      return <RoutesMobileView items={items} components={components} selectedLanguage={selectedLanguage} component={foundComponent}/>

    return <>{foundComponent?.type}</>
  },[components])

  return <div style={{padding: 10, height: 520}}>
    <div style={{width:'260px',height:460,overflow:'auto'}}>
      {renderComponent(selectedComponent)}
    </div>
    <div style={{position: 'absolute', bottom: 5, width: '260px'}}>
      <Grid container direction={'row'} spacing={3}>
        {bottomMenu?.map((menuItem) => {
          const foundComponent = components.find((component)=>component.id === menuItem)
          const componentIcon = AvailableGuideComponents.find((availableGuideComponent)=>availableGuideComponent.type === foundComponent?.type)?.icon
          return <Grid item key={`component-${menuItem}`}>
              <Grid container>
                <Grid item style={{fontSize: 32}} onClick={()=>setSelectedComponent(menuItem)}>
                  <i className={componentIcon} style={{color:selectedComponent === menuItem ? '#3c8cb6' :''}}/>
                </Grid>
              </Grid>
            </Grid>
          }
        )}
      </Grid>
    </div>
  </div>

}

export default MobileViewBottomMenu