import React from 'react'
import {Grid, makeStyles, Typography} from "@material-ui/core";
import {AccessTime} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  listCard:{
    height: 140,
    position: 'relative',
    border:'1px solid #ececec',
    backgroundColor: '#ffffff',
    padding: 8,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
  },
  listCardThumbnail:{
    backgroundSize: 'cover',
    borderRadius: 5,
    width:'98%',
    height: 90,
  }
}))


const ListOfListsItem = ({item,selectedLanguage,duration=null}) =>{
  const classes = useStyles()
  return <Grid container className={classes.listCard} direction={'row'} alignItems={'center'} alignContent={'center'}>
    <Grid item className={classes.listCardThumbnail}  style={{backgroundImage: item?.coverImage && `url(${item?.coverImage?.thumb})`}} xs={12}/>
    <Grid item xs={12}>
      <Typography variant={'h6'} style={{fontWeight:'bold',fontSize:13}}>{item?.title[selectedLanguage]}</Typography>
    </Grid>
    {duration && <Grid item xs={12}>
      <Grid container spacing={1} alignItems={'center'}>
        <Grid item>
          <AccessTime/>
        </Grid>
        <Grid item>
            <Typography variant={'caption'}>{duration}m</Typography>
        </Grid>
      </Grid>
    </Grid>
    }
  </Grid>
}

export default ListOfListsItem