import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Grid, makeStyles }from "@material-ui/core";
import ItemCard from "./ItemCard";

import moment from "moment";

import GuideContext from "../../GuideEditPage/GuideSidebarContext";

import ItemEditPage from "./ItemEditPage";
import toastr from "toastr";

const useStyles = makeStyles(() => ({
  editItemContainer: {
    backgroundColor: '#ffffff',
    padding: 14,
    width: 'calc(100vw - 300px)',
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
    minHeight: 580,
    height: 'auto',
    position: 'relative'
  }
}))

const ItemsList = ({items = [], setItems, handleDeleteItem,languages}) => {
  const classes = useStyles()


  const [itemValue, setItemValue] = useState({})
  const {editItem,setEditItem} = useContext(GuideContext)

  useEffect(()=>{
    if (editItem?.hideSidebar)
      setItemValue(editItem?.data)
  },[editItem?.hideSidebar])

  const handleChange = useCallback((key, value, objectKey) => {
    if (objectKey) {
      setItemValue((prevState) => ({
        ...prevState,
        [objectKey]: {
          ...prevState[objectKey],
          [key]: value
        }
      }))
    } else
      setItemValue((prevState) => ({...prevState, [key]: value}))
  }, [])

  const handleChangeMultilingual = useCallback((key, value,selectedLanguage) => {
      setItemValue((prevState) => ({...prevState, [key]: {
          ...prevState[key],
        [selectedLanguage]: value}
      }))
  }, [])

  const handleSave = useCallback( () => {
    const codeExists = items.find((i)=>i.code === itemValue.code)
    if (codeExists)
      toastr.error(`This code is used by ${codeExists.title[languages[0]]} item`);
    else
      setItems(itemValue)
  }, [itemValue, setEditItem, setItems,items])

  const handleClose = () => {
    setEditItem({hideSidebar:false,data:{}})
  }

  if (itemValue?.id)
    return <ItemEditPage
      classes={classes}
      itemValue={itemValue}
      languages={languages}
      handleChange={handleChange}
      handleChangeMultilingual={handleChangeMultilingual}
      handleClose={handleClose}
      handleSave={handleSave}
    />
  return <>
    <Grid container style={{maxWidth: 'calc(100vw - 600px)', width: "auto"}} spacing={2}>
      {items?.sort((item, itemB) => moment.utc(itemB.createdAt).diff(moment.utc(item.createdAt)))?.map((item) => {
        return <Grid key={item.id} item>
          <ItemCard data={item} setItemEdit={setEditItem} languages={languages} setItemValue={setItemValue}
                    handleDeleteItem={handleDeleteItem}/>
        </Grid>
      })
      }
    </Grid>
  </>
}

export default ItemsList