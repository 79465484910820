import React, {useMemo, useState} from "react";
import {Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap";
import * as classnames from "classnames";
import ContainerCards from "./container-cards";
import DataStreamList from "../DataStreams/data-stream-list";
import _ from "lodash";
import {compareProp, createMap} from "../../utils";
import EntityMetadata from "../../components/Common/EntityMetadata";
import NoFiles from "./NoFiles";
import ContainerList from "./container-list";

const ContainerRecordLayout = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    handleFileClick,
    handleFileEdit,
    handleDeleteFile,
    showList,
    handleMoveFile,
    handleFileRename,
    handleLinkFile,
    handleContainerClick,
    children,
    selectedContainer,
    handleContainerSelect,
    handleEditClick,
    deleteContainer,
    currentContainer,
    dataStreams,
  } = props;
  const {result} = props.context.Container.get;
  const properties = props.context.Property.getAll.result;
  const propertyGroups = props.context.PropertyGroup.getAll.result;
  const groupMap = useMemo(() => createMap(propertyGroups), [propertyGroups]);
  const joinProps = useMemo(() => {
    if (!result.properties) return [];
    return result.properties;
  }, [result.properties]);

  const groups = useMemo(() => {
    return _.chain(joinProps)
        .groupBy("groupId")
        .map((value, key) => ({
          id: parseInt(key),
          properties: value.sort(compareProp),
        }))
        .value();
  }, [joinProps])

  const ContainerItemsLayout = useMemo(() => {
    return showList ? ContainerList : ContainerCards;
  },[showList])

  return (
    <Row>
      <Col xl={"4"} md={"12"}>
        <Card>
          <CardBody>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {groups?.forEach((group, index) => {
                const currentGroup = groupMap.get(group.id);
                if (currentGroup)
                  return (
                    <NavItem key={currentGroup.id}>
                      <NavLink
                        style={{cursor: "pointer"}}
                        className={classnames({
                          active: activeTab === index,
                        })}
                        onClick={() => setActiveTab(index)}
                      >
                        <span className="d-none d-sm-block">{currentGroup.name}</span>
                      </NavLink>
                    </NavItem>
                  );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {groups.map((group, index) => {
                return (
                  <TabPane tabId={index} className="p-2" key={index}>
                    <EntityMetadata entity={group} properties={properties} />
                  </TabPane>
                );
              })}
            </TabContent>
          </CardBody>
        </Card>
      </Col>
      <Col xl={"8"}>
        <Row>
          <ContainerItemsLayout
            handleContainerClick={handleContainerClick}
            containers={children}
            selectedContainer={selectedContainer}
            handleContainerSelect={handleContainerSelect}
            handleEditClick={handleEditClick}
            handleDelete={deleteContainer}
            currentContainer={currentContainer}
            {...props}
          />
        </Row>
        <Row>
          <DataStreamList
            showList={showList}
            files={dataStreams}
            handleFileClick={handleFileClick}
            handleFileEdit={handleFileEdit}
            handleFileRename={handleFileRename}
            handleDeleteFile={handleDeleteFile}
            handleMoveFile={handleMoveFile}
            handleLinkFile={handleLinkFile}
          />
        </Row>
        <Row>{props.noFiles && <NoFiles overview={true} />}</Row>
      </Col>
    </Row>
  );
};

export default ContainerRecordLayout;
