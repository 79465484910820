import React, {useCallback, useState} from 'react'
import {Box, Divider, Grid} from "@material-ui/core";
import {Button} from "reactstrap";
import GuideComponentsDialog from "../ComponentsList/GuideComponentsDialog";
import {makeid} from "../../../../utils";
import {GuideComponentsModels} from "../../../../constants/enums";

const GuideComponents = (props) => {
  const {components, guide, setGuide, bottomMenu} = props
  const [openComponentsDialog,setOpenComponentsDialog] = useState(false)

  const handleAddComponent = useCallback((newComponent) => {
    const newId = makeid()
    setGuide(() => ({
      ...guide,
      guideDetails: {
        ...guide.guideDetails,
        components: [...components, {id: newId , ...GuideComponentsModels[newComponent?.type]}]
      }
    }))

    setOpenComponentsDialog(false)
  }, [guide])

  return <Box width={'100%'} display={'flex'} mt={4} alignItems={'center'} justifyContent={'center'}>
    <Button color={'primary'} onClick={()=>setOpenComponentsDialog(!openComponentsDialog)}>New Component</Button>

    <GuideComponentsDialog open={openComponentsDialog} bottomMenu={false} components={components} handleAddComponent={handleAddComponent}
                           handleClose={() => setOpenComponentsDialog(false)}/>

  </Box>
}

export default GuideComponents