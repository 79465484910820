import React, {Component, useEffect, useState} from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import {Link, withRouter} from 'react-router-dom';
//i18n
import {withNamespaces} from 'react-i18next';
import TagList from './TagList';
import StorageInfo from './StorageInfo'
import {HOME_PAGE} from '../../config';
import {useLocation} from 'react-router';
import {connect} from 'react-redux'
import {usePermission} from '../../helpers/hooks'
import {permissionsEnums} from '../../constants/enums'

const GlobalMenu = (props) => {

  const {context} = props
  const [checkPermission] = usePermission(context)
  const [showContentModel, setShowContentModel] = useState(false)
  const [showGeneralSettings, setShowGeneralSettings] = useState(false)
  const [showIamSettings, setShowIamSettings] = useState(false)
  const [showReports, setShowReports] = useState(false)

  useEffect(() => {
    setShowContentModel(checkPermission(permissionsEnums.STORE.sidebar.settings.showContentModel))
    setShowGeneralSettings(checkPermission(permissionsEnums.STORE.sidebar.settings.showGeneralSettings))
    setShowIamSettings(checkPermission(permissionsEnums.STORE.sidebar.settings.showIam))
    setShowReports(checkPermission(permissionsEnums.STORE.sidebar.showReports))
    // eslint-disable-next-line
  }, [context.MyPermissions])

  return <>
    <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title">{props.t('Menu')}</li>
      {/*<li>*/}
      {/*    <Link to="/dashboard" className="waves-effect">*/}
      {/*        <i className="bx bx-home-circle"></i>*/}
      {/*        <span>{this.props.t('Dashboards')}</span>*/}
      {/*    </Link>*/}
      {/*</li>*/}
      <li>
        <Link to="/containers" className="waves-effect">
          <i className="bx bx-cloud"></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('My Content')}</span>
        </Link>
      </li>
      {/*<li>*/}
      {/*  <Link to="/upload" className="waves-effect">*/}
      {/*    <i className="bx bx-folder-plus"></i>*/}
      {/*    <span>{this.props.t("Upload")}</span>*/}
      {/*  </Link>*/}
      {/*</li>*/}
      <li>
        <Link to="/recent" className="waves-effect">
          <i className="bx bx-time"></i>
          <span>{props.t('Recent')}</span>
        </Link>
      </li>
      <li>
        <Link to="/starred" className="waves-effect">
          <i className="bx bx-star"></i>
          <span>{props.t('Starred')}</span>
        </Link>
      </li>
      {showReports &&
        <li>
          <Link to="/reports" className="waves-effect">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span>{props.t('Reports')}</span>
          </Link>
        </li>
      }
      <li>
        <Link to="/trash" className="waves-effect">
          <i className="bx bx-trash"></i>
          <span>{props.t('Trash')}</span>
        </Link>
      </li>
      <li>
        <Link to="/#" className="has-arrow waves-effect">
          <i className="bx bx-cog"></i>
          <span>{props.t('Settings')}</span>
        </Link>
        <ul className="sub-menu" aria-expanded="false">
          {showGeneralSettings &&
            <li>
              <Link to="/settings/general">{props.t('General')}</Link>
            </li>
          }
          {showContentModel &&
            <>
              <li>
                <Link to="/settings/content-model">
                  {props.t('Content Model')}
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/settings/content-model-v2">*/}
              {/*    {props.t('Content Model v2.0')}*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </>
          }
          {showIamSettings &&
            <>
              <li>
                <Link to="/settings/groups">{props.t('Groups')}</Link>
              </li>
              <li>
                <Link to="/settings/users">{props.t('Users')}</Link>
              </li>
            </>
          }
          <li>
            <Link to="/settings/tags">{props.t('Tags')}</Link>
          </li>
        </ul>
      </li>
    </ul>
    <div className='dropdown-divider mb-2'/>
    <ul className="metismenu list-unstyled">
      <li>
        <Link to="/storage" className="waves-effect">
          <i className="bx bx-cabinet"></i>
          <span>{props.t('Storage')}
            <small>({parseFloat(sessionStorage.getItem('percentage')).toFixed(2)} %)</small></span>
        </Link>
      </li>
    </ul>
    <StorageInfo {...props}/>
    <div className='dropdown-divider'/>
    <TagList {...props} />
  </>
}

const SurveysMenu = (props) => {

  return <>
    <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title">{props.t('Menu')}</li>
      {/*<li>*/}
      {/*    <Link to="/dashboard" className="waves-effect">*/}
      {/*        <i className="bx bx-home-circle"></i>*/}
      {/*        <span>{this.props.t('Dashboards')}</span>*/}
      {/*    </Link>*/}
      {/*</li>*/}
      <li>
        <Link to="/surveys" className="waves-effect">
          <i className='bx bxs-spreadsheet'></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('Surveys')}</span>
        </Link>
      </li>
      <li>
        <Link to="/contacts" className="waves-effect">
          <i className='bx bxs-contact'></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('Contacts')}</span>
        </Link>
      </li>
      {/*  /!*<li>*!/*/}
      {/*  /!*  <Link to="/upload" className="waves-effect">*!/*/}
      {/*  /!*    <i className="bx bx-folder-plus"></i>*!/*/}
      {/*  /!*    <span>{this.props.t("Upload")}</span>*!/*/}
      {/*  /!*  </Link>*!/*/}
      {/*  /!*</li>*!/*/}
      {/*  <li>*/}
      {/*    <Link to="/recent" className="waves-effect">*/}
      {/*      <i className="bx bx-time"></i>*/}
      {/*      <span>{props.t("Recent")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/starred" className="waves-effect">*/}
      {/*      <i className="bx bx-star"></i>*/}
      {/*      <span>{props.t("Starred")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/reports" className="waves-effect">*/}
      {/*      <i className="bx bx-pie-chart-alt-2"></i>*/}
      {/*      <span>{props.t("Reports")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/trash" className="waves-effect">*/}
      {/*      <i className="bx bx-trash"></i>*/}
      {/*      <span>{props.t("Trash")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/#" className="has-arrow waves-effect">*/}
      {/*      <i className="bx bx-cog"></i>*/}
      {/*      <span>{props.t("Settings")}</span>*/}
      {/*    </Link>*/}
      {/*    <ul className="sub-menu" aria-expanded="false">*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/general">{props.t("General")}</Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/content-model">*/}
      {/*          {props.t("Content Model")}*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/groups">{props.t("Groups")}</Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/users">{props.t("Users")}</Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/tags">{props.t("Tags")}</Link>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </li>*/}
    </ul>
    {/*<div className='dropdown-divider mb-2'/>*/}
    {/*<ul className="metismenu list-unstyled">*/}
    {/*  <li>*/}
    {/*    <Link to="/storage" className="waves-effect">*/}
    {/*      <i className="bx bx-cabinet"></i>*/}
    {/*      <span>{props.t("Storage")} <small>({parseFloat(sessionStorage.getItem('percentage')).toFixed(2)} %)</small></span>*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*</ul>*/}
    {/*<StorageInfo {...props}/>*/}
    {/*<div className='dropdown-divider'/>*/}
    {/*<TagList {...props} />*/}
  </>
}
const StoriesMenu = (props) => {

  return <>
    <ul className="metismenu list-unstyled" id="side-menu">
      <li className="menu-title">{props.t('Menu')}</li>
      {/*<li>*/}
      {/*    <Link to="/dashboard" className="waves-effect">*/}
      {/*        <i className="bx bx-home-circle"></i>*/}
      {/*        <span>{this.props.t('Dashboards')}</span>*/}
      {/*    </Link>*/}
      {/*</li>*/}
      <li>
        <Link to="/stories" className="waves-effect">
          <i className='bx bx-movie'></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('Stories')}</span>
        </Link>
      </li>
      <li>
        <Link to="/screens" className="waves-effect">
          <i className='bx bx-devices'></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('Screens')}</span>
        </Link>
      </li>
      <li>
        <Link to="/websites" className="waves-effect">
          <i className='bx bx-world'></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('Websites')}</span>
        </Link>
      </li>

      <div className='dropdown-divider'/>

      <li>
        <Link to="/screen-app" className="waves-effect">
          <i className='bx bx-package'></i>
          {/*<span className="badge badge-pill badge-info float-right">*/}
          {/*  03*/}
          {/*</span>*/}
          <span>{props.t('Screen app')}</span>
        </Link>
      </li>

      {/*  /!*<li>*!/*/}
      {/*  /!*  <Link to="/upload" className="waves-effect">*!/*/}
      {/*  /!*    <i className="bx bx-folder-plus"></i>*!/*/}
      {/*  /!*    <span>{this.props.t("Upload")}</span>*!/*/}
      {/*  /!*  </Link>*!/*/}
      {/*  /!*</li>*!/*/}
      {/*  <li>*/}
      {/*    <Link to="/recent" className="waves-effect">*/}
      {/*      <i className="bx bx-time"></i>*/}
      {/*      <span>{props.t("Recent")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/starred" className="waves-effect">*/}
      {/*      <i className="bx bx-star"></i>*/}
      {/*      <span>{props.t("Starred")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/reports" className="waves-effect">*/}
      {/*      <i className="bx bx-pie-chart-alt-2"></i>*/}
      {/*      <span>{props.t("Reports")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/trash" className="waves-effect">*/}
      {/*      <i className="bx bx-trash"></i>*/}
      {/*      <span>{props.t("Trash")}</span>*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*  <li>*/}
      {/*    <Link to="/#" className="has-arrow waves-effect">*/}
      {/*      <i className="bx bx-cog"></i>*/}
      {/*      <span>{props.t("Settings")}</span>*/}
      {/*    </Link>*/}
      {/*    <ul className="sub-menu" aria-expanded="false">*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/general">{props.t("General")}</Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/content-model">*/}
      {/*          {props.t("Content Model")}*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/groups">{props.t("Groups")}</Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/users">{props.t("Users")}</Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to="/settings/tags">{props.t("Tags")}</Link>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </li>*/}
    </ul>
    {/*<div className='dropdown-divider mb-2'/>*/}
    {/*<ul className="metismenu list-unstyled">*/}
    {/*  <li>*/}
    {/*    <Link to="/storage" className="waves-effect">*/}
    {/*      <i className="bx bx-cabinet"></i>*/}
    {/*      <span>{props.t("Storage")} <small>({parseFloat(sessionStorage.getItem('percentage')).toFixed(2)} %)</small></span>*/}
    {/*    </Link>*/}
    {/*  </li>*/}
    {/*</ul>*/}
    {/*<StorageInfo {...props}/>*/}
    {/*<div className='dropdown-divider'/>*/}
    {/*<TagList {...props} />*/}
  </>
}

const GuideMenu = (props) => {
  return (
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{props.t('Menu')}</li>
        <li>
          <Link to="/guides" className="waves-effect">
            <i className='bx bx-compass'></i>
            <span>{props.t('Guides')}</span>
          </Link>
        </li>
    </ul>
  )
}


const CustomMenu = (props) => {
  let location = useLocation();
  let routes = location.pathname.split('/')
  const page = routes[1] ? routes[1] : ''

  switch (page) {
    case 'surveys': {
      return <SurveysMenu {...props} />
    }
    case 'contacts': {
      return <SurveysMenu {...props} />
    }
    //eslint-disable-next-line
    case 'stories': {
      // fall through to stories
    }
    //eslint-disable-next-line
    case 'screens': {
      // fall through to stories
    }
    //eslint-disable-next-line
    case 'screen-app': {
      // fall through to stories
    }
    //eslint-disable-next-line
    case 'websites' : {
      return <StoriesMenu {...props} />
    }
    case 'guides' : {
      return <GuideMenu {...props} />
    }
    default: {
      return <GlobalMenu {...props} />
    }
  }
}

class SidebarContent extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      const currentPath = items[i].pathname.replace(HOME_PAGE, '');
      if (this.props.location.pathname === currentPath) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <CustomMenu {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {})(withRouter(withNamespaces()(SidebarContent)))
