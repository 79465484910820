import React, {useEffect, useState} from 'react'
import BaseDialog from "../../../../components/Common/BaseDialog";
import {Box, Grid, makeStyles, Typography} from "@material-ui/core";
import {AvailableGuideComponents, GuideComponents} from "../../../../constants/enums";
import {truncateString} from "../../../../utils";
import moment from "moment";

const useStyles = makeStyles(() => ({
  itemCard: {
    backgroundSize: 'cover',
    backgroundColor: '#ffffff',
    padding: 8,
    width:200,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
    height: 140,
    position: 'relative'
  },
  itemTitle: {
    backgroundColor: 'rgba(255,255,255,0.91)', padding: 5, borderRadius: 5
  }
}))


const componentTypes = ['LIST','ROUTES','STATIC']

const GuideComponentsBottomMenuDialog = (props) => {
  const classes = useStyles()
  const {open, handleClose, components, handleAddComponent,bottomMenu,languages} = props
  const [selectedComponents,setSelectedComponents] = useState([])
  const [activeTab,setActiveTab] = useState(0)
  const initialLanguage = languages? languages[0] : ''
  const [selectedLanguage,setSelectedLanguage] = useState(initialLanguage)


  useEffect(()=>{
    if (open && bottomMenu?.length>0)
      setSelectedComponents(components?.filter((component)=>bottomMenu?.includes(component.id)).map((component)=>component.id))
    else
      setSelectedComponents([])
  },[open,bottomMenu])

  const handleSelectComponent = (selectedComponent) =>{
    if (selectedComponents.includes(selectedComponent))
      setSelectedComponents(selectedComponents.filter((selectedId)=>selectedId !== selectedComponent))
    else
      setSelectedComponents([...selectedComponents,selectedComponent])
  }

  const renderStepOne = () => {
    return <Grid container spacing={2}>
      {components?.filter((c)=>c.type===componentTypes[0])?.map((component) => {
        const isChecked = selectedComponents?.find((selectedComponent)=>selectedComponent === component.id) !== undefined
        return <Grid key={component.id} item style={{position:'relative'}}>
          <Grid container alignContent={'flex-start'} alignItems={'center'} className={classes.itemCard}
                style={{outline:isChecked?'#7589da solid 4px':''}}
                onClick={()=>handleSelectComponent(component.id)}
          >
            <Grid item xs={12} className={classes.itemTitle}>
              {component.title[selectedLanguage] ? <span style={{fontWeight: 'bolder'}}>{truncateString(component.title[selectedLanguage], 30)}</span> :
                <span style={{color: '#939393', fontStyle: 'italic'}}>Empty title</span>}
            </Grid>
            <Grid item style={{position: 'absolute', bottom: 4,right:2, width: '95%'}} alignContent={'flex-end'} justify={'flex-end'} xs={12}>
              <Grid container alignItems={'center'}
                    style={{backgroundColor: 'rgba(255,255,255,0.53)', padding: 5, borderRadius: 5}}>
                <Grid item>
                  <Typography variant={'caption'} style={{color: '#000', textShadow: '#fff6f6 0px 1px 1px'}}>Created
                    at {moment(component?.createdAt).format('hh:mm DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
              <Box display={'flex'} alignItems={'flex-end'} width={'100%'} justifyContent={'flex-end'}>
                <div className="custom-control custom-switch mb-2 mt-2 z-depth-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`${component.id}-component,`}
                    checked={isChecked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${component.id}-component`}
                    onClick={() => handleSelectComponent(component)}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      })}
    </Grid>
  }

  const renderStepTwo = () => {
    return <Grid container spacing={2}>
      {components?.filter((c)=>c.type===componentTypes[1])?.map((component) => {
        const isChecked = selectedComponents?.find((selectedComponent)=>selectedComponent === component.id) !== undefined
        return <Grid key={component.id} item style={{position:'relative'}}>
          <Grid container alignContent={'flex-start'} alignItems={'center'} className={classes.itemCard}
                style={{outline:isChecked?'#7589da solid 4px':''}}
                onClick={()=>handleSelectComponent(component.id)}
          >
            <Grid item xs={12} className={classes.itemTitle}>
              {component.title[selectedLanguage] ? <span style={{fontWeight: 'bolder'}}>{truncateString(component.title[selectedLanguage], 30)}</span> :
                <span style={{color: '#939393', fontStyle: 'italic'}}>Empty title</span>}
            </Grid>
            <Grid item style={{position: 'absolute', bottom: 4,right:2, width: '95%'}} alignContent={'flex-end'} justify={'flex-end'} xs={12}>
              <Grid container alignItems={'center'}
                    style={{backgroundColor: 'rgba(255,255,255,0.53)', padding: 5, borderRadius: 5}}>
                <Grid item>
                  <Typography variant={'caption'} style={{color: '#000', textShadow: '#fff6f6 0px 1px 1px'}}>Created
                    at {moment(component?.createdAt).format('hh:mm DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
              <Box display={'flex'} alignItems={'flex-end'} width={'100%'} justifyContent={'flex-end'}>
                <div className="custom-control custom-switch mb-2 mt-2 z-depth-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`${component.id}-component,`}
                    checked={isChecked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${component.id}-component`}
                    onClick={() => handleSelectComponent(component)}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      })}
    </Grid>
  }

  const renderStepThree = () => {
    return <Grid container spacing={2}>
      {components?.filter((c)=>c.type===componentTypes[2])?.map((component) => {
        const isChecked = selectedComponents?.find((selectedComponent)=>selectedComponent === component.id) !== undefined
        return <Grid key={component.id} item style={{position:'relative'}}>
          <Grid container alignContent={'flex-start'} alignItems={'center'} className={classes.itemCard}
                style={{outline:isChecked?'#7589da solid 4px':''}}
                onClick={()=>handleSelectComponent(component.id)}
          >
            <Grid item xs={12} className={classes.itemTitle}>
              {component.title[selectedLanguage] ? <span style={{fontWeight: 'bolder'}}>{truncateString(component.title[selectedLanguage], 30)}</span> :
                <span style={{color: '#939393', fontStyle: 'italic'}}>Empty title</span>}
            </Grid>
            <Grid item style={{position: 'absolute', bottom: 4,right:2, width: '95%'}} alignContent={'flex-end'} justify={'flex-end'} xs={12}>
              <Grid container alignItems={'center'}
                    style={{backgroundColor: 'rgba(255,255,255,0.53)', padding: 5, borderRadius: 5}}>
                <Grid item>
                  <Typography variant={'caption'} style={{color: '#000', textShadow: '#fff6f6 0px 1px 1px'}}>Created
                    at {moment(component?.createdAt).format('hh:mm DD/MM/YYYY')}</Typography>
                </Grid>
              </Grid>
              <Box display={'flex'} alignItems={'flex-end'} width={'100%'} justifyContent={'flex-end'}>
                <div className="custom-control custom-switch mb-2 mt-2 z-depth-0">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={`${component.id}-component,`}
                    checked={isChecked}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`${component.id}-component`}
                    onClick={() => handleSelectComponent(component)}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      })}
    </Grid>
  }

  const contents = [renderStepOne(),renderStepTwo(),renderStepThree()]
  const tabs = [{title:'Lists'},{title:'Routes'},{title:'Static'}]

  return <BaseDialog
    open={open}
    handleClose={handleClose}
    title={'Repox Guide Components'}
    size={'lg'}
    tabs={tabs}
    activeTab={activeTab}
    primaryActionTitle={'Add Components'}
    primaryAction={() => {
      handleAddComponent(selectedComponents)
    }}
    primaryActionDisabled={selectedComponents?.length <=0}
    contents={contents}
  />

}

export default GuideComponentsBottomMenuDialog