import React, {useEffect, useState} from 'react'
import {getThumb} from "../../../../utils";
import {Grid} from "@material-ui/core";
import Carousel from "react-elastic-carousel";


const SingleImageCarousel = ({image})=>{
  const [base64Data,setBase64Data] = useState(null)

  useEffect(()=>{
    getThumb(image.uuid,image.origin,setBase64Data)
  },[image.origin, image.uuid])
  return <div style={{width:'100%',height:100,backgroundSize:'cover',backgroundImage:`url(${base64Data})`}}/>
}

const ImagesCarousel = ({images}) =>{
  return  <Carousel
    showArrows={false}
  >
    {images?.length >0 && images?.map((image)=>{
    return <React.Fragment key={image.id}>
      <SingleImageCarousel image={image}/>
    </React.Fragment>
    })
    }
  </Carousel>
}

export default ImagesCarousel