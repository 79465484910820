import React, {useEffect, useState, useMemo,useCallback} from "react";
import BaseContent from "../../components/Common/BaseContent";
import {useListToggle, usePaging} from "../../helpers/hooks";
import {ApiEndpoint} from "../../store/core/endpoint";
import {EntityType} from "../../store/core/entityType";
import ListToggle from "../../components/Common/ListToggle";
import moment from "moment";
import {bytesToSize} from "../../utils";
import RecentCard from '../../components/Common/recent-card';
import ListSettings from "../../components/Common/ListSettings";
import MoreButton from "../../components/Common/MoreButton";
import {EntityDialog} from "../../pages/Containers/EntityDialog";
import {connect} from 'react-redux';
import DataStreamList from "../DataStreams/data-stream-list";
import { Row } from 'reactstrap';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { API_URL } from '../../config'
import {
  useContainerActions,
  useContainers,
  useToggle,
} from '../../helpers/hooks';
import {downloadThumb, exportFile, fetchData, getAxiosDefaultConfig, getIconForDatastream, postData,} from '../../../src/utils';
import {
  clearContainer,
  clearContainers,
  createContainer,
  deleteContainer,
  getContainer,
  updateContainer,
} from '../../store/container/actions';
import {getDataStream, deleteDataStream, updateDataStream,clearDataStreams} from '../../store/dataStream/actions'
import { Schema } from '../../store/core/schema';
import ReportDialog from '../../pages/Containers/report-dialog';
import PrintPreviewDialog from "../../pages/Containers/PrintPreviewDialog";
import ImportDialog from '../../pages/Containers/import-dialog';
import ExportDialog from '../../pages/Containers/ExportDialog';
import DeleteDialog from '../../pages/Containers/DeleteDialog';
import RenameDialog from '../../pages/Containers/rename-dialog';
import MoveDialog from "../../pages/Containers/MoveDialog";
import LinkDialog from "../../pages/Containers/LinkDialog";
import DataStreamEntityDialog from '../DataStreams/EntityDialog'
import { MandatoryContext } from '../../pages/Containers/MandatoryContext'
import RenameDatastreamDialog from '../DataStreams/rename-dialog'
import MoveFileDialog from '../DataStreams/move-datastream'
import LinkFileDialog from '../DataStreams/link-datastream'
const breadcrumbs = [{title: "Starred", link: "#"}];

const StarredContainers = (props) => {
  //const result = useGroupedEntitiesFetch(url);
  const {
    context,
    getContainer,
    clearContainer,
    createContainer,
    updateContainer,
    deleteContainer,
    clearContainers,
    toggleRightSidebar,
    clearDataStreams,
    getDataStream,
    deleteDataStream,
    updateDataStream
  } = props;
  const entityType = EntityType.Container;  
  const entityTypeDataStream = EntityType.DataStream;
  const url = ApiEndpoint[EntityType.Container] + "/starred";
  const urlDatastream = ApiEndpoint[EntityType.DataStream] + "/starred";

  const {result,error, isLoading} = props.context.Container.get;
  const {result: resultDataStream} = props.context.DataStream.get
  const containerGetter = props.context.Container.get;
  const {showRightSidebar} = props.context.Layout;
  const properties = props.context.Property.getAll.result;
  const children = props.context.Container.getAll.result;
  const dataStreamTypes = props.context.DataStreamType.getAll.result;
  const thisContainer = props.context.Container.get.result;  
  const propertyGroups = props.context.PropertyGroup.getAll.result;  
  const containerTypes = props.context.ContainerType.getAll.result;
  const qualifiers = props.context.Qualifier.getAll.result;
  
  
  const [isContainer, setIsContainer] = useState(true);
  const [entity, setEntity] = useState(Schema[entityType]);
  const [open, setOpen] = useState(false);  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDelete, toggleDelete] = useToggle(false);
  const [showMoveFile, toggleMoveFile] = useToggle(false);
  const [showLinkFile, toggleLinkFile] = useToggle(false);
  const [showMoveContainer, toggleMoveContainer] = useToggle(false);
  const [showLinkContainer, toggleLinkContainer] = useToggle(false);  
  const [selectedContainers, setSelectedContainers] = useState([]);  
  const [selectedContainer, setSelectedContainer] = useState({})
  const [parentContainer, setParentContainer] = useState({})
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);  
  const [openPrintPreview, setOpenPrintPreview] = useState(false)  
  const [openReport, setOpenReport] = useState(false)  
  const [openRename, setOpenRename] = useState(false)  
  const [toolbarAction, setToolbarAction] = useState(false)
  const [isLoadings, setIsLoadings] = useState(true); 
  const [mandatoryNotice, setMandatoryNotice] = useState(false)
  const [openFileRename, setOpenFileRename] = useState(false)  
  const [editDataStream, setEditDataStream] = useState(false)  
  const [selectedDatastream, setSelectedDatastream] = useState({})  
  const [deleteFile, setDeleteFile] = useState(false)    
  const [entityDatastream, setEntityEntityDatastream] = useState(Schema[entityTypeDataStream]);  
  const [showDeleteDataStreamModal, setShowDeleteDataStreamModal] = useState(false);
  const [openDatastreamModal, setOpenDatastreamModal] = useState(false);   
  const [selectTypeToOpen, setSelectTypeToOpen] = useState(null);  
  const [page, setPage] = useState(0);
  const [pageDatastream, setPageDatastream] = useState(0);  
  const [isList, toggleList] = useListToggle();
  const [isLoadingData, data, contents] = usePaging(url, {
    size: 20,
    page,
    identifier: "uuid",
  });

  const [isLoadingDatastream, dataDatastream, contentsDatastream] = usePaging(urlDatastream, {
    size: 20,
    page: pageDatastream,
    identifier: "uuid",
  });

  const containerData = contents.map((container) => ({
    uuid: container.uuid,
    id: container.uuid,    
    label: container.label,
    route: "/containers/" + container.uuid,
    icon: "bx-folder-open",
    color: container.color,
    published: container.published,
    starred: container.starred,
    properties: container.properties,    
  }));

  const datastreamData = contentsDatastream.map((datastream) => ({
    ...datastream,
    label: datastream.label,
    route: `/containers/${datastream.membership[0]?.uuid}/?datastream=${datastream.uuid}`,
    icon: "bx-file",
    color: datastream.color,
    name: datastream.label,
    type: datastream.type,
    uuid: datastream.uuid,
    thumbnail: datastream.thumbnail,
    filesize: datastream.filesize,
    mimeType: datastream.mimeType,    
  }));

  const containerList = useMemo(() => {
    return contents.map((container) => ({
      label: container.label,
      totalFiles: container.datastreamCount + container.childCount,
      creator: `${container.createdBy.firstname} ${container.createdBy.lastname}`,
      createdAt: moment(container.createdAt).format("Do MMMM YYYY"),
      _published: container.published,
      _route: "/containers/" + container.uuid,
      _icon: "bx-folder",
      // handleClick: () => handleEntityClick(containerType),
      color: container.color,
      id: container.uuid
    }));
  }, [contents]);

  const datastreamList = useMemo(() => {
    return contentsDatastream.map((datastream) => ({
      label: datastream.label,
      creator: `${datastream.createdBy.firstname} ${datastream.createdBy.lastname}`,
      createdAt: moment(datastream.createdAt).format("Do MMMM YYYY"),
      fileSize: bytesToSize(datastream.filesize),
      _published: datastream.published,
      _route: `/containers/${datastream.membership[0]?.uuid}/?datastream=${datastream.uuid}`,
      _icon: "bx-file",
      // handleClick: () => handleEntityClick(containerType),
      color: datastream.color,
      name: datastream.name,
      type: datastream.type,
      uuid: datastream.uuid,
      thumbnail: datastream.thumbnail,
      filesize: datastream.filesize,
      mimeType: datastream.mimeType,
    }));
  }, [contentsDatastream]);

  const listColumns = [
    {name: "Name", prop: "label"},
    {name: "Created At", prop: "createdAt"},
    {name: "Creator", prop: "creator"},
    {name: "Total files", prop: "totalFiles"},
  ];

  const listDatastreamColumns = [
    {name: "Name", prop: "label"},
    {name: "Created At", prop: "createdAt"},
    {name: "Creator", prop: "creator"},
    {name: "File size", prop: "fileSize"},
  ];

  const handleMore = () => {
    if (isContainer === true) setPage(page + 1);
    else setPageDatastream(pageDatastream + 1);
  };

  const history = useHistory();
  const handleFileClick = (file) => {    
     history.push(file.route);
  };

  const handleContainerClick = (container) => {
    history.push({
      pathname: '/containers/' + container.uuid,
    });
  };
  
  const handleContextClick = (e, container) => {
    setSelectedContainers((prevState) => {
      const exist = prevState.includes(container.uuid);
      if (prevState.length > 1 && exist) {
        return [
          ...prevState.filter((x) => x !== container.uuid),
          container.uuid,
        ];
      } else {
        return [container.uuid];
      }
    });
  };

  const handleContainerSelect = (e, container) => {
    const multi = e.metaKey || e.ctrlKey;
    setSelectedContainers((prevState) => {
      return !multi
        ? [container.uuid]
        : prevState.includes(container.uuid)
          ? prevState.filter((x) => x !== container.uuid)
          : [...prevState, container.uuid];
    });
  };  
  const isSelected = (id) => selectedContainers.includes(id);

  const handleEditClick = (container) => {
    setEntity({...container,id: container.uuid});
    const parent = { ...selectedContainer.parents }
    if (parent[0]) { setParentContainer(parent[0]); }
    else { setParentContainer({}) }    
    setOpen(true);
  };

  const handleReportClick = (container) => {
    setEntity({...container,id: container.uuid});
    setOpenReport(true);
  };   

  const handlePrintPreviewClick = (container) => {
    setEntity({...container,id: container.uuid});
    setOpenPrintPreview(true);
  }
  const handleRedirect = () => {
    if (thisContainer.uuid === selectedContainer.uuid) {
      try {
        const parentId =
          thisContainer.paths[0].nodes[thisContainer.paths[0].nodes.length - 1]
            .uuid;
        props.history.push(`/containers/${parentId}`);
      } catch (e) {
        console.log('Error: Parent not found');
        props.history.push('/containers');
      }
    }
  };

  const handleDeleteContainers = () => {
    const array = [selectedContainer]
    const requests = array.map((container) => {
      //const url = isRoot ? ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove` : ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove?from=${parentContainer.uuid}`;
      const url =
        (!!container.paths && container.paths.length === 0)
          ? ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove`
          : !!container.paths && container.paths.length > 0
          ? ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove?from=${container.paths[0].nodes[container.paths[0].nodes.length - 1].uuid}`
          : ApiEndpoint[EntityType.Container] + `/${container.uuid}/remove?from=${selectedContainer.parents[0].uuid}`;
      return axios.post(url, null, getAxiosDefaultConfig());
    });

    setIsLoadings(true);
    Promise.all(requests)
      .then(() => {
        setIsLoadings(false);
         window.location.reload();
      })
      .catch((ex) => {
        console.log(error)
        setIsLoadings(false);
      });
  };

  const handleLinkClick = (container) => {
    const parent = {...selectedContainer.parents}
    if (parent[0]) { setParentContainer(parent[0]); }
    else { setParentContainer({}) }
    toggleLinkContainer()
  };   

  const handleMoveClick = (container) => {
    const parent = {...selectedContainer.parents}
    if (parent[0]) { setParentContainer(parent[0]); }
    else { setParentContainer({}) }
    toggleMoveContainer()
  };  

  const importCallback = () => {

      getContainer(selectedContainer.uuid);

  };  

  const handleContainersExport = async (mode, callback) => {
    const url = ApiEndpoint[EntityType.Export];
    const requests = [selectedContainer].map((container) => {
     // const data = {uuid: container.uuid, mode: mode};
      const data = { ...mode, containers: [container.uuid] }
      return exportFile(url, data, container.label);
    });

    setIsLoadings(true);

    if (selectedContainers.length > 0) {
      // Export selected containers
      Promise.all(requests)
        .then(() => {
          setIsLoadings(false);
          callback(null, {});
        })
        .catch((ex) => {
          setIsLoadings(false);
          callback(ex, null);
        });
    } else {
      try {
        // Export root directory
        await exportFile(url, {mode}, 'root');
        setIsLoadings(false);
      } catch (e) {
        callback(e);
        setIsLoadings(false);
      }
    }
  };  

  const handleRename = (label,currentUuid) =>{
    const payload = {
      label:label
    }
    axios.put(`${API_URL}/containers/${currentUuid}/label`,payload,getAxiosDefaultConfig()).then((res)=>{
      setOpenRename(false)
      getContainer(selectedContainer.uuid)
      window.location.reload();
    })
  }

  const handleAddColor = (color) =>{
    const payload = {
      color:color
    }
    axios.put(`${API_URL}/containers/${selectedContainer.uuid}/color`,payload,getAxiosDefaultConfig()).then((res)=>{
      getContainer(selectedContainer.uuid)
      setOpenRename(false)
      window.location.reload();
    })
  }  

  const handleStarContainers = (starred) => {
    const url = ApiEndpoint[EntityType.Tag] + '/starred';

    const payloads = [selectedContainer]
      .filter((x) => x.starred !== starred)
      .map((container) => ({
        starred: starred,
        objectId: container.uuid,
        objectType: 'CONTAINER',
      }));

    const requests = payloads.map((payload) =>
      axios.post(url, payload, getAxiosDefaultConfig())
    );

    setIsLoadings(true);
    Promise.all(requests)
      .then(() => {
        setIsLoadings(false);  
        window.location.reload();       
      })
      .catch((ex) => {
        console.log(error);
        setIsLoadings(false);
      });
  };
  
  const handleCreate = () => {
    setEntity(Schema[entityType]);
    setOpen(true);
  };

  const handlePageClick = useCallback(() => {
    setSelectedContainer(selectedContainer || null);
  }, [selectedContainer]);

  useEffect(() =>
  {
    if (entity.uuid === undefined)
    {
      window.location.reload()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleFileEdit = (file) => {
    getDataStream(file.uuid)
    setEditDataStream(true)
  };
  const handleFileRenameOpen = (file) =>{
    setSelectedDatastream(file)
    setOpenFileRename(true)
  }

  const handleFileRename = (label,uuid) =>{
    const payload = {
      label:label
    }
    axios.put(`${API_URL}/datastreams/${uuid}/label`,payload,getAxiosDefaultConfig()).then((res)=>{
      getContainer(thisContainer.uuid)
      setOpenFileRename(false)
    })
  }
  
  const handleDeleteFile = (file) => {
    getDataStream(file.uuid)
    setDeleteFile(true)
  };

  const handleMoveFile = (file) => {
    const parent = { ...file.membership }
    if (parent[0]) { setParentContainer(parent[0]); }
    else { setParentContainer({}) }
    setSelectedDatastream(file)
    toggleMoveFile()
  };

  const handleLinkFile = (file) => {
    const parent = { ...file.membership }
    if (parent[0]) { setParentContainer(parent[0]); }
    else { setParentContainer({}) }
    setSelectedDatastream(file)
    toggleLinkFile()
  };

  const handleFileSelect = (e,file) => {
    const parent = { ...file.membership }
    if (parent) { setParentContainer(parent[0]); }
    else { setParentContainer({}) }
    setSelectedDatastream(file)
  }

  useEffect(() => {
    setSelectTypeToOpen(null);
    if (resultDataStream && resultDataStream.uuid) {
      if(editDataStream)
      {
        clearDataStreams()
        setEntityEntityDatastream(resultDataStream)
        setOpenDatastreamModal(!openDatastreamModal)
        setEditDataStream(!editDataStream) //this state is for direct edit through ContextMenu
      }
      else if (deleteFile)
      {
        setEntityEntityDatastream(resultDataStream)
        setShowDeleteDataStreamModal(!showDeleteDataStreamModal)
        setDeleteFile(!deleteFile) //this state is for direct delete through ContextMenu
      }
    }
    // eslint-disable-next-line
  }, [resultDataStream, getDataStream])  

  return (
    <React.Fragment>
      <MandatoryContext.Provider value={{mandatoryNotice, setMandatoryNotice}}>        
    <BaseContent
      breadcrumbs={breadcrumbs}
      isLoading={isLoadingData}
      entityType={entityType}            
      handleCreate={handleCreate}
      renderActions={() => {
        return (
          <>
            <button type="button" onClick={() => setIsContainer(true)} className="btn font-size-18" data-toggle="fullscreen">
              <div className={`font-size-11 ${isContainer ? "recent-selected-filter" : "text-muted"}`}>Containers</div>
            </button>
            <button type="button" onClick={() => setIsContainer(false)} className="btn font-size-18" data-toggle="fullscreen">
              <div className={`font-size-11 ${!isContainer ? "recent-selected-filter" : "text-muted"}`}>Datastreams</div>
            </button>
            <ListToggle isList={isList} toggle={toggleList} />
          </>
        );
      }}
    >
      {!isList && isContainer &&
        <RecentCard
        settings={containerData}
        handleContextClick={handleContextClick}
        handleContainerSelect={handleContainerSelect}        
        isSelected={isSelected}
        handleEditClick={handleEditClick}
        selectedContainer={selectedContainer}
        setSelectedContainer={setSelectedContainer}
        handleStarContainers={handleStarContainers}
        handleReportClick={handleReportClick}
        handlePrintPreviewClick={handlePrintPreviewClick}
        handleDeleteContainers={handleDeleteContainers}
        toggleDelete={toggleDelete}
        handleLinkClick={handleLinkClick}
        handleMoveClick={handleMoveClick}    
        openImport={() => setOpenImport(true)}
        openExport={() => setOpenExport(true)}    
        openRename={() => setOpenRename(true)}
        handleRename={handleRename}
        handleAddColor={handleAddColor}  
        setEntity={setEntity}           
        handleContainerClick={handleContainerClick}            
        />
      }
      {!isList && !isContainer && (
        <Row>
          <DataStreamList
            showList={isList}
            files={datastreamData}
            handleFileSelect={handleFileSelect}
            handleFileClick={handleFileClick}
            handleFileEdit={handleFileEdit}
            //handleFileRename={handleFileRename}
            handleDeleteFile={handleDeleteFile}
            handleMoveFile={handleMoveFile}
            handleLinkFile={handleLinkFile}
            handleFileRename={handleFileRenameOpen}
            settings={datastreamList}
            recent={true}
            selectedDatastream={selectedDatastream}                
          />
        </Row>
      )}      
      {isList && <ListSettings contents={isContainer ? containerList : datastreamList} columns={isContainer ? listColumns : listDatastreamColumns} />}
      <MoreButton isLoading={isLoadingData || isLoadingDatastream} hasMore={isContainer ? !data.last : !dataDatastream.last} handleMore={handleMore} />
        {open && <EntityDialog
          entity={entity}
          entityType={entityType}
          open={open}
          handleClose={() => {
            setOpen(false);
            setToolbarAction(false);
            setEntity({});
          }}
          createEntity={createContainer}
          updateEntity={updateContainer}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          containerTypes={containerTypes}
          context={context.Container}
          rootContext={context}
          parentUuid={parentContainer.uuid}
          handleRedirect={handleRedirect}
          qualifiers={qualifiers}
          propertyGroups={propertyGroups}       
          thisContainer={selectedContainer}
          toolbarAction={toolbarAction}             
          setEntity={setEntity}
          handlePageClick={handlePageClick}            
        />}  
        <DataStreamEntityDialog
          entity={entityDatastream}
          currentContainer={parentContainer.uuid}
          entityType={entityTypeDataStream}
          open={openDatastreamModal}
          handleClose={() => {
            getContainer(parentContainer.uuid);
            setOpenDatastreamModal(false);
          }}
          context={context.DataStream}
          deletedEntity={() => {
            deleteDataStream && getContainer(parentContainer.uuid);
          }}
          updateEntity={updateDataStream}
          showDeleteModal={showDeleteDataStreamModal}
          setShowDeleteModal={setShowDeleteDataStreamModal}
          dataStreamTypes={dataStreamTypes}
          propertyGroups={propertyGroups}
        />          
        <RenameDialog context={context} handleClose={() => setOpenRename(false)} open={openRename} handleRename={handleRename} containerUuid={selectedContainer.uuid} currentContainer={selectedContainer} />
        <ImportDialog open={openImport} handleClose={() => setOpenImport(false)} callback={importCallback} containerUuid={selectedContainer.uuid} />
        <ExportDialog open={openExport} handleClose={() => setOpenExport(false)} handleContainersExport={handleContainersExport} />      
        <MoveDialog
          open={showMoveContainer}
          handleClose={() => {
            toggleMoveContainer();
            getContainer(selectedContainer.uuid);
          }}
          selectedContainer={selectedContainer}
          parentContainer={parentContainer}
        />      
        <LinkDialog
          open={showLinkContainer}
          handleClose={() => {
            toggleLinkContainer();
            getContainer(selectedContainer.uuid);
          }}
          selectedContainer={selectedContainer}
          parentContainer={parentContainer}
        />      
      <DeleteDialog handleClose={toggleDelete} open={showDelete} handleDelete={handleDeleteContainers} />      
      <ReportDialog open={openReport} handleClose={() => setOpenReport(false)} uuid={selectedContainer.uuid} /> 
      <RenameDatastreamDialog handleClose={() => setOpenFileRename(false)} open={openFileRename} handleRename={handleFileRename} context={context} datastreamUuid={selectedDatastream?.uuid} />
      <MoveFileDialog
        handleClose={() => {
          toggleMoveFile();
          getContainer(parentContainer.uuid);
        }}
        open={showMoveFile}
        selectedDatastream={selectedDatastream}
        parentContainer={parentContainer}
      />
      <LinkFileDialog
        handleClose={() => {
          toggleLinkFile();
          getContainer(parentContainer.uuid);
        }}
        open={showLinkFile}
        selectedDatastream={selectedDatastream}
        parentContainer={parentContainer}
      />          
      <PrintPreviewDialog handleClose={() => setOpenPrintPreview(false)} open={openPrintPreview} selectedContainer={selectedContainer} properties={properties} />      
          {isLoadings && (
        <span style={{fontSize: "25px", position: "absolute", width: "25px", height: "25px", right: "30px", textAlign: "center", bottom: "55px"}}>
          <i className="bx bx-loader bx-spin font-size-30 align-middle"></i>
        </span>
          )}    
    </BaseContent>
      </MandatoryContext.Provider>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.Tag.getAll.result,
    context: state,
  };
};

export default connect(mapStateToProps, {
  getContainer,
  clearContainer,
  createContainer,
  updateContainer,
  deleteContainer,
  clearContainers,
  clearDataStreams,
  getDataStream,
  updateDataStream,
  deleteDataStream,
})(StarredContainers);
