import React, {useEffect, useState} from 'react';
import ErrorBoundary from '../../components/Common/ErrorBoundary';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import {Button} from 'reactstrap';
import ReactPlayer from 'react-player';
import DocViewer, {DocViewerRenderers, MSDocRenderer, TXTRenderer } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
import ReactJson from 'react-json-view';



const FilePreview = ({fileBase64, fileType, isLoading, filename, selectTypeToOpen, result, setOpenWith}) => {
  const isImage = fileType && fileType.includes("image");
  const isPDF = fileType && fileType.includes("pdf");
  const isVideo = fileType && fileType.includes("video");
  const isDoc = fileType?.includes("msword");
  const isDocument = fileType && (fileType?.includes("document") || fileType?.includes("ms-excel"));
  const isTXT = fileType && fileType?.includes("text");
  const isGLTF = fileType && (filename?.includes("gltf") || filename?.includes("glb"));
  const isOBJ = fileType && !filename?.includes("gltf") && !filename?.includes("glb");
  const isJSON = fileType && fileType?.includes("json");

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  
  useEffect(() => {
    if (fileType && !fileType.includes("octet"))
    {
      setOpenWith(false);      
    }else if (fileType && fileType.includes("octet") && selectTypeToOpen === null) {
      setOpenWith(true);
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileType]);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const docs = [
    {
      uri: fileBase64,
    },
  ];

  const modelPath = fileBase64;
  
  const file = fileBase64;
  
  let type;
  if (fileType?.includes("document")) {
    type = "docx";
  } else if (fileType?.includes("ms-excel")) {
    type = "xlsx";
  } else if (fileType?.includes("text")) {
    type = "txt";
  } 

  if (isLoading) return <div className="text-white font-size-15 text-center mt-5">Loading...</div>;

  return (
    <ErrorBoundary>
      <div style={{height: "100%"}} onClick={(e) => e.stopPropagation()}>
        {fileBase64 && !isLoading && fileType && (
          <>
            <div
              style={{
                border: 0,
                overflowY: "auto",
                overflowX: "hidden",
                minWidth: "900px",
                minHeight: "800px",
                height: "auto",
                width: "auto",
                textAlign: "center",
                color: "#000",
              }}
            >
              {isImage && (
                <div style={{maxWidth: "80vw", maxHeight: "90vh"}}>
                  <img src={fileBase64} title={"preview"} height={"100%"} width={"90%"} alt={"file preview"} />
                </div>
              )}
              {isPDF && (
                <Container className="align-items-center align-content-center justify-content-center">
                  <Button type="button" color="light" disabled={pageNumber <= 1} onClick={previousPage} style={{position: "absolute", top: "300px", left: 0}}>
                    Previous
                  </Button>
                  <Row>
                    <Col>
                      <div className="text-center text-white">
                        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                      </div>
                    </Col>
                  </Row>
                  <Row className="flex-center flex-fill">
                    <Col className="col-auto" style={{margin: "0 auto"}}>
                      <Document file={fileBase64} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
                      </Document>
                    </Col>
                  </Row>
                  <Button type="button" color="light" style={{position: "absolute", top: "300px", right: 0}} disabled={pageNumber >= numPages} onClick={nextPage}>
                    Next
                  </Button>
                </Container>
              )}
              {isTXT && (
                <div style={{color: "white", fontSize: 18}}>
                  <p>This file format is currently not supported.</p>
                </div>
              )}
              {isVideo && (
                <Col className="col-lg-auto">
                  <ReactPlayer url={fileBase64} controls={true} width={"100%"} height={500} playing />
                </Col>
              )}
              {isDocument && (
                <div style={{color: "#000", maxHeight: "90vh", minWidth: "900px", backgroundColor: "#fff", fontSize: 18}}>
                  <FileViewer key={file} fileType={type} filePath={file} style={{backgroundColor: "#ffffff", overflow: "none", color: "#000"}} />
                </div>
              )}
              {isDoc && (
                <div style={{color: "white", fontSize: 18}}>
                  <p>This file format is currently not supported.</p>
                </div>
              )}
              {isJSON && <div style={{ display: "block", textAlign: "center", backgroundColor: "#ffffff", minHeight: "125px", paddingTop: "50px" }}>
                <ReactJson src={fileBase64} />
              </div>
              }
              {selectTypeToOpen === "3d" && isGLTF && (
                <model-viewer
                  src={modelPath}
                  shadow-intensity="1"
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  camera-controls
                  alt="3D model"
                  background-color="#ffff00"
                  style={{backgroundColor: "#ffffff", width: "900px", height: "700px"}}
                ></model-viewer>
              )}
              {selectTypeToOpen === "3d" && isOBJ && (
                <div style={{display: "block", textAlign: "center", backgroundColor: "#ffffff", width: "900px", height: "125px", paddingTop: "50px"}}>
                  <p>This file format is currently not supported.</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default FilePreview;
