import React, {useEffect, useState} from 'react';
import BaseContent from '../../components/Common/BaseContent';
import axios from 'axios';
import {API_URL} from '../../config';
import {getAxiosDefaultConfig} from '../../utils';
import {Col, Container, Row} from 'reactstrap';
import PaginationComponent from 'react-reactstrap-pagination';
import {useLocation} from 'react-router';
import GuidesList from './components/GuidesList'
import GuidesFilter from './components/GuidesFilter'
import GuidesTableList from './components/GuidesTableList'
import ListToggle from '../../components/Common/ListToggle'
import {useListToggle} from '../../helpers/hooks'

const breadcrumbs = [{title: 'Guides', link: '/guides'}]
const listColumns = ['Name','Description','Tags','Aspect Ratio','Orientation','Updated at']


function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const GuideDirectory = (props) => {

  const {browser, selectStory, selectedStory} = props

  let query = useQuery()

  const pageVar = query.get('page')
  const [page, setPage] = useState(pageVar ? pageVar : 0)
  const [sortFilter, setSortFilter] = useState('title')
  const [sortBy, setSortBy] = useState('DESC')
  const [guides, setGuides] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [breadcrumbsStories, setBreadCrumbsStories] = useState(breadcrumbs)
  const [isList, toggleList] = useListToggle();

  const getAllGuides = (page = 0, searchQuery = '') => {
    setIsLoading(true)
    axios.get(`${API_URL}/guides?page=${page}&q=${encodeURIComponent(searchQuery)}&sort=${sortFilter},${sortBy}`, getAxiosDefaultConfig())
      .then((response) => {
        const {data} = response;
        setGuides(data);
        setBreadCrumbsStories([{
          title: `Guides(${data?.totalElements})`
        }])
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false);
      })
  }

  useEffect(() => {
    getAllGuides(page, '')
    // eslint-disable-next-line
  }, [page, sortBy, sortFilter])

  const handleSelectPage = (page) => {
    setPage(page - 1)
  }

  return (
    <BaseContent
      // isLoading={isLoading||!websites}
      breadcrumbs={breadcrumbsStories}
      page={!browser}
      busy={isLoading}
      renderActions={() => <ListToggle isList={isList} toggle={toggleList}/>}
    >
      <Container style={{paddingTop: 30, minHeight: '120px'}}>
        <GuidesFilter getAllStories={getAllGuides} sortBy={sortBy} setSortBy={setSortBy} sortFilter={sortFilter}
                       setSortFilter={setSortFilter}/>
      </Container>

      {guides && <Container style={{paddingTop: 30, minHeight: '55vh'}}>
        {!isList &&
          <GuidesList result={guides.content} isLoading={isLoading} browser={browser} selectedStory={selectedStory}
                       selectStory={selectStory}/>
        }

        {isList &&
          <GuidesTableList browser={browser} selectedStory={selectedStory} selectStory={selectStory}
                            columns={listColumns} result={guides.content} isLoading={isLoading} {...props}/>
        }

        <Row>
          <Col xs={{size: 6, offset: 6}} className='listing-navigation'>
            <PaginationComponent totalItems={guides.totalElements} pageSize={guides.pageable.pageSize}
                                 onSelect={handleSelectPage}/>
          </Col>
        </Row>

      </Container>}

    </BaseContent>
  )
}

export default GuideDirectory
