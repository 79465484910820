import React, {useEffect, useState} from "react";
import {Row, UncontrolledTooltip} from "reactstrap";
import {getIconForDatastream} from "../../utils";
import axios from "axios";
import {API_URL} from "../../config";
import {getAxiosDefaultConfig} from "../../utils";
// import {handleError} from "../../store/core/api";
// import moment from "moment";
import toastr from "toastr";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const SearchNavigation = (props) => {
  const [containerIndexes, setContainerIndexes] = useState([].sort((a, b) => a.label - b.label));
  const [datastreamIndexes, setDatastreamIndexes] = useState([]);
  const [parentIndex, setParentIndex] = useState([]);
  const [backIndex, setBackIndex] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);

  const [query, setQuery] = useState("");
  const [resultsContainer, setResultsContainer] = useState([]);
  const [resultsDatastream, setResultsDatastream] = useState([]);

  const getDatastreams = (id) => {
    axios
      .get(`${API_URL}/containers/${id}`, getAxiosDefaultConfig())
      .then((res) => {
        setDatastreamIndexes(res.data.datastreams);
      })
      .catch((e) => {
        setError(true);
        setIsLoading(false);
        toastr.error(e);
      });
  };

  //Set tree on first open
  useEffect(() => {
    setIsLoading(true);
    if (!!props.selectedContainer?.mimeType) {
      axios
        .get(`${API_URL}/containers/${props.parentContainer?.uuid}`, getAxiosDefaultConfig())
        .then((res) => {
          setContainerIndexes(res.data.children);
          setDatastreamIndexes(res.data.datastreams);
          setParentIndex(props.parentContainer);
          if (res.data.parents.length > 0) {
            setBackIndex(res.data.paths[0].nodes[res.data.paths[0].nodes.length - 1]);
          } else {
            setBackIndex({uuid: null, label: "ROOT"});
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setError(true);
          setIsLoading(false);
          toastr.error(e);
        });
    } else if (props.selectedContainer?.uuid !== undefined) {
      axios
        .get(`${API_URL}/containers/${props.selectedContainer?.uuid}`, getAxiosDefaultConfig())
        .then((res) => {
          setContainerIndexes(res.data.siblings);
          if (res.data.parents.length > 0) {
            setBackIndex(res.data.paths[0].nodes[res.data.paths[0].nodes.length - 2]);
            setParentIndex(res.data.parents[0]);

            if (res.data) getDatastreams(res.data.parents[0].uuid);
          } else {
            setBackIndex({uuid: null, label: "ROOT"});
            setParentIndex({uuid: null, label: "ROOT"});
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setError(true);
          setIsLoading(false);
          toastr.error(e);
        });
    } else {
      axios
        .get(`${API_URL}/containers`, getAxiosDefaultConfig())
        .then((res) => {
          setContainerIndexes(res.data);
          setBackIndex({uuid: null, label: "ROOT"});
          setParentIndex({uuid: null, label: "ROOT"});
          setIsLoading(false);
        })
        .catch((e) => {
          setError(true);
          setIsLoading(false);
          toastr.error(e);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Set selected destination from list
  const handleListItemClick = (event, index, item) => {
    setSelectedIndex(index);
    setSelectedDestination(item);
    props.setMoveDestination(item);
  };

  //Change level in container tree
  const handleChangeDestinationLevel = (item) => {
    setIsLoading(true);
    setDatastreamIndexes([]);
    axios
      .get(`${API_URL}/containers/${item.uuid}`, getAxiosDefaultConfig())
      .then((res) => {
        setContainerIndexes(res.data.children);
        if (res.data.parents.length > 0) {
          setBackIndex(res.data.parents[0]);
        } else {
          setBackIndex({uuid: null, label: "ROOT"});
        }
        setParentIndex(item);
        getDatastreams(item.uuid);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(true);
        setIsLoading(false);
        toastr.error(e);
      });
  };
  //Get back to previous tree level
  const handleBack = () => {
    setIsLoading(true);
    setSelectedIndex();
    setDatastreamIndexes([]);
    if (backIndex?.uuid === undefined || backIndex?.uuid === null) {
      axios
        .get(`${API_URL}/containers`, getAxiosDefaultConfig())
        .then((res) => {
          setContainerIndexes(res.data);
          setBackIndex({uuid: null, label: "ROOT"});
          setParentIndex({uuid: null, label: "ROOT"});
          setIsLoading(false);
        })
        .catch((e) => {
          setError(true);
          setIsLoading(false);
          toastr.error(e);
        });
    } else {
      axios
        .get(`${API_URL}/containers/${backIndex.uuid}`, getAxiosDefaultConfig())
        .then((res) => {
          setContainerIndexes(res.data.children);
          if (res.data.parents.length > 0) {
            setBackIndex(res.data.parents[0]);
            setParentIndex(backIndex);
            getDatastreams(backIndex.uuid);
          } else {
            setBackIndex({uuid: null, label: "ROOT"});
            setParentIndex(backIndex);
            getDatastreams(backIndex.uuid);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setError(true);
          setIsLoading(false);
          toastr.error(e);
        });
    }
  };

  const safeValue = (value) => (value ? value : "");
  useEffect(() => {
    const resultsContainer = containerIndexes.filter((x) => [x.label].find((value) => safeValue(value).toLowerCase().includes(query.toLowerCase())));
    const resultsDatastream = datastreamIndexes.filter((x) => [x.label].find((value) => safeValue(value).toLowerCase().includes(query.toLowerCase())));
    setResultsContainer(resultsContainer);
    setResultsDatastream(resultsDatastream);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerIndexes, datastreamIndexes, query]);

  return (
    <Box className="align-content-left">
      <Box
        style={{
          width: "100%",
          lineHeight: "30px",
          background: "rgb(245 245 245)",
          padding: "5px 0px",
          color: "#76767",
          border: "1px solid #cecece",
          fontWeight: "700",
          borderRadius: "0.25rem 0.25rem 0 0",
          borderBottomColor: "#ddd",
        }}
      >
        <IconButton
          aria-label="back"
          id={"back"}
          style={{
            margin: "0px 5px",
            padding: "0",
            display: "inline-block",
            borderRadius: "50%",
            width: "30px",
            textAlign: "center",
            fontSize: "17px",
            height: "30px",
            verticalAlign: "middle",
          }}
          onClick={handleBack}
        >
          <i className="bx bx-arrow-back" style={{verticalAlign: "middle", fontWeight: "700"}}></i>
        </IconButton>
        <UncontrolledTooltip target={"back"} placement="top">
          back
        </UncontrolledTooltip>{" "}
        <span
          id="parentContainer"
          style={{cursor: "pointer"}}
          onClick={(e) => {
            handleListItemClick(e, null, parentIndex);
          }}
        >
          {parentIndex.label ? (parentIndex.label.length > 50 ? parentIndex.label.substring(0, 50) + "..." : parentIndex.label) : "ROOT"}
        </span>
        <UncontrolledTooltip target={"parentContainer"} placement="top">
          Click to select container
        </UncontrolledTooltip>
      </Box>
      <Box style={{width: "100%", height: "300px", overflowY: "scroll", border: "1px solid #cecece", borderTop: "none", borderBottom: "none"}}>
        <form>
          <input type="text" style={{background: "rgb(246 246 247)", border: "none"}} className="form-control" value={query} placeholder={"Search container..."} onChange={(e) => setQuery(e.target.value)} />
        </form>
        <List component="nav" aria-label="container indexes" dense="true">
          {parentIndex?.uuid !== undefined && parentIndex?.uuid !== null && (
            <ListItem button onClick={handleBack}>
              <ListItemIcon style={{fontSize: "16px", minWidth: "30px"}}>
                <i className="bx bx-dots-horizontal"></i>
              </ListItemIcon>
            </ListItem>
          )}
          {resultsContainer &&
            resultsContainer.map((item, index) => {
              return (
                <ListItem key={index} button selected={selectedIndex === index} onClick={(event) => handleListItemClick(event, index, item)} onDoubleClick={(event) => handleChangeDestinationLevel(item)}>
                  <ListItemIcon style={{fontSize: "20px", minWidth: "30px", color: "#556ee6"}}>{selectedIndex === index ? <i className="bx bxs-folder-open"></i> : <i className="bx bxs-folder"></i>}</ListItemIcon>
                  <ListItemText primary={`${item.label.substring(0, 50)} ${item.label.length > 50 ? "..." : ""}`} />
                </ListItem>
              );
            })}
          {resultsDatastream &&
            resultsDatastream.map((item, index) => {
              return (
                <ListItem key={index} style={{opacity: "0.7", fontSize: "16px", minWidth: "30px"}}>
                  <ListItemIcon style={{fontSize: "20px", minWidth: "30px", color: "#556ee6"}}>
                    <i className={`bx ${getIconForDatastream(item.mimeType)}`} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{style: {fontSize: "14px"}}} primary={`${item.label.substring(0, 50)} ${item.label.length > 50 ? "..." : ""}`} />
                </ListItem>
              );
            })}
        </List>
      </Box>
      {isLoading && (
        <span style={{fontSize: "25px", position: "absolute", width: "25px", height: "25px", right: "30px", textAlign: "center", bottom: "55px"}}>
          <i className="bx bx-loader bx-spin font-size-30 align-middle"></i>
        </span>
      )}
      <Box
        style={{
          width: "100%",
          lineHeight: "30px",
          background: "rgb(245 245 245)",
          padding: "5px 15px",
          color: "#76767",
          border: "1px solid #cecece",
          borderRadius: "0 0 0.25rem 0.25rem",
          fontWeight: "700",
          borderTopColor: "#ddd",
        }}
      >
        To : {selectedDestination?.label?.length > 50 ? selectedDestination?.label.substring(0, 50) + "..." : selectedDestination?.label}
      </Box>
    </Box>
  );
};

export default SearchNavigation;
