import React, {useCallback, useEffect, useState} from 'react'

import {Col, Row} from 'reactstrap'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import toastr from 'toastr'
import PaginationComponent from 'react-reactstrap-pagination'
import {API_URL} from "../../../config";
import {formatBytes, getAxiosDefaultConfig} from "../../../utils";
import {ApiEndpoint} from "../../../store/core/endpoint";
import {EntityType} from "../../../store/core/entityType";
import GuideAssetThumb from "../GuideAssetThumb";
import BaseDialog from "../../../components/Common/BaseDialog";
import Loader from "../../Story/Viewer/components/Loader";

const GuideAssetsDialog = (props) => {
  const {open, handleClose,handleChange} = props
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeTab, setActiveTab] = useState(0)
  const [, setBusy] = useState(false);
  const [selected, setSelected] = useState('')
  const [assetFrom,setAssetFrom] = useState('')
  const [repoxStorePage,setRepoxStorePage] = useState(0)
  const [mediaAssets,setMediaAssets] = useState([])
  const [storiesPage,setStoriesPage] = useState(0)
  const [uploadFileLoading,setUploadFileLoading] = useState(false)


  useEffect(() => {
    if (open) {
      axios.get(`${API_URL}/assets?page=${storiesPage}`, getAxiosDefaultConfig())
        .then((response) => {
          setMediaAssets(response.data)
        })
        .catch((e) => {
          toastr.error(e)
        });
    }
    //eslint-disable-next-line
  }, [open,storiesPage])

  useEffect(()=>{
    if (open)
    {
      setSelected('')
    }
    return ()=>{
      setStoriesPage(0)
      setRepoxStorePage(0)
    }
    //eslint-disable-next-line
  },[open])


  const handleAcceptedFiles = useCallback((files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
  }, []);

  const fileUpload = async () => {
    const url = `${ApiEndpoint[EntityType.Assets]}/upload`;
    const axiosConfig = getAxiosDefaultConfig();
    const formData = new FormData();
    setUploadFileLoading(true)
    const data = {type: selectedFiles[0].type, label: selectedFiles[0].name, properties: []};
    formData.append("file", selectedFiles[0]);
    formData.append(
      "data",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    try {
      setBusy(true);
      await axios.post(url, formData, axiosConfig);
      setUploadFileLoading(false)
      setBusy(false);
      setActiveTab(1)
      setStoriesPage(mediaAssets.totalPages - 1)
    } catch (e) {
      toastr.error(e)
      setUploadFileLoading(false)
      setBusy(false);
    }
  };

  useEffect(() => {
    selectedFiles.length > 0 && fileUpload()
    // eslint-disable-next-line
  }, [selectedFiles])

  const loadNextRepoxAssets = (page) =>{
    setRepoxStorePage(page - 1)
  }

  const loadNextStoriesAssets = (page) =>{
    setStoriesPage(page - 1)
  }

  const renderStepTwo = () => (
    <>
      <Row>
        {mediaAssets?.content?.map((asset) => {
          return <GuideAssetThumb fileName={asset.label} key={`store-${asset.uuid}`} from={'stories'} asset={asset} selected={selected} setSelected={setSelected} setAssetFrom={setAssetFrom}/>
        })}
      </Row>
      <Row>
        <PaginationComponent totalItems={mediaAssets?.totalElements} pageSize={mediaAssets?.pageable?.pageSize} defaultActivePage={storiesPage+1} onSelect={loadNextStoriesAssets} />
      </Row>
    </>
  )

  const renterStepThree = () => (
    <Row>
      <Col lg={12}>
        {!uploadFileLoading &&
          <Dropzone
            accept={".png, .jpeg, .jpg, .gif, .bmp"}
            multiple={false}
            onDrop={handleAcceptedFiles}
          >
            {({getRootProps, getInputProps}) => (
              <div className="dropzone">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                  </div>
                  <h3>Drop files here or click to upload</h3>
                  <h6>Select a file from your computer</h6>
                </div>
              </div>
            )}
          </Dropzone>
        }
        {uploadFileLoading &&
          <Row>
            <Col lg={12}>
              <Loader/>
            </Col>
            <Col lg={12} className='mt-2 mb-2 text-muted font-size-24 font-weight-bold' style={{textAlign:'center'}}>
              Uploading file...
            </Col>
          </Row>
        }
      </Col>
    </Row>
  )

  const contents = [renderStepTwo(), renterStepThree()];

  return <BaseDialog
    scrollable={true}
    extraClassName={"modal-full-height"}
    open={open}
    handleClose={() => {
      handleClose()
      setSelected('')
    }
    }
    size={'lg'}
    primaryAction={() => {
      handleChange(selected,assetFrom)
      handleClose()
    }}
    primaryActionTitle={'Import asset'}
    primaryActionDisable={selected === ''}
    changeTab={activeTab}
    tabs={[
      {title: 'Media Content'},
      {title: 'Upload an image'},
    ]}
    contents={contents}
  />

}

export default GuideAssetsDialog
