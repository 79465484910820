import React, {useEffect, useMemo, useState, useRef} from 'react';
import EntitySidebar from '../../components/Common/entity-sidebar';
import {
  clearContainer,
  clearContainers,
  createContainer,
  deleteContainer,
  getContainer,
  updateContainer,
} from '../../store/container/actions';
import {connect} from 'react-redux';
import BaseContent from '../../components/Common/BaseContent';
import Toolbar from '../../components/Common/Toolbar';
import {EntityDialog} from "./EntityDialog";
import {EntityType} from '../../store/core/entityType';
import {Schema} from '../../store/core/schema';
import {
  useContainerActions,
  useContainers,
  useListToggle,
  useToggle,
} from '../../helpers/hooks';
import CreateDataStream from '../DataStreams/create-dataset';
import {ObjectType, ViewLayout} from '../../constants/enums';
import {toggleRightSidebar} from '../../store/layout/actions';
import ContainerRecordLayout from './container-record-layout';
import ContainerDefaultLayout from './container-default-layout';
import ContainerCataloguingLayout from "./container-cataloguing-layout";
import {createMap, getAxiosDefaultConfig} from '../../utils';
import DataStreamPortal from '../DataStreams/datastream-portal';
import ImportDialog from './import-dialog';
import ExportDialog from './ExportDialog';
import DeleteDialog from './DeleteDialog';
import RenameDialog from './rename-dialog';
import MoveDialog from "./MoveDialog";
import LinkDialog from "./LinkDialog";
import PrintPreviewDialog from "./PrintPreviewDialog";
import DataStreamEntityDialog from '../DataStreams/EntityDialog'
import MoveFileDialog from '../DataStreams/move-datastream'
import LinkFileDialog from '../DataStreams/link-datastream'
import {getDataStream, deleteDataStream, updateDataStream,clearDataStreams} from '../../store/dataStream/actions'
import {MandatoryContext} from './MandatoryContext'
import axios from 'axios'
import {API_URL} from '../../config'
import RenameDatastreamDialog from '../DataStreams/rename-dialog'
import ReportDialog from './report-dialog';
import { useReactToPrint } from "react-to-print";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col} from "reactstrap";
import {FormControlLabel, FormControl, RadioGroup, Radio, FormLabel} from "@material-ui/core";


function getLayout(type) {
  if (!type) return ContainerDefaultLayout;
  switch (type.layout) {
    case ViewLayout.record:
      return ContainerRecordLayout;
    case ViewLayout.cataloguing:
      return ContainerCataloguingLayout;
    default:
      return ContainerDefaultLayout;
  }
}

const entityType = EntityType.Container;
const entityTypeDataStream = EntityType.DataStream;

function ContainerOverview(props) {
  const {
    context,
    getContainer,
    clearContainer,
    createContainer,
    updateContainer,
    deleteContainer,
    clearContainers,
    toggleRightSidebar,
    clearDataStreams,
    getDataStream,
    deleteDataStream,
    updateDataStream
  } = props;
  const uuid = props.match.params.id;
  const {result,error, isLoading} = props.context.Container.get;
  const {result: resultDataStream} = props.context.DataStream.get
  const propertyGroups = props.context.PropertyGroup.getAll.result;
  const containerGetter = props.context.Container.get;
  const {showRightSidebar} = props.context.Layout;
  const containerTypes = props.context.ContainerType.getAll.result;
  const properties = props.context.Property.getAll.result;
  const qualifiers = props.context.Qualifier.getAll.result;
  const thisContainer = props.context.Container.get.result;
  const children = props.context.Container.getAll.result;
  const dataStreamTypes = props.context.DataStreamType.getAll.result;
  const dataStreams = result.datastreams || [];
  const [entity, setEntity] = useState(Schema[entityType]);
  const [entityDatastream, setEntityEntityDatastream] = useState(Schema[entityTypeDataStream]);
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteDataStreamModal, setShowDeleteDataStreamModal] = useState(false);
  const [openDatastreamModal, setOpenDatastreamModal] = useState(false);
  const [openDatasetModal, toggleDatasetModal] = useToggle();
  const [, togglePreview] = useToggle();
  const [, setCurrentFile] = useState({});
  const [openImport, setOpenImport] = useState(false);
  const [editDataStream,setEditDataStream] = useState(false)
  const [deleteFile,setDeleteFile] = useState(false)
  const [openExport, setOpenExport] = useState(false);
  const [showList, toggleListLayout] = useListToggle();
  const [showDelete, toggleDelete] = useToggle(false);
  const [showMoveFile, toggleMoveFile] = useToggle(false);
  const [showLinkFile, toggleLinkFile] = useToggle(false);
  const [showMoveContainer, toggleMoveContainer] = useToggle(false);
  const [showLinkContainer, toggleLinkContainer] = useToggle(false);  
  const [selectedDatastream,setSelectedDatastream] = useState({})
  const [parentContainer,setParentContainer] = useState({})
  const [mandatoryNotice,setMandatoryNotice] = useState(false)
  const [openRename,setOpenRename] = useState(false)
  const [openFileRename, setOpenFileRename] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [actionFromBreacrumb, setActionFromBreacrumb] = useState(false)
  const [toolbarAction, setToolbarAction] = useState(false)
  const [openPrintPreview, setOpenPrintPreview] = useState(false)
  const [openWith, setOpenWith] = useState(false);
  const [selectTypeToOpen, setSelectTypeToOpen] = useState(null);

  const dataStreamUuid = useMemo(() => {
    const urlParams = new URLSearchParams(props.location.search);
    return urlParams.get('datastream');
  }, [props.location.search]);

  const handleDismissDataStream = () => {
    props.history.push(`/containers/${result.uuid}`);
  };

  useEffect(() => {
    setSelectTypeToOpen(null);
    if (resultDataStream && resultDataStream.uuid) {
      if(editDataStream)
      {
        clearDataStreams()
        setEntityEntityDatastream(resultDataStream)
        setOpenDatastreamModal(!openDatastreamModal)
        setEditDataStream(!editDataStream) //this state is for direct edit through ContextMenu
      }
      else if (deleteFile)
      {
        setEntityEntityDatastream(resultDataStream)
        setShowDeleteDataStreamModal(!showDeleteDataStreamModal)
        setDeleteFile(!deleteFile) //this state is for direct delete through ContextMenu
      }
    }
    // eslint-disable-next-line
  }, [resultDataStream,getDataStream])

  const {
    selectedContainer,
    selectedContainers,
    currentContainer,
    busy,
    handleContainerSelect,
    handlePageClick,
    handleContainerClick,
    tags,
    isSelected,
    handleContextClick,
  } = useContainers({
    history: props.history,
    thisContainerUuid: uuid,
    setEntity,
    showList,
    containerGetter
  });

  const handleRename = (label,currentUuid) =>{
    const payload = {
      label:label
    }
    axios.put(`${API_URL}/containers/${currentUuid}/label`,payload,getAxiosDefaultConfig()).then((res)=>{
      setOpenRename(false)
      getContainer(uuid)
    })
  }

  const fullSelectedContainersObject = useMemo(() => {
    if (
      selectedContainers.length === 1 &&
      selectedContainers[0] === result.uuid
    ) {
      return [result];
    } else {
      return children.filter((container) =>
        selectedContainers.includes(container.uuid)
      );
    }
  }, [children, result, selectedContainers]);

  const {
    handleStarContainers,
    handleDeleteContainers,
    handleContainersExport
  } = useContainerActions(result,fullSelectedContainersObject, () =>
    getContainer(uuid)
  );

  //TODO: to be rechecked
  // React.useEffect(() => {
  //   if (currentContainer && currentContainer.id) {
  //     setEntity(currentContainer);
  //   }
  // }, [currentContainer]);

  const handleEditClick = (container) => {
    setEntity(container);
    setOpen(true);
  };
  const handleReportClick = (container) => {
    setEntity(container);
    setOpenReport(true);
  };  

 

  const handlePrintPreviewClick = (container) => {
    setEntity(container);
    setOpenPrintPreview(true);
  }
  const handleAddColor = (color) =>{
    const payload = {
      color:color
    }
    axios.put(`${API_URL}/containers/${selectedContainer}/color`,payload,getAxiosDefaultConfig()).then((res)=>{
      getContainer(uuid)
      setOpenRename(false)
    })
  }

  const handleToolbarEdit = () => {
    handleEditClick(currentContainer);
    setToolbarAction(true)
  };

  const handleCreate = () => {
    setEntity(Schema[entityType]);
    setOpen(true);
  };

  useEffect(() => {
    clearContainer();
    getContainer(uuid);
    return () => {
      clearContainer();
      clearContainers();
    };
  }, [uuid, getContainer, clearContainer, clearContainers]);

  const contentClass = showRightSidebar
    ? 'content-with-sidebar'
    : 'content-with-no-sidebar';

  const paths = [
    {title: 'Content', link: '/containers'},
    ...(thisContainer.paths && thisContainer.paths[0]
      ? thisContainer.paths[0].nodes.map((parent) => ({
        title: parent.label,
        link: `/containers/${parent.uuid}`,
      }))
      : []),
    {
      title: `${thisContainer.label || ''}`,
      link: '#',
      icon:
        result.published &&
        'font-weight-bold bx bxs-check-circle text-success mr-1',
    },
  ];

  const handleFileClick = (file) => {
    togglePreview();
    setCurrentFile(file);
    props.history.push(`/containers/${ result.uuid }?datastream=${ file.uuid }`);
    if (file?.mimeType?.includes("octet")) {
      setOpenWith(true);
    }
  };

  const handleFileEdit = (file) => {
    getDataStream(file.uuid)
    setEditDataStream(true)
  };

  const handleMoveFile = (file) => {
    setParentContainer(currentContainer)
    setSelectedDatastream(file)
    toggleMoveFile()
  };

  const handleLinkFile = (file) => {
    setParentContainer(currentContainer)
    setSelectedDatastream(file)
    toggleLinkFile()
  };

  const handleMoveClick = (container) => {
    //setEntity(container);
    setParentContainer(currentContainer);
    toggleMoveContainer()
    //setOpenMove(true);
  };

  const handleMoveClickBreadcrumb = (container) => {
    //setEntity(container);
     setActionFromBreacrumb(true)
     setParentContainer(currentContainer);
     toggleMoveContainer();
    //setOpenMove(true);
  };

  const handleLinkClick = (container) => {
    //setEntity(container);
    setParentContainer(currentContainer);
    toggleLinkContainer()
   // setOpenLink(true);
  };   
 
  const handleLinkClickBreadcrumb = (container) => {
    //setEntity(container);
    setActionFromBreacrumb(true);
    setParentContainer(currentContainer);
    toggleLinkContainer();
    // setOpenLink(true);
  }; 
 
  const handleRenameBreadcrumb = (container) => {
    setOpenRename(true)
  }

  const handleDeleteClickBreadcrumb = () => {
    toggleDelete();
     setParentContainer(currentContainer);
    // props.handleDelete(container    
  };  

  const handleDeleteFile = (file) => {
    getDataStream(file.uuid)
    setDeleteFile(true)
  };

  const handleFileRenameOpen = (file) =>{
    setSelectedDatastream(file)
    setOpenFileRename(true)
  }

  const handleFileRename = (label,uuid) =>{
    const payload = {
      label:label
    }
    axios.put(`${API_URL}/datastreams/${uuid}/label`,payload,getAxiosDefaultConfig()).then((res)=>{
      getContainer(thisContainer.uuid)
      setOpenFileRename(false)
    })
  }
  
  // const handleGeneralContextMenu = (e) =>{
  //   e.preventDefault()
  // }

  const handleRedirect = () => {
    if (thisContainer.uuid === uuid) {
      try {
        const parentId =
          thisContainer.paths[0].nodes[thisContainer.paths[0].nodes.length - 1]
            .uuid;
        props.history.push(`/containers/${parentId}`);
      } catch (e) {
        console.log('Error: Parent not found');
        props.history.push('/containers');
      }
    }
  };


  const entityActions = [
    {title: "Create Container", action: handleCreate, icon: "bx-folder-plus"},
    {
      title: "Upload datastream",
      action: toggleDatasetModal,
      icon: "bx-cloud-upload",
    },
    {
      title: "divider",
      action: null,
      icon: null,
    },
    {
      title: "Edit",
      action: () => handleToolbarEdit(),
      icon: "bx-edit",
    },
    {
      title: !currentContainer.starred ? "Add to starred" : "Remove from starred",
      action: () => handleStarContainers(!currentContainer.starred),
      icon: !currentContainer.starred ? "bx-star" : "bxs-star",
    },
    {
      title: "Add Color",
      action: (e) => e.stopPropagation(),
      icon: "bx-palette",
      handleAddColor: handleAddColor,
    },
    {
      title: "Rename",
      action: () => handleRenameBreadcrumb(),
      icon: "bx-edit-alt",
    },
    {
      title: "divider",
      action: null,
      icon: null,
    },
    {
      title: "Import",
      action: () => setOpenImport(true),
      icon: "bx-import",
    },
    {
      title: "Export",
      action: () => setOpenExport(true),
      icon: "bx-export",
    },
    {
      title: "divider",
      action: null,
      icon: null,
    },
    {
      title: "Move",
      action: () => handleMoveClickBreadcrumb(),
      icon: "bx bx-transfer",
    },
    {
      title: "Link",
      action: () => handleLinkClickBreadcrumb(),
      icon: "bx bx-link",
    },
    {
      title: "divider",
      action: null,
      icon: null,
    },
    {
      title: "Report",
      action: (e) => handleReportClick(e),
      icon: "bxs-report",
    },
    {
      title: "Print Preview",
      action: (e) => handlePrintPreviewClick(e),
      icon: "bx-printer",
    },
    {
      title: "divider",
      action: null,
      icon: null,
    },
    {
      title: "Remove",
      action: () => handleDeleteClickBreadcrumb(),
      icon: "text-danger bx-trash",
    },
    //{title: "Rights", action: null, icon: "bx-user-plus"},
    // {title: 'Share', action: null, icon: 'bx-link'},
    // {title: 'Edit', action: handleToolbarEdit, icon: 'bx-edit'},
  ];

  const importCallback = () => {
    if (selectedContainer === uuid) {
      getContainer(uuid);
    } else {
      props.history.push('/containers/' + selectedContainer);
    }
  };

  const containerTypeMap = useMemo(() => createMap(containerTypes), [
    containerTypes,
  ]);
  const containerFullType = useMemo(
    () => containerTypeMap.get(result.type && result.type.code),
    [containerTypeMap, result.type]
  );
  const ContainerLayout = useMemo(() => getLayout(containerFullType), [
    containerFullType,
  ]);

  const isDefaultLayout = useMemo(() => {
    const selectedContainerFullType = containerTypeMap.get(
      result.type && result.type.code
    );
    return (
      selectedContainerFullType &&
      selectedContainerFullType.layout === ViewLayout.default
    );
  }, [containerTypeMap, result.type]);

  const showProperties = useMemo(() => {
    return isDefaultLayout || result.uuid !== currentContainer.uuid;
  }, [currentContainer.uuid, isDefaultLayout, result.uuid]);

  const showNoFiles =
    result.children &&
    !result.children[0] &&
    result.datastreams &&
    !result.datastreams[0] &&
    !isLoading;
  

  const handleSetSelectTypeToOpen = (e) => {
    setOpenWith(false);
    setSelectTypeToOpen(e.target.value);
  };  

  return (
    <React.Fragment>
      <MandatoryContext.Provider value={{mandatoryNotice, setMandatoryNotice}}>
        <div className={`${contentClass}`} onClick={handlePageClick} style={{minHeight: "100vh"}} onContextMenu={(e) => e.preventDefault()}>
          <BaseContent
            title={"My Files"}
            error={error}
            isLoading={isLoading}
            handleCreate={handleCreate}
            handeleUploadDataStream={toggleDatasetModal}
            entityActions={entityActions}
            paths={paths}
            entityType={entityType}
            renderActions={() => (
              <Toolbar
                togglePanel={toggleRightSidebar}
                handleUpload={toggleDatasetModal}
                handleDelete={selectedContainer !== uuid && toggleDelete}
                showList={showList}
                toggleListLayout={toggleListLayout}
                handleEdit={handleToolbarEdit}
              />
            )}
          >
            <ContainerLayout
              handleContainerClick={handleContainerClick}
              containers={children}
              selectedContainer={selectedContainer}
              handleContainerSelect={handleContainerSelect}
              handleEditClick={handleEditClick}
              handleAddColor={handleAddColor}
              handleDelete={deleteContainer}
              currentContainer={currentContainer}
              toggleDelete={toggleDelete}
              isLoading={isLoading}
              dataStreams={dataStreams}
              handleFileClick={handleFileClick}
              handleFileEdit={handleFileEdit}
              handleDeleteFile={handleDeleteFile}
              handleMoveFile={handleMoveFile}
              handleLinkFile={handleLinkFile}
              handleFileRename={handleFileRenameOpen}
              openImport={() => setOpenImport(true)}
              openExport={() => setOpenExport(true)}
              openRename={() => setOpenRename(true)}
              noFiles={showNoFiles}
              toggleListLayout={toggleListLayout}
              showList={showList}
              isSelected={isSelected}
              handleContextClick={handleContextClick}
              handleStarContainers={handleStarContainers}
              handleDeleteContainers={handleDeleteContainers}
              handleReportClick={handleReportClick}
              handlePrintPreviewClick={handlePrintPreviewClick}
              handleMoveClick={handleMoveClick}
              handleLinkClick={handleLinkClick}
              {...props}
            />
            <EntitySidebar
              open={showRightSidebar}
              entity={currentContainer}
              properties={properties}
              entityType={entityType}
              handleClose={toggleRightSidebar}
              objectType={ObjectType.container}
              entityTags={tags}
              showProperties={showProperties}
              isLoadingCurrent={isLoading}
              busy={busy}
            />
          </BaseContent>
        </div>
        <EntityDialog
          entity={entity}
          entityType={entityType}
          open={open}
          handleClose={() => {
            setOpen(false);
            setToolbarAction(false);
            setEntity({});
          }}
          createEntity={createContainer}
          updateEntity={updateContainer}
          // deleteEntity={entity.id && entity.id !== uuid && deleteContainer}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          containerTypes={containerTypes}
          context={context.Container}
          rootContext={context}
          parentUuid={uuid}
          handleRedirect={handleRedirect}
          qualifiers={qualifiers}
          propertyGroups={propertyGroups}
          thisContainer={thisContainer}
          setEntity={setEntity}
          toolbarAction={toolbarAction}
          handlePageClick={handlePageClick}
        />
        <CreateDataStream refreshContainer={() => getContainer(uuid)} thisContainerUuid={uuid} open={openDatasetModal} handleClose={toggleDatasetModal} />
        <DataStreamEntityDialog
          entity={entityDatastream}
          currentContainer={uuid}
          entityType={entityTypeDataStream}
          open={openDatastreamModal}
          handleClose={() => {
            getContainer(uuid);
            setOpenDatastreamModal(false);
          }}
          context={context.DataStream}
          deletedEntity={() => {
            deleteDataStream && getContainer(uuid);
          }}
          updateEntity={updateDataStream}
          showDeleteModal={showDeleteDataStreamModal}
          setShowDeleteModal={setShowDeleteDataStreamModal}
          dataStreamTypes={dataStreamTypes}
          propertyGroups={propertyGroups}
        />
        <DataStreamPortal uuid={dataStreamUuid} dismiss={handleDismissDataStream} selectTypeToOpen={selectTypeToOpen} setOpenWith={setOpenWith} />
        <ImportDialog open={openImport} handleClose={() => setOpenImport(false)} callback={importCallback} containerUuid={selectedContainer} />
        <ExportDialog open={openExport} handleClose={() => setOpenExport(false)} handleContainersExport={handleContainersExport} />
        <MoveDialog
          open={showMoveContainer}
          handleClose={() => {
            toggleMoveContainer();
            getContainer(uuid);
            setActionFromBreacrumb(false);
          }}
          selectedContainer={fullSelectedContainersObject[0]}
          parentContainer={result}
          actionFromBreacrumb={actionFromBreacrumb}
          setActionFromBreacrumb={setActionFromBreacrumb}
        />
        <LinkDialog
          open={showLinkContainer}
          handleClose={() => {
            toggleLinkContainer();
            getContainer(uuid);
            setActionFromBreacrumb(false);
          }}
          selectedContainer={fullSelectedContainersObject[0]}
          parentContainer={result}
          actionFromBreacrumb={actionFromBreacrumb}
          setActionFromBreacrumb={setActionFromBreacrumb}
        />
        <ReportDialog open={openReport} handleClose={() => setOpenReport(false)} uuid={selectedContainer} />
        <DeleteDialog handleClose={toggleDelete} open={showDelete} handleDelete={handleDeleteContainers} />
        <RenameDialog handleClose={() => setOpenRename(false)} open={openRename} handleRename={handleRename} containerUuid={selectedContainer} currentContainer={currentContainer} />
        <RenameDatastreamDialog handleClose={() => setOpenFileRename(false)} open={openFileRename} handleRename={handleFileRename} context={context} datastreamUuid={selectedDatastream?.uuid} />
        <MoveFileDialog
          handleClose={() => {
            toggleMoveFile();
            getContainer(uuid);
          }}
          open={showMoveFile}
          selectedDatastream={selectedDatastream}
          parentContainer={parentContainer}
        />
        <LinkFileDialog
          handleClose={() => {
            toggleLinkFile();
            getContainer(uuid);
          }}
          open={showLinkFile}
          selectedDatastream={selectedDatastream}
          parentContainer={parentContainer}
        />
        <PrintPreviewDialog handleClose={() => setOpenPrintPreview(false)} open={openPrintPreview} selectedContainer={fullSelectedContainersObject[0]} properties={properties} />
        <Modal isOpen={openWith} role="dialog" centered={true} className="exampleModal" toggle={setOpenWith}>
          <div className="modal-content">
            <ModalHeader>Open with...</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="open with" name="type" value={selectTypeToOpen} onChange={(e) => handleSetSelectTypeToOpen(e)}>
                      <FormControlLabel value="3d" control={<Radio />} label="3D Model viewer" />
                      {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
      </MandatoryContext.Provider>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    context: state,
  };
};

export default connect(mapStateToProps, {
  getContainer,
  clearContainer,
  createContainer,
  updateContainer,
  deleteContainer,
  clearContainers,
  clearDataStreams,
  toggleRightSidebar,
  getDataStream,
  updateDataStream,
  deleteDataStream,
})(ContainerOverview);
