import React   from 'react';
import GuideItem from "./GuideItem";
import {Row} from "reactstrap";
import GuideNewItem from "./GuideNewItem";
const GuidesList = ({result, isLoading, browser, selectStory, selectedStory}) => {

  const handleSelection = (uuid) => {
    if (selectStory) {
      selectStory(uuid)
    }
  }

  return <>
    {!isLoading&&result&&<Row xs={12} md={3}>
      {result.map((story,i)=><div key={story.uuid} className='c-item-wrapper'>
        <GuideItem key={story.uuid}  story={story} onClick={browser?()=>handleSelection(story.uuid):null} selectedStory={selectedStory} /></div>)}
      <div className='c-item-wrapper'><GuideNewItem /></div>
    </Row>}
  </>

};

export default GuidesList;
