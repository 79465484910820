import {Grid, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {Label} from "reactstrap";

import GuideLanguageSelection from "../GuideLanguageSelection";
import GuideHtmlEditor from "../GuideHtmlEditor";


const EditItemComponent = ({itemValue, handleChange,  handleChangeMultilingual,selectedLanguage}) => {

  return <Grid container direction={'column'} spacing={2} style={{marginTop:15}}>
    <Grid item xs={2}>
        <Label>Code *</Label>
      <TextField key={`item-code`} variant={'outlined'} size={'small'}
                 value={itemValue?.code}
                 onChange={(e) => handleChange('code', e.target.value)}
                 fullWidth/>
    </Grid>
    <Grid item xs={5}>
        <Label>Title</Label>
      <TextField key={`title-${selectedLanguage}`} variant={'outlined'} size={'small'}
                 value={itemValue?.title[selectedLanguage]}
                 onChange={(e) => handleChangeMultilingual('title', e.target.value, selectedLanguage)}
                 placeholder={'Please type a title'} fullWidth/>
    </Grid>
    <Grid item xs={12} key={`html-grid-${selectedLanguage}`}>
      <Label>Description</Label>
      <GuideHtmlEditor key={`html-${selectedLanguage}`} value={itemValue?.description[selectedLanguage]}
                       selectedLanguage={selectedLanguage} handleChangeMultilingual={handleChangeMultilingual}/>
      {/*<TextField multiline={true} size={'small'} value={itemValue?.description}*/}
      {/*           onChange={(e) => handleChange('description', e.target.value)} fullWidth={true} rows={3} rowsMax={4}*/}
      {/*           variant={'outlined'}*/}
      {/*           placeholder={'Describe this item'}/>*/}
    </Grid>
  </Grid>
}

export default EditItemComponent