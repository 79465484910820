import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody, Col, Media, Row } from "reactstrap";
import OverflowTip from "../../components/Common/OverflowTip";


function CardSettings(props)
{
  const { isDataStream } = props;

  return (
    <Row>
      {props?.settings?.map((setting, key) =>
      {
        const bgColor = setting.color !== null && setting.color !== undefined && setting.color !== "#fff" ? `${ setting.color }8C` : "#fff";
        return (
          <Col xl="4" sm="6" key={"_project_" + key}>
            <Link to={setting.route ? setting.route : "#"}>
              <Card className={`cursor-pointer border border-light`} style={{ position: "relative", backgroundColor: bgColor }} onClick={setting.handleClick}>
                <CardBody>
                  <Media className={"align-items-center"}>
                    {props.starred && (
                      <div className="position-absolute" style={{ right: 0, bottom: 0, margin: 8 }}>
                        <i className={`font-weight-bold bx bxs-star font-size-20`} style={{ color: "#ffca00" }} />
                      </div>
                    )}
                    <div className="avatar-sm mr-4">
                      {!isDataStream ? (
                        <span className="avatar-title rounded-circle bg-light text-primary font-size-24">
                          <i className={`bx ${ setting.icon }`} />
                        </span>
                      ) : (
                        setting.thumbnail
                      )}
                    </div>
                    <Media className="overflow-hidden" body>
                      <h5 className="text-truncate font-size-15 text-dark d-flex align-items-center" id={"label" + key}>
                        <OverflowTip>{setting.label}</OverflowTip>
                        {setting.published && <i className="font-weight-bold bx bxs-check-circle text-success ml-1" />}
                      </h5>
                      {setting.info && (
                        <Badge color={"primary"} style={{ backgroundColor: setting.color }}>
                          {setting.info}11
                        </Badge>
                      )}
                      {setting?.type && (
                        <Badge color={"primary"} style={{ fontSize: 12, fontWeight: 400 }}>
                          {setting.type}
                        </Badge>
                      )}
                    </Media>
                    {props.hasNext && <i className="font-weight-bold bx bx-chevron-right text-primary font-size-18" />}
                  </Media>
                </CardBody>
              </Card>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
}

export default CardSettings;
