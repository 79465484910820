import React, {useEffect, useMemo, useState} from 'react'
import {Grid, makeStyles, Typography} from "@material-ui/core";
import ListItemMobileView from "./LIstItemMobileView";
import ListOfListMobileView from "./ListOfListMobileView";
import ListOfListsItem from "./ListOfListItem";
import ListItem from "./ListItem";


const RoutesMobileView = ({items,component,selectedLanguage,components}) =>{
  const [selectedItem,setSelectedItem] = useState({})
  const [selectedList,setSelectedList] = useState({})

  useEffect(()=>{
    setSelectedItem({})
    setSelectedList({})
  },[component?.id])

  if (selectedItem?.id)
    return <ListItemMobileView item={selectedItem} selectedLanguage={selectedLanguage} handleClose={()=>setSelectedItem({})}/>

  if (selectedList?.id)
    return <ListOfListMobileView items={items} list={selectedList} routeIndicator={true} selectedLanguage={selectedLanguage} setSelectedItem={setSelectedItem} handleClose={()=>setSelectedList({})}/>

  return <Grid container direction={'row'} alignItems={'center'} alignContent={'center'} justifyContent={'center'}>
    <Grid item xs={12} style={{marginBottom:5}}>
      <Typography align={'center'} variant={'h5'}>{component?.title[selectedLanguage]}</Typography>
    </Grid>
    {component?.listOfLists?.map((listItem)=>{
      const foundListOfItems = components.find((component)=>component?.id === listItem)
      if (foundListOfItems)
      return <Grid item key = {foundListOfItems?.id} xs={12} onClick={()=>setSelectedList(foundListOfItems)}>
        <ListOfListsItem item = {foundListOfItems} selectedLanguage={selectedLanguage} duration={foundListOfItems?.duration}/>
      </Grid>
    })}
    {component?.listOfItems?.map((listItem,idx)=>{
        const thisComponentItemObj = items.find((item)=>item.id === listItem && item.title[selectedLanguage])
        if (thisComponentItemObj)
          return <Grid item key = {thisComponentItemObj.id} xs={12} onClick={()=>setSelectedItem(thisComponentItemObj)}>
            <ListItem item = {thisComponentItemObj} selectedLanguage={selectedLanguage} routeIdx={idx+1}/>
          </Grid>
      }
    )}
  </Grid>
}

export default RoutesMobileView