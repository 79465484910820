import React from 'react'
import {Grid, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  listCard:{
    height: 140,
    position: 'relative',
    border:'1px solid #ececec',
    backgroundColor: '#ffffff',
    padding: 8,
    marginBottom: 4,
    marginRight: 4,
    borderRadius: 5,
    boxShadow: '1px 1px 1px 0px #f2efef',
  },
  listCardThumbnail:{
    backgroundSize: 'cover',
    borderRadius: 5,
    width:'98%',
    height: 120,
  },

  routeIndicator:{
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position:'absolute',
    top:2,right:3,
    backgroundColor:'rgba(131,80,178,0.94)',
    color:'#fff',border:'1px solid #fff',borderRadius:'50%'

  }
}))
const ListItem = ({item,selectedLanguage, routeIdx=0}) =>{
  const classes = useStyles()
  return <Grid container className={classes.listCard} direction={'column'} alignItems={'flex-start'} alignContent={'center'} justifyContent={'center'} wrap={'nowrap'}>
    <Grid item className={classes.listCardThumbnail}  style={{backgroundImage: item?.coverImage && `url(${item?.coverImage?.thumb})`}} xs={12}>
      {routeIdx > 0 && <div className={classes.routeIndicator}>{routeIdx}</div>}
    </Grid>
    <Grid item xs={12}>
      <Typography variant={'h6'} style={{fontWeight:'bold',fontSize:13}}>{item?.title[selectedLanguage]}</Typography>
    </Grid>
  </Grid>
}

export default ListItem